/**
 * App Reducers
 */
import { combineReducers } from 'redux';
import settings from './settings';
import sidebarReducer from './SidebarReducer';
import authUserReducer from './AuthUserReducer';
import whatsappReducer from './WhatsappReducer';
import flvbyReducer from './FlvbyReducer';
import chatAppReducer from './ChatAppReducer';
import flowBuilderReducer from './FlowBuilderReducer';

const reducers = combineReducers({
    settings,
    chatAppReducer,
    sidebar: sidebarReducer,
    authUser: authUserReducer,
    whatsapp: whatsappReducer,
    flvby: flvbyReducer,
    flow: flowBuilderReducer,
});

export default reducers;
