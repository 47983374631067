module.exports = {
    'sidebar.app': 'Приложение',
    'sidebar.horizontal': 'горизонтальный',
    'sidebar.horizontalMenu': 'горизонтальное меню',
    'sidebar.general': 'Генеральная',
    'sidebar.component': 'Компонент',
    'sidebar.features': 'Особенности',
    'sidebar.applications': 'Приложения',
    'sidebar.dashboard': 'Панель приборов',
    'sidebar.dashboard1': 'Панель приборов 1',
    'sidebar.modules': 'Модули',
    'sidebar.agency': 'Агентство',
    'sidebar.pages': 'страницы',
    'sidebar.gallery': 'Галерея',
    'sidebar.pricing': 'ценообразование',
    'sidebar.terms&Conditions': 'Условия Условия',
    'sidebar.feedback': 'Обратная связь',
    'sidebar.report': 'отчет',
    'sidebar.faq(s)': 'задаваемые вопросы (ы)',
    'sidebar.advancedComponent': 'Расширенный компонент',
    'sidebar.blank': 'пустой',
    'sidebar.session': 'сессия',
    'sidebar.login': 'Авторизоваться',
    'sidebar.register': 'регистр',
    'sidebar.lockScreen': 'Экран блокировки',
    'sidebar.forgotPassword': 'Забыли пароль',
    'sidebar.404': '404',
    'sidebar.500': '500',
    'sidebar.uiComponents': 'Компоненты пользовательского интерфейса',
    'sidebar.alerts': 'Оповещения',
    'sidebar.appBar': 'Панель приложений',
    'sidebar.avatars': 'Аватары',
    'sidebar.buttons': 'Кнопки',
    'sidebar.bottomNavigations': 'Нижние навигационные',
    'sidebar.badges': 'Значки',
    'sidebar.cards': 'Карты',
    'sidebar.cardsMasonry': 'Карточки Masonry',
    'sidebar.chip': 'чип',
    'sidebar.dialog': 'диалог',
    'sidebar.dividers': 'циркуль',
    'sidebar.drawers': 'подштанники',
    'sidebar.popover': 'Трещать',
    'sidebar.expansionPanel': 'Панель расширения',
    'sidebar.gridList': 'Сетка Список',
    'sidebar.list': 'Список',
    'sidebar.menu': 'Меню',
    'sidebar.popoverAndToolTip': 'поп Нада также Инструмент наконечник',
    'sidebar.progress': 'Прогресс',
    'sidebar.snackbar': 'Закусочная',
    'sidebar.selectionControls': 'Контроль выбора',
    'sidebar.advanceUiComponents': 'Дополнительные компоненты пользовательского интерфейса',
    'sidebar.dateAndTimePicker': 'Выбор даты и времени',
    'sidebar.tabs': 'Вкладки',
    'sidebar.stepper': 'Шаговый',
    'sidebar.notification': 'уведомление',
    'sidebar.sweetAlert': 'Сладкое предупреждение',
    'sidebar.autoComplete': 'Автозаполнение',
    'sidebar.aboutUs': 'О нас',
    'sidebar.widgets': 'Виджеты',
    'sidebar.inbox': 'входящие',
    'sidebar.forms': 'формы',
    'sidebar.formElements': 'Элементы формы',
    'sidebar.textField': 'Текстовое поле',
    'sidebar.selectList': 'Выбрать список',
    'sidebar.charts': 'Графики',
    'sidebar.reCharts': 'диаграммы',
    'sidebar.reactChartjs2': 'Реакция Chartjs 2',
    'sidebar.icons': 'Иконки',
    'sidebar.themifyIcons': 'Имитировать значки',
    'sidebar.simpleLineIcons': 'Иконки простой линии',
    'sidebar.materialIcons': 'Иконки материалов',
    'sidebar.fontAwesome': 'Шрифт Awesome',
    'sidebar.maps': 'Карты',
    'sidebar.googleMaps': 'Карты Гугл',
    'sidebar.leafletMaps': 'Листовые карты',
    'sidebar.tables': 'таблицы',
    'sidebar.basic': 'основной',
    'sidebar.dataTable': 'Таблица данных',
    'sidebar.reactTable': 'Таблица реактивов',
    'sidebar.responsive': 'отзывчивый',
    'sidebar.userList': 'Список пользователей',
    'sidebar.users': 'пользователей',
    'sidebar.userProfile1': 'Профиль пользователя 1',
    'sidebar.userProfile2': 'Профиль пользователя 2',
    'sidebar.userManagement': 'Управление пользователями',
    'sidebar.userProfile': 'Профиль пользователя',
    'sidebar.calendar': 'Календарь',
    'sidebar.cultures': 'Культуры',
    'sidebar.dnd': 'просьба не беспокоить',
    'sidebar.selectable': 'Выбор',
    'sidebar.customRendering': 'Пользовательский рендеринг',
    'sidebar.chat': 'чат',
    'sidebar.toDo': 'Делать',
    'sidebar.editor': 'редактор',
    'sidebar.wysiwygEditor': 'Редактор WYSIWYG',
    'sidebar.quillEditor': 'Редактор Quill',
    'sidebar.reactAce': 'Реагировать Ace',
    'sidebar.dragAndDrop': 'Перетаскивание',
    'sidebar.reactDragula': 'Реагировать на Драгулу',
    'sidebar.reactDnd': 'Реагировать на Dnd',
    'sidebar.blogManagement': 'Управление блогами',
    'sidebar.ecommerce': 'Электронная торговля',
    'sidebar.shopList': 'Список магазинов',
    'sidebar.shopGrid': 'Сеть магазинов',
    'sidebar.invoice': 'Выставленный счет',
    'sidebar.multilevel': 'многоуровневый',
    'sidebar.sublevel': 'Подуровень',
    'widgets.totalEarns': 'Всего заработает',
    'widgets.emailsStatistics': 'Статистика электронной почты',
    'widgets.totalRevenue': 'Общий доход',
    'widgets.onlineVistors': 'Интернет-висторы',
    'widgets.trafficSources': 'Источники трафика',
    'widgets.RecentOrders': 'Недавние заказы',
    'widgets.topSellings': 'Лучшие продажи',
    'widgets.productReports': 'Отчеты о продукции',
    'widgets.productStats': 'Статистика продукта',
    'widgets.ComposeEmail': 'Написать письмо',
    'widgets.employeePayroll': 'Заработная плата сотрудников',
    'widgets.visitors': 'Посетители',
    'widgets.orders': 'заказы',
    'widgets.orderStatus': 'Статус заказа',
    'widgets.totalSales': 'Тотальная распродажа',
    'widgets.netProfit': 'Чистая прибыль',
    'widgets.overallTrafficStatus': 'Общее состояние трафика',
    'widgets.tax': 'налог',
    'widgets.expenses': 'РАСХОДЫ',
    'widgets.currentTime': 'Текущее время',
    'widgets.currentDate': 'Текущая дата',
    'widgets.todayOrders': 'Сегодня заказы',
    'widgets.toDoList': 'Сделать списки',
    'widgets.discoverPeople': 'Откройте для себя людей',
    'widgets.commments': 'Комментарии',
    'widgets.newCustomers': 'новые клиенты',
    'widgets.recentNotifications': 'Последние уведомления',
    'widgets.appNotifications': 'Уведомления приложений',
    'widgets.newEmails': 'Новые электронные письма',
    'widgets.siteVisitors': 'Посетители сайта',
    'widgets.recentActivities': 'Последние действия',
    'widgets.recentOrders': 'Недавние заказы',
    'widgets.gallery': 'Галерея',
    'widgets.pricing': 'ценообразование',
    'widgets.enterpriseEdition': 'Enterprise Edition',
    'widgets.personalEdition': 'Персональная версия',
    'widgets.teamEdition': 'Командный выпуск',
    'widgets.ratings': 'Рейтинги',
    'widgets.socialCompanines': 'Социальные компании',
    'widgets.standard': 'стандарт',
    'widgets.advanced': 'продвинутый',
    'widgets.master': 'Мастер',
    'widgets.Mega': 'Мега',
    'widgets.logIn': 'Авторизоваться',
    'widgets.signUp': 'Зарегистрироваться',
    'widgets.lockScreen': 'Экран блокировки',
    'widgets.alertsWithLink': 'Оповещения с ссылкой',
    'widgets.additionalContent': 'Дополнительный контент',
    'widgets.alertDismiss': 'Оповещение об увольнении',
    'widgets.uncontrolledDisableAlerts': 'Неконтролируемые отключенные оповещения',
    'widgets.contexualAlerts': 'Контекстные оповещения',
    'widgets.alertsWithIcons': 'Оповещения с иконками',
    'widgets.Simple App Bars': 'Sобновить App Bars',
    'widgets.appBarsWithButtons': 'Панель приложений с кнопками',
    'widgets.imageAvatars': 'Iмага Аватары',
    'widgets.lettersAvatars': 'Письма Аватары',
    'widgets.iconsAvatars': 'Иконки Аватары',
    'widgets.flatButtons': 'Плоские кнопки',
    'widgets.raisedButton': 'Поднятая кнопка',
    'widgets.buttonWithIconAndLabel': 'Кнопка с иконкой и ярлыком',
    'widgets.floatingActionButtons': 'Плавающие кнопки действий',
    'widgets.iconButton': 'Кнопка значка',
    'widgets.socialMediaButton': 'Кнопка Социальные медиа',
    'widgets.reactButton': 'Кнопка ответа',
    'widgets.buttonOutline': 'Контур кнопки',
    'widgets.buttonSize': 'Размер кнопки',
    'widgets.buttonState': 'Состояние кнопки',
    'widgets.buttonNavigationWithNoLabel': 'Кнопка Навигация без метки',
    'widgets.buttonNavigation': 'Навигация по кнопке',
    'widgets.iconNavigation': 'Навигация по иконкам',
    'widgets.badgeWithHeadings': 'BПрикрепленное изображение с заголовками',
    'widgets.contexualVariations': 'Контекстные вариации',
    'widgets.badgeLinks': 'Ссылки на значки',
    'widgets.materialBadge': 'Значок материала',
    'widgets.simpleCards': 'Простые карты',
    'widgets.backgroundVarient': 'Справочная информация',
    'widgets.cardOutline': 'План карты',
    'widgets.overlayCard': 'Карта наложения',
    'widgets.cardGroup': 'Группа карт',
    'widgets.cardTitle': 'Название карты',
    'widgets.speacialTitleTreatment': 'Юридическая информация',
    'widgets.chipWithClickEvent': 'Чип с кликом',
    'widgets.chipArray': 'Чип-массив',
    'widgets.dialogs': 'Диалоги',
    'widgets.listDividers': 'Список разделителей',
    'widgets.insetDividers': 'Вставляемые разделители',
    'widgets.temporaryDrawers': 'Временные ящики',
    'widgets.permanentDrawers': 'Постоянные ящики',
    'widgets.simpleExpansionPanel': 'Простая панель расширения',
    'widgets.controlledAccordion': 'Контролируемый аккордеон',
    'widgets.secondaryHeadingAndColumns': 'Вторичные заголовки и столбцы',
    'widgets.imageOnlyGridLists': 'Только списки гридов',
    'widgets.advancedGridLists': 'Расширенные списки гридов',
    'widgets.singleLineGridLists': 'Однолинейные списки сетки',
    'widgets.simpleLists': 'Простые списки',
    'widgets.folderLists': 'Списки папок',
    'widgets.listItemWithImage': 'Список элементов с изображением',
    'widgets.switchLists': 'Списки переключателей',
    'widgets.insetLists': 'Списки вставки',
    'widgets.nestedLists': 'Вложенные списки',
    'widgets.checkboxListControl': 'Контроль списка флажков',
    'widgets.pinedSubHeader': 'Выровненный подзаголовок',
    'widgets.InteractiveLists': 'Интерактивные списки',
    'widgets.simpleMenus': 'Простые меню',
    'widgets.selectedMenu': 'Выбранное меню',
    'widgets.maxHeightMenu': 'Меню максимальной высоты',
    'widgets.changeTransition': 'Изменить переход',
    'widgets.paper': 'Бумага',
    'widgets.anchorPlayGround': 'Якорная игровая площадка',
    'widgets.tooltip': 'ToolTip',
    'widgets.positionedToolTips': 'Расположенная закусочная',
    'widgets.circularProgressBottomStart': 'Круговой ход',
    'widgets.interactiveIntegration': 'Интерактивная интеграция',
    'widgets.determinate': 'детерминированный',
    'widgets.linearProgressLineBar': 'Линейная линия линейки хода',
    'widgets.indeterminate': 'неопределенный',
    'widgets.buffer': 'буфер',
    'widgets.query': 'запрос',
    'widgets.transitionControlDirection': 'Направление управления переключением',
    'widgets.simpleSnackbar': 'Простая закусочная',
    'widgets.positionedSnackbar': 'Расположенная закусочная',
    'widgets.contexualColoredSnackbars': 'Контекстные цветные закусочные',
    'widgets.simpleCheckbox': 'Простой флажок',
    'widgets.interminateSelection': 'Межминистерский отбор',
    'widgets.disabledCheckbox': 'Отключен флажок',
    'widgets.customColorCheckbox': 'Пользовательский флажок цвета',
    'widgets.VerticalStyleCheckbox': 'Флажок вертикального стиля',
    'widgets.horizontalStyleCheckbox': 'Флажок горизонтального стиля',
    'widgets.radioButtons': 'Радио-кнопки',
    'widgets.disabledRadio': 'Отключенное радио',
    'widgets.withError': 'С ошибкой',
    'widgets.switches': 'Swiches',
    'widgets.dateAndTimePicker': 'Выбор даты и времени',
    'widgets.defaultPicker': 'Выбор по умолчанию',
    'widgets.timePicker': 'Выбор времени',
    'widgets.weekPicker': 'Выбор недели',
    'widgets.defaultDatePicker': 'Выбор даты по умолчанию',
    'widgets.customPicker': 'Пользовательский подборщик',
    'widgets.tabs': 'Вкладки',
    'widgets.fixedTabs': 'Фиксированные вкладки',
    'widgets.basicTab': 'Основная вкладка',
    'widgets.centeredLabels': 'Центрированные ярлыки',
    'widgets.forcedScrolledButtons': 'Принудительные кнопки прокрутки',
    'widgets.iconsTabs': 'Вкладки вкладок',
    'widgets.withDisableTabs': 'С отключенными вкладками',
    'widgets.iconWithLabel': 'Иконка с меткой',
    'widgets.stepper': 'Шаговый',
    'widgets.horizontalLinear': 'Горизонтальная линейная',
    'widgets.horizontalNonLinear': 'Горизонтальный нелинейный',
    'widgets.horizontalLinerAlternativeLabel': 'Альтернативная метка горизонтального вкладыша',
    'widgets.horizontalNonLinerAlternativeLabel': 'Горизонтальная альтернативная этикетка без вкладыша',
    'widgets.verticalStepper': 'Вертикальный степпер',
    'widgets.descriptionAlert': 'Описание Alert',
    'widgets.customIconAlert': 'Пользовательский значок оповещения',
    'widgets.withHtmlAlert': 'С Html бдительный',
    'widgets.promptAlert': 'Быстрое оповещение',
    'widgets.passwordPromptAlert': 'Предупреждение о запросе пароля',
    'widgets.customStyleAlert': 'Пользовательский стиль оповещения',
    'widgets.autoComplete': 'Автозаполнение',
    'widgets.reactSelect': 'Выбор реакции',
    'widgets.downshiftAutoComplete': 'Автоматический переход на пониженную передачу',
    'widgets.reactAutoSuggests': 'Реагировать на авто',
    'widgets.aboutUs': 'О нас',
    'widgets.ourVission': 'Наши деления',
    'widgets.ourMissions': 'Наши миссии',
    'widgets.ourMotivation': 'Наша мотивация',
    'widgets.defualtReactForm': 'Defualt React Form',
    'widgets.url': 'Веб-сайт',
    'widgets.textArea': 'Текстовая область',
    'widgets.file': 'файл',
    'widgets.formGrid': 'Сетка формы',
    'widgets.inlineForm': 'Встроенная форма',
    'widgets.inputSizing': 'Размер входного сигнала',
    'widgets.inputGridSizing': 'Ширина сетки ввода',
    'widgets.hiddenLabels': 'Скрытые ярлыки',
    'widgets.formValidation': 'Проверка формы',
    'widgets.number': 'Число',
    'widgets.date': 'Дата',
    'widgets.time': 'Время',
    'widgets.color': 'цвет',
    'widgets.search': 'Поиск',
    'widgets.selectMultiple': 'Выберите несколько',
    'widgets.inputWithSuccess': 'Вход с успехом',
    'widgets.inputWithDanger': 'Вход с опасностью',
    'widgets.simpleTextField': 'Простое текстовое поле',
    'widgets.componet': 'Компоненты',
    'widgets.layouts': 'Макеты',
    'widgets.inputAdorements': 'Входные адепты',
    'widgets.formattedInputs': 'Форматированные входы',
    'widgets.simpleSelect': 'Простой выбор',
    'widgets.nativeSelect': 'Нативный выбор',
    'widgets.MutltiSelectList': 'Выбрать список Mutlti',
    'widgets.lineChart': 'Линейный график',
    'widgets.barChart': 'Барная диаграмма',
    'widgets.stackedBarChart': 'Сложенная гистограмма',
    'widgets.lineBarAreaChart': 'Диаграмма площади линии',
    'widgets.areaChart': 'Диаграмма района',
    'widgets.stackedAreaChart': 'Сложенная область',
    'widgets.verticalChart': 'Вертикальная диаграмма',
    'widgets.radarChart': 'Радарная диаграмма',
    'widgets.doughnut': 'Пончик',
    'widgets.polarChart': 'Полярная диаграмма',
    'widgets.pieChart': 'Круговая диаграмма',
    'widgets.bubbleChart': 'Диаграмма пузырьков',
    'widgets.horizontalBar': 'Бар Horizonatl',
    'widgets.basicTable': 'Основная таблица',
    'widgets.contexualColoredTable': 'Контекстная цветная таблица',
    'widgets.dataTable': 'Таблица данных',
    'widgets.employeeList': 'Список сотрудников',
    'widgets.responsiveTable': 'Отзывчивая таблица',
    'widgets.responsiveFlipTable': 'Отзывчивый откидной стол',
    'widgets.reactGridControlledStateMode': 'Реагировать режим контролируемого состояния сетки',
    'widgets.googleMaps': 'Карты Гугл',
    'widgets.productsReports': 'Отчеты о продукции',
    'widgets.taskList': 'Список заданий',
    'widgets.basicCalender': 'Основной календарь',
    'widgets.culturesCalender': 'Календари культур',
    'widgets.dragAndDropCalender': 'Перетаскивание и удаление календаря',
    'widgets.selectableCalender': 'Выбираемый календарь',
    'widgets.customRendering': 'Пользовательский рендеринг',
    'widgets.customCalender': 'Пользовательский календарь',
    'widgets.searchMailList': 'Поиск в списке рассылки',
    'components.buyNow': 'купить сейчас',
    'compenets.choose': 'выберите',
    'compenets.username': 'имя пользователя',
    'compenets.passwords': 'Пароли',
    'widgets.forgetPassword': 'Забыть пароль',
    'compenets.signIn': 'Войти в систему',
    'compenets.dontHaveAccountSignUp': 'У вас нет учетной записи Зарегистрироваться',
    'compenets.enterUserName': 'Введите имя пользователя',
    'compenets.enterEmailAddress': 'Введите адрес электронной почты',
    'compenets.confirmPasswords': 'Подтвердить пароли',
    'components.alreadyHavingAccountSignIn': 'У вас уже есть учетная запись',
    'components.enterYourPassword': 'Введите ваш пароль',
    'components.unlock': 'отпереть',
    'components.enterPasswords': 'Введите пароли',
    'components.resestPassword': 'Пароль Resest',
    'components.pageNotfound': 'Страница не найдена',
    'components.goToHomePage': 'Вернуться на домашнюю страницу',
    'components.sorryServerGoesWrong': 'К сожалению, сервер не работает',
    'components.persistentDrawer': 'Постоянный ящик',
    'components.back': 'назад',
    'components.next': 'следующий',
    'components.completeStep': 'Полный шаг',
    'components.withHtml': 'С Html',
    'components.prompt': 'Незамедлительный',
    'components.withDescription': 'С описанием',
    'components.success': 'успех',
    'components.passwordPrompt': 'Запрос пароля',
    'components.warning': 'Предупреждение',
    'components.customIcon': 'Пользовательский значок',
    'components.customStyle': 'Пользовательский стиль',
    'components.basic': 'основной',
    'components.submit': 'Отправить',
    'components.sendMessage': 'Отправить сообщение',
    'components.addNewTasks': 'Добавить новые задачи',
    'components.addToCart': 'Добавить в корзину',
    'components.payNow': 'Заплатить сейчас',
    'components.print': 'Распечатать',
    'components.cart': 'телега',
    'components.viewCart': 'Просмотр корзины',
    'components.checkout': 'Проверять, выписываться',
    'widgets.QuickLinks': 'Быстрые ссылки',
    'widgets.upgrade': 'Обновить',
    'widgets.app': 'Приложение',
    'widgets.addNew': 'Добавить новое',
    'widgets.orderDate': 'Дата заказа',
    'widgets.status': 'Положение дел',
    'widgets.trackingNumber': 'Номер Отслеживания',
    'widgets.action': 'действие',
    'widgets.designation': 'обозначение',
    'widgets.subject': 'предмет',
    'widgets.send': 'послать',
    'widgets.saveAsDrafts': 'Сохранить как черновики',
    'widgets.onlineSources': 'Интернет-источники',
    'widgets.lastMonth': 'Прошлый месяц',
    'widgets.widgets': 'Виджеты',
    'widgets.listing': 'список',
    'widgets.paid': 'оплаченный',
    'widgets.refunded': 'Возвращено',
    'widgets.done': 'Готово',
    'widgets.pending': 'в ожидании',
    'widgets.cancelled': 'отменен',
    'widgets.approve': 'Одобрить',
    'widgets.following': 'Следующий',
    'widgets.follow': 'следовать',
    'widgets.graphs&Charts': 'Графики и диаграммы',
    'widgets.open': 'открыто',
    'widgets.bounced': 'Возвращенные',
    'widgets.spam': 'Спам',
    'widgets.unset': 'Отозвать',
    'widgets.bandwidthUse': 'Использование полосы пропускания',
    'widgets.dataUse': 'Data использование',
    'widgets.unsubscribe': 'Отказаться от подписки',
    'widgets.profile': 'Профиль',
    'widgets.messages': 'Сообщения',
    'widgets.support': 'Поддержка',
    'widgets.faq(s)': 'Вопрос-ответ (ы)',
    'widgets.upgradePlains': 'Обновление равнин',
    'widgets.logOut': 'Выйти',
    'widgets.mail': 'почта',
    'widgets.adminTheme': 'Тема администратора',
    'widgets.wordpressTheme': 'Тема Wordpress',
    'widgets.addToCart': 'Добавить в корзину',
    'widgets.plan': 'План',
    'widgets.basic': 'основной',
    'widgets.pro': 'профессионал',
    'widgets.startToBasic': 'Начать к основным',
    'widgets.upgradeToPro': 'Обновление до Pro',
    'widgets.upgradeToAdvance': 'Обновление до продвинутого',
    'widgets.comparePlans': 'Сравнить планы',
    'widgets.free': 'Свободно',
    'widgets.frequentlyAskedQuestions': 'Часто задаваемые вопросы',
    'widgets.searchIdeas': 'Поиск идей',
    'widgets.startDate': 'Дата начала',
    'widgets.endDate': 'Дата окончания',
    'widgets.category': 'категория',
    'widgets.apply': 'Подать заявление',
    'widgets.downloadPdfReport': 'Загрузить отчет Pdf',
    'widgets.yesterday': 'Вчера',
    'widgets.totalOrders': 'Всего заказов',
    'widgets.totalVisitors': 'Всего посетителей',
    'widgets.typeYourQuestions': 'Введите ваши вопросы',
    'widgets.username': 'имя пользователя',
    'widgets.password': 'пароль',
    'widgets.signIn': 'Войти в систему',
    'widgets.enterYourPassword': 'Введите ваш пароль',
    'widgets.alreadyHavingAccountLogin': 'Уже есть учетная запись',
    'widgets.composeMail': 'Написать письмо',
    'widgets.issue': 'вопрос',
    'widgets.recentChat': 'Последние сообщения',
    'widgets.previousChat': 'Предыдущий чат',
    'widgets.all': 'Все',
    'widgets.filters': 'фильтры',
    'widgets.deleted': 'удаленный',
    'widgets.starred': 'Помеченные',
    'widgets.frontend': 'Внешний интерфейс',
    'widgets.backend': 'Backend',
    'widgets.api': 'Api',
    'widgets.simpleAppBar': 'Простая панель приложений',
    'widgets.recents': 'Недавние',
    'widgets.cardLink': 'Ссылка на карту',
    'widgets.anotherLink': 'Еще одна ссылка',
    'widgets.cardSubtitle': 'подзаголовок карты',
    'widgets.confirmationDialogs': 'Диалоги подтверждения',
    'widgets.deletableChip': 'Удаляемый чип',
    'widgets.customDeleteIconChip': 'Пользовательское удаление значка',
    'widgets.openAlertDialog': 'Открыть диалоговое окно оповещений',
    'widgets.openResponsiveDialog': 'Открыть ответный диалог',
    'widgets.openSimpleDialog': 'Открыть простой диалог',
    'widgets.openFormDialog': 'Диалог открытой формы',
    'widgets.follower': 'толкатель',
    'widgets.important': 'Важный',
    'widgets.private': 'Частный',
    'widgets.openLeft': 'Открыть влево',
    'widgets.openRight': 'Открыть право',
    'widgets.openTop': 'С открытым верхом',
    'widgets.openBottom': 'Открытое дно',
    'widgets.selectTripDestination': 'Выберите пункт назначения поездки',
    'widgets.pinnedSubheaderList': 'Список закрепленных подзаголовков',
    'widgets.singleLineItem': 'Одиночная позиция',
    'widgets.acceptTerms': 'Принять условия',
    'widgets.optionA': 'Вариант A',
    'widgets.optionB': 'Вариант B',
    'widgets.optionC': 'Вариант C',
    'widgets.optionM': 'Вариант M',
    'widgets.optionN': 'Вариант N',
    'widgets.optionO': 'Вариант O',
    'widgets.customColor': 'Пользовательский цвет',
    'widgets.centeredTabs': 'Центрированные вкладки',
    'widgets.multipleTabs': 'Несколько вкладок',
    'widgets.preventScrolledButtons': 'Запретить прокручиваемые кнопки',
    'widgets.browse': 'Просматривать',
    'widgets.formValidate': 'Форма подтверждения',
    'widgets.code': 'Код',
    'widgets.company': 'Компания',
    'widgets.price': 'Цена',
    'widgets.change': 'изменение',
    'widgets.high': 'Высокая',
    'widgets.low': 'Низкий',
    'widgets.volume': 'объем',
    'widgets.personalDetails': 'Личная информация',
    'widgets.occupation': 'оккупация',
    'widgets.companyName': 'название компании',
    'widgets.phoneNo': 'Номер телефона',
    'widgets.city': 'город',
    'widgets.zipCode': 'Почтовый Индекс',
    'widgets.updateProfile': 'Обновить профиль',
    'widgets.reject': 'отклонять',
    'widgets.exportToExcel': 'Экспорт в превосходить',
    'widgets.addNewUser': 'Добавить пользователя',
    'widgets.workWeek': 'Рабочая неделя',
    'widgets.agenda': 'Повестка дня',
    'widgets.conference': 'Конференция',
    'widgets.multilevel': 'многоуровневый',
    'widgets.dailySales': 'Ежедневные продажи',
    'widgets.today': 'Cегодня',
    'widgets.campaignPerformance': 'Эффективность кампании',
    'widgets.supportRequest': 'Запрос поддержки',
    'widgets.usersList': 'Список пользователей',
    'widgets.lastWeek': 'На прошлой неделе',
    'themeOptions.sidebarOverlay': 'Наложение боковой панели',
    'themeOptions.sidebarBackgroundImages': 'Фоновые изображения боковой ',
    'themeOptions.miniSidebar': 'Мини-боковая панель',
    'themeOptions.boxLayout': 'Макет коробки',
    'themeOptions.rtlLayout': 'Rtl расположение',
    'themeOptions.darkMode': 'Темный режим',
    'themeOptions.sidebarImage': 'Изображение боковой панели',
    'themeOptions.appSettings': 'Настройки приложения',
    'themeOptions.sidebarLight': 'Легкий',
    'themeOptions.sidebarDark': 'Темно',
    'button.cancel': 'Отмена',
    'button.add': 'Добавить',
    'button.update': 'Обновить',
    'button.reply': 'Ответить',
    'button.delete': 'Удалить',
    'button.yes': 'да',
    'button.viewAll': 'Посмотреть все',
    'button.like': 'подобно',
    'button.assignNow': 'Назначить сейчас',
    'button.seeInsights': 'См. Insights',
    'sidebar.dateTimePicker': 'Выбор даты и времени',
    'components.summary': 'Резюме',
    'hint.whatAreYouLookingFor': 'Что ты ищешь',
    'components.yesterday': 'Вчера',
    'components.last7Days': 'Последние 7 дней',
    'components.last1Month': 'Последние 1 месяц',
    'components.last6Month': 'Последние 6 месяцев',
    'components.spaceUsed': 'Используемое пространство',
    'components.followers': 'толкатель',
    'components.trending': 'простирания',
    'components.paid': 'оплаченный',
    'components.refunded': 'Возвращено',
    'components.done': 'Готово',
    'components.pending': 'в ожидании',
    'components.cancelled': 'отменен',
    'components.approve': 'Одобрить',
    'components.week': 'Неделю',
    'components.month': 'Месяц',
    'components.year': 'Год',
    'components.today': 'Cегодня',
    'components.popularity': 'популярность',
    'components.email': 'Эл. адрес',
    'components.drafts': 'Черновики',
    'components.sent': 'Отправлено',
    'components.trash': 'дрянь',
    'components.all': 'Все',
    'components.do': 'Делать',
    'components.title': 'заглавие',
    'components.projectName': 'название проекта',
    'components.companyName': 'название компании',
    'components.openAlert': 'Открыть оповещение',
    'components.slideInAlertDialog': 'Слайд в диалоговом окне оповещений',
    'components.openFullScreenDialog': 'Открыть полноэкранные диалоги',
    'components.basicChip': 'Основной чип',
    'components.clickableChip': 'Кликаемый чип',
    'components.left': 'Оставил',
    'components.right': 'Правильно',
    'components.expansionPanel1': 'Панель расширения 1',
    'components.expansionPanel2': 'Панель расширения 2',
    'components.generalSetting': 'Общая настройка',
    'components.advancedSettings': 'Расширенные настройки',
    'components.firstName': 'Имя',
    'components.lastName': 'Фамилия',
    'components.occupation': 'оккупация',
    'components.phoneNo': 'Номер телефона',
    'components.address': 'Адрес',
    'components.city': 'город',
    'components.state': 'состояние',
    'components.zipCode': 'Почтовый Индекс',
    'components.social Connection': 'Социальная связь',
    'widgets.buyMore': 'Купить больше',
    'widgets.trafficChannel': 'Канал трафика',
    'widgets.stockExchange': 'Фондовая биржа',
    'widgets.tweets': 'твиты',
    'widgets.ourLocations': 'Наши местоположения',
    'widgets.sales': 'Продажи',
    'widgets.to': 'к',
    'widgets.shipTo': 'Адрес получателя',
    'widgets.description': 'Описание',
    'widgets.unitPrice': 'Цена за единицу',
    'widgets.total': 'Всего',
    'widgets.note': 'Заметка',
    'widgets.chipWithAvatar': 'Чип с аватаром',
    'widgets.chipWithTextAvatar': 'Чип с текстом Аватар',
    'widgets.chipWithIconAvatar': 'Чип с иконкой Аватар',
    'widgets.customClickableChip': 'Пользовательский клик-чип',
    'widgets.outlineChip': 'Контурный чип',
    'widgets.disableChip': 'Отключить чип',
    'widgets.alertDialog': 'Диалоговое окно оповещений',
    'widgets.animatedSlideDialogs': 'Анимированные слайд-диалоги',
    'widgets.fullScreenDialogs': 'Полноэкранные диалоги',
    'widgets.formDialogs': 'Диалоги формы',
    'widgets.simpleDialogs': 'Простые диалоги',
    'widgets.responsiveFullScreen': 'Отзывчивый полный экран',
    'widgets.primary': 'первичный',
    'widgets.social': 'Социальное',
    'widgets.user': 'пользователь',
    'widgets.admin': 'Администратор',
    'widgets.permanentdrawer': 'Постоянный ящик',
    'widgets.persistentdrawer': 'Постоянный ящик',
    'widgets.swiches': 'Swiches',
    'widgets.horizontalLinearAlternativeLabel': 'Горизонтальная линейная альтернативная метка',
    'widgets.horizontalNonLinearAlternativeLabel': 'Горизонтальная нелинейная альтернативная метка',
    'widgets.notifications': 'Уведомления',
    'widgets.basicAlert': 'Основное оповещение',
    'widgets.successAlert': 'Успешное предупреждение',
    'widgets.warningAlert': 'Предупреждающее предупреждение',
    'widgets.reactAutoSuggest': 'Реагировать Авто Предложить',
    'widgets.components': 'Компоненты',
    'widgets.inputAdornments': 'Входные адепты',
    'widgets.multiSelectList': 'много Выбрать список',
    'widgets.contextualColoredTable': 'Контекстная цветная таблица',
    'widgets.updateYourEmailAddress': 'Обновить адрес электронной почты',
    'widgets.selectADefaultAddress': 'Выберите адрес по умолчанию',
    'widgets.activity': 'Мероприятия',
    'widgets.basicCalendar': 'Основной календарь',
    'widgets.culturesCalendar': 'Календарь культур',
    'widgets.dragAndDropCalendar': 'Календарь перетаскивания',
    'widgets.quillEditor': 'Редактор Quill',
    'widgets.reactDND': 'Реакция DND',
    'widgets.dragula': 'Dragula',
    'button.acceptTerms': 'Принять условия',
    'button.reject': 'отклонять',
    'button.addNew': 'Добавить новое',
    'button.goToCampaign': 'Перейти в кампанию',
    'button.viewProfile': 'Просмотреть профиль',
    'button.sendMessage': 'Отправить сообщение',
    'button.saveNow': 'Сохрани теперь',
    'button.pen': 'Ручка',
    'button.search': 'Поиск',
    'button.downloadPdfReport': 'Загрузить отчет Pdf',
    'button.primary': 'первичный',
    'button.secondary': 'второстепенный',
    'button.danger': 'Опасность',
    'button.info': 'Информация',
    'button.success': 'успех',
    'button.warning': 'Предупреждение',
    'button.link': 'Link',
    'button.smallButton': 'Маленькая кнопка',
    'button.largeButton': 'Большая кнопка',
    'button.blockLevelButton': 'Кнопка уровня блока',
    'button.primaryButton': 'Основная кнопка',
    'button.button': 'кнопка',
    'button.save': 'Сохранить',
    'button.openMenu': 'Открыть меню',
    'button.openWithFadeTransition': 'Открыть с переходом увядать',
    'button.openPopover': 'Открыть трещать',
    'button.accept': 'принимать',
    'button.click': 'Нажмите',
    'button.complete': 'полный',
    'button.back': 'назад',
    'button.next': 'следующий',
    'button.completeStep': 'Полный шаг',
    'button.error': 'ошибка',
    'button.writeNewMessage': 'Написать новое сообщение',
    'button.saveChanges': 'Сохранить изменения',
    'button.addNewUser': 'Добавить пользователя',
    'button.more': 'Больше',
    'hint.searchMailList': 'Поиск в списке рассылки',
    'widgets.AcceptorrRejectWithin': 'Принять или отклонить',
    'widgets.quoteOfTheDay': 'Цитата дня',
    'widgets.updated10Minago': 'Обновлено 10 мин назад',
    'widgets.personalSchedule': 'Персональный график',
    'widgets.activeUsers': 'Активные пользователи',
    'widgets.totalRequest': 'Общий запрос',
    'widgets.new': 'новый',
    'widgets.ShareWithFriends': 'Поделиться с друзьями!',
    'widgets.helpToShareText':
        'Помогите нам распространить мир, поделившись нашим сайтом со своими друзьями и последователями в социальных сетях!',
    'widgets.thisWeek': 'На этой неделе',
    'widgets.howWouldYouRateUs': 'Как вы оцениваете нас?',
    'widgets.booking': 'бронирование',
    'widgets.confirmed': 'подтвердил',
    'widgets.monthly': 'ежемесячно',
    'widgets.weekly': 'еженедельно',
    'widgets.target': 'цель',
    'widgets.totalActiveUsers': 'Всего активных пользователей',
    'sidebar.user': 'пользователь',
    'sidebar.miscellaneous': 'Разное',
    'sidebar.promo': 'рекламный',
    'themeOptions.themeColor': 'Цвет темы',
    'module.inbox': 'входящие',
    'module.drafts': 'Черновики',
    'module.sent': 'Отправлено',
    'module.trash': 'дрянь',
    'module.spam': 'Спам',
    'module.frontend': 'Внешний интерфейс',
    'module.backend': 'Backend',
    'module.api': 'Api',
    'module.issue': 'вопрос',
    'components.emailPrefrences': 'Настройки электронной почты',
    'components.myProfile': 'Мой профайл',
    'sidebar.gettingStarted': 'Начиная',
    'widgets.deadline': 'Крайний срок',
    'widgets.team': 'команда',
    'widgets.projectManagement': 'Управление проектом',
    'widgets.latestPost': 'Последний пост',
    'widgets.projectTaskManagement': 'Управление проектами',
    'widgets.selectProject': 'Выберите проект',
    'widgets.activityBoard': 'Доска объявлений',
    'widgets.checklist': 'контрольный список',
    'sidebar.shop': 'Магазин',
    'sidebar.cart': 'телега',
    'sidebar.checkout': 'Проверять, выписываться',
    'components.product': 'Продукт',
    'components.quantity': 'Количество',
    'components.totalPrice': 'Итоговая цена',
    'components.removeProduct': 'Удалить продукт',
    'components.mobileNumber': 'Мобильный номер',
    'components.address2Optional': 'Адрес 2 (необязательно)',
    'components.country': 'Страна',
    'components.zip': 'застежка-молния',
    'components.saveContinue': 'Сохранить и продолжить',
    'components.placeOrder': 'Разместить заказ',
    'components.payment': 'Оплата',
    'components.billingAddress': 'Платежный адрес',
    'components.ShippingAddressText': 'Адрес доставки совпадает с адресом фактуры.',
    'components.CartEmptyText': 'Ваша корзина пуста!',
    'components.NoItemFound': 'Не найдено ни одного товара',
    'components.goToShop': 'Идти в магазин',
    'components.cardNumber': 'Номер карты',
    'components.expiryDate': 'Срок годности',
    'components.cvv': 'CVV',
    'components.nameOnCard': 'Имя на карте',
    'components.confirmPayment': 'Подтвердить платеж',
    'sidebar.saas': 'SAAS',
    'sidebar.multiLevel': 'MultiLevel',
    'sidebar.level1': '1-й уровень',
    'sidebar.level2': 'Уровень 2',
    'sidebar.boxed': 'в штучной упаковке',
    'sidebar.news': 'Новости',
    'sidebar.extensions': 'расширения',
    'sidebar.imageCropper': 'Изображение Cropper',
    'sidebar.videoPlayer': 'Видео проигрыватель',
    'sidebar.dropzone': 'Зона сброса',
    'widgets.baseConfig': 'Базовая конфигурация',
    'widgets.customControlBar': 'Пользовательская панель управления',
    'widgets.withDownloadButton': 'С кнопкой загрузки',
    'widgets.httpLiveStreaming': 'HTTP потоковое видео',
    'widgets.keyboardShortcuts': 'Горячие клавиши',
    'button.useDefaultImage': 'Использовать изображение по умолчанию',
    'button.cropImage': 'Обрезать изображение',
    'widgets.preview': 'предварительный просмотр',
    'widgets.croppedImage': 'Обрезанное изображение',
};
