import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
// intl messages
import IntlMessages from 'Util/IntlMessages';
// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

import Subscriptions from './component/Subscriptions';
import Payments from './component/Payments';
import { Helmet } from 'react-helmet';

const TabContainer = ({ children }) => {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
};

const Billing = ({ user, firebase, match, userInit }) => {
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    return (
        <div>
            <Helmet>
                <title>Payments and Subscriptions</title>
            </Helmet>

            {loading && <RctSectionLoader />}
            <PageTitleBar title={<IntlMessages id="payments.title" />} match={match} />
            <Alert severity="info" style={{ marginBottom: 10 }}>
                <IntlMessages id="subscription.info" />
            </Alert>
            <RctCollapsibleCard>
                <Tabs value={index} onChange={(e, value) => setIndex(value)} textColor="primary" indicatorColor="primary">
                    <Tab label={<IntlMessages id="payments.subscriptions" />} />
                    <Tab label={<IntlMessages id="payments.payments" />} />
                </Tabs>
                <SwipeableViews axis={'x'} index={index} onChangeIndex={index => setIndex(index)}>
                    <TabContainer>
                        <Subscriptions userInit={userInit} user={user} firebase={firebase} match={match} setLoading={setLoading} />
                    </TabContainer>
                    <TabContainer>
                        <Payments user={user} firebase={firebase} match={match} setLoading={setLoading} />
                    </TabContainer>
                </SwipeableViews>
            </RctCollapsibleCard>
        </div>
    );
};

const mapStateToProps = ({ authUser }) => {
    const { user, firebase, userInit } = authUser;
    return { user, firebase, userInit };
};

export default compose(connect(mapStateToProps))(Billing);
