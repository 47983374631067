/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */

import {
    WP_OPEN_INSTANCE,
    WP_SET_INSTANCE,
    WP_FAILED_ACTION,
    WP_FB_UPDATE_INSTANCE_STATUS,
    WP_UPDATE_INSTANCE_STATUS,
    WP_INITIATE_INSTANCE_DIALOG,
    WP_SOCKET_CONNECT,
    WP_SOCKET_DESTROY,
    WP_SET_QR_CODE,
    WP_UPDATE_BATTERY,
    WP_IS_SERVER_ONLINE,
    WP_SAVED_MESSAGES,
    WP_RELOAD_SETTINGS,
    WP_UPDATE_INSTANCE,
    WP_REMOVE_INSTANCE_DIALOG,
    WP_REBOOT_INSTANCE_DIALOG,
    WP_DISCONNECT_INSTANCE_DIALOG,
    WP_SET_INSTANCE_EXPIRATION_TIME,
    WP_OPEN_PAYMENT_DIALOG,
} from './types';

/**
 * Open payment dialog
 */
export const showHidePaymentDialog = payment => ({
    type: WP_OPEN_PAYMENT_DIALOG,
    payload: payment,
});

/**
 * Set instance expiration time
 */
export const setInstanceExpirationTime = expiration => ({
    type: WP_SET_INSTANCE_EXPIRATION_TIME,
    payload: expiration,
});

/**
 * Show/hide disconnect instance dialog
 */
export const showHideDisconnectInstanceDialog = instance => ({
    type: WP_DISCONNECT_INSTANCE_DIALOG,
    payload: instance,
});

/**
 * Show/hide remove instance dialog
 */
export const showHideInstanceDialog = status => ({
    type: WP_REMOVE_INSTANCE_DIALOG,
    payload: status,
});

/**
 * Show/hide reboot instance dialog
 */
export const showHideRebootInstanceDialog = status => ({
    type: WP_REBOOT_INSTANCE_DIALOG,
    payload: status,
});

/**
 * Update settings
 */
export const updateInstanceSettings = status => ({
    type: WP_UPDATE_INSTANCE,
    payload: status,
});

/**
 * Reload settings
 */
export const setReloadingInstanceSettings = status => ({
    type: WP_RELOAD_SETTINGS,
    payload: status,
});

/**
 * Set saved messages count
 */
export const setSavedMessagesCount = messages => ({
    type: WP_SAVED_MESSAGES,
    payload: messages,
});

/**
 * Set current instance for the dialog window
 */
export const updateBatteryUpdate = battery => ({
    type: WP_UPDATE_BATTERY,
    payload: battery,
});

/**
 * Set current instance for the dialog window
 */
export const setServerStatus = status => ({
    type: WP_IS_SERVER_ONLINE,
    payload: status,
});

/**
 * Set current instance for the dialog window
 */
export const setQRCode = code => ({
    type: WP_SET_QR_CODE,
    payload: code,
});

/**
 * Set current instance for the dialog window
 */
export const createSocketIo = socketServer => ({
    type: WP_SOCKET_CONNECT,
    payload: socketServer,
});

/**
 * Set current instance for the dialog window
 */
export const destroySocketIo = () => ({
    type: WP_SOCKET_DESTROY,
});

/**
 * Set current instance for the dialog window
 */
export const initiateWhatsappInstanceDialog = (instanceId, instanceInfo) => ({
    type: WP_INITIATE_INSTANCE_DIALOG,
    payload: { instanceId, instanceInfo },
});

/**
 * Set current instance for the dialog window
 */
export const setInstance = instance => ({
    type: WP_SET_INSTANCE,
    payload: instance,
});

/**
 * Open instance dialog window
 */
export const openInstanceDialog = instance => ({
    type: WP_OPEN_INSTANCE,
    payload: instance,
});

/**
 * Update instantce status in firebase
 */
export const updateInstnanceStatusFireBase = (uid, instanceId, status) => ({
    type: WP_FB_UPDATE_INSTANCE_STATUS,
    payload: { uid, instanceId, status },
});

/**
 * Update instantce status in store
 */
export const updateInstnanceStatus = status => ({
    type: WP_UPDATE_INSTANCE_STATUS,
    payload: status,
});

/**
 * Failed whatsapp instance update/delete/view actions
 */
export const failedWhatsAppInstanceAction = message => ({
    type: WP_FAILED_ACTION,
    payload: message,
});
