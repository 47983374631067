/**
 * Auth User Reducers
 */
import { NotificationManager } from 'react-notifications';
import socketIOClient from 'socket.io-client';
import {
    WP_OPEN_INSTANCE,
    WP_SET_INSTANCE,
    WP_FAILED_ACTION,
    WP_UPDATE_INSTANCE_STATUS,
    WP_SOCKET_CONNECT,
    WP_SOCKET_DESTROY,
    WP_SET_QR_CODE,
    WP_UPDATE_BATTERY,
    WP_IS_SERVER_ONLINE,
    WP_SAVED_MESSAGES,
    WP_RELOAD_SETTINGS,
    WP_UPDATE_INSTANCE,
    WP_REMOVE_INSTANCE_DIALOG,
    WP_REBOOT_INSTANCE_DIALOG,
    WP_DISCONNECT_INSTANCE_DIALOG,
    WP_SET_INSTANCE_EXPIRATION_TIME,
    WP_OPEN_PAYMENT_DIALOG,
} from 'Actions/types';
/**
 * initial auth user
 */
const INIT_STATE = {
    instance: {},
    open: false,
    status: 'New',
    socket: '',
    qr: '',
    battery: { value: 100, live: false },
    server: false,
    countdown: Date.now() + 45000,
    savedMessages: 0,
    reloadInstanceSettings: false,
    renewQr: false,
    //Remove Instance
    removeInstanceDialog: false,
    //Reboot Instance
    rebootInstanceDialog: false,
    //Disconnect Instance
    disconnectInstanceDialog: false,
    //Instance Expiration Time
    expirationTime: 0,
    //Show/Hide payment dialog
    paymentDialog: false,
    instancesToRemove: [],
    plan_id: '',
    instance_id: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case WP_OPEN_PAYMENT_DIALOG:
            return {
                ...state,
                paymentDialog: !state.paymentDialog,
                ...(!state.paymentDialog ? { plan_id: action.payload.plan_id, instance_id: action.payload.instance_id } : {}),
            };

        case WP_SET_INSTANCE_EXPIRATION_TIME:
            return { ...state, expirationTime: action.payload };

        case WP_DISCONNECT_INSTANCE_DIALOG:
            return { ...state, disconnectInstanceDialog: !state.disconnectInstanceDialog };

        case WP_REBOOT_INSTANCE_DIALOG:
            return { ...state, rebootInstanceDialog: !state.rebootInstanceDialog };

        case WP_REMOVE_INSTANCE_DIALOG:
            return {
                ...state,
                removeInstanceDialog: !state.removeInstanceDialog,
                ...(action.payload ? { instancesToRemove: action.payload } : { instancesToRemove: INIT_STATE.instancesToRemove }),
            };

        case WP_RELOAD_SETTINGS:
            return { ...state, reloadInstanceSettings: action.payload };

        case WP_SAVED_MESSAGES:
            return { ...state, savedMessages: action.payload };

        case WP_UPDATE_INSTANCE:
            return {
                ...state,
                instance: {
                    ...state.instance,
                    ...action.payload,
                },
                reloadInstanceSettings: false,
            };

        case WP_SET_INSTANCE:
            return { ...state, instance: action.payload };

        case WP_OPEN_INSTANCE:
            return { ...state, open: !state.open };

        case WP_UPDATE_INSTANCE_STATUS:
            return { ...state, status: action.payload };

        case WP_FAILED_ACTION:
            NotificationManager.error(action.payload);
            return { ...state };

        case WP_SOCKET_CONNECT:
            return {
                ...state,
                socket: socketIOClient(action.payload, {
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 5000,
                    reconnectionDelayMax: 6000,
                }),
            };

        case WP_SOCKET_DESTROY:
            return { ...state, socket: '' };

        case WP_SET_QR_CODE:
            return {
                ...state,
                qr: action.payload,
                countdown: Date.now() + 45000,
                ...(!action.payload ? { renewQr: true } : { renewQr: false }),
            };

        case WP_UPDATE_BATTERY:
            return { ...state, battery: action.payload };

        case WP_IS_SERVER_ONLINE:
            return { ...state, server: action.payload };

        default:
            return { ...state };
    }
};
