/**
 * Notification Component
 */
import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Badge } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import localforage from 'localforage';

// api

// intl messages
import IntlMessages from 'Util/IntlMessages';

const useStyles = makeStyles({
    notification: {
        boxShadow: '0 0 9px #868383, 0 0 5px #000000',
        margin: '8px 6px 8px 5px',
    },
    box: {
        boxShadow: '0 0 9px #868383, 0 0 5px #000000',
    },
    time: {
        fontSize: 'smaller',
        color: 'grey',
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
    },
});

const NewsNotifications = ({ firebase }) => {
    const classes = useStyles();

    const [notifications, setNotifications] = useState([]);
    const [activeNotification, setActiveNotification] = useState(false);
    const [newMessagesCounter, setNewMessagesCounter] = useState(0);
    const [initMessages, setInitMessages] = useState(false);
    const [notificationsRef] = useState(firebase.database.ref(`/notifications`));
    const [newNotificationRef] = useState(firebase.database.ref(`/main/lastMessage`));

    const loadNotifications = () => {
        notificationsRef
            .orderByChild('create_time_mls')
            .limitToLast(10)
            .once('value', snapshot => {
                let item = snapshot.val();
                if (item) {
                    let messages = notifications;
                    let newItemKeys = Object.keys(item);
                    let keys = notifications.map(item => item.key);
                    let newCount = 0;

                    if (messages.length > 0) {
                        if (messages.length > newItemKeys.length) {
                            messages = [];
                            for (let key in item) {
                                messages.unshift({
                                    key: key,
                                    title: item[key].title,
                                    message: item[key].message,
                                    create_time: item[key].create_time,
                                    create_time_mls: item[key].create_time_mls,
                                });
                            }
                        } else {
                            for (let key in item) {
                                if (keys.indexOf(key) === -1) {
                                    newCount++;
                                    messages.unshift({
                                        key: key,
                                        ...item[key],
                                    });
                                }
                            }
                        }
                    } else {
                        for (let key in item) {
                            newCount++;
                            messages.unshift({
                                key: key,
                                title: item[key].title,
                                message: item[key].message,
                                create_time: item[key].create_time,
                                create_time_mls: item[key].create_time_mls,
                            });
                        }
                    }

                    return localforage
                        .getItem('newMessagesCounter')
                        .then(old_count => {
                            if (old_count) {
                                newCount += old_count;
                            }

                            if (newCount > 0) {
                                return localforage.setItem('activeNotification', true).then(() => {
                                    setActiveNotification(true);
                                    setNewMessagesCounter(newCount);
                                    return localforage.setItem('newMessagesCounter', newCount);
                                });
                            } else {
                                return Promise.resolve();
                            }
                        })
                        .then(() => {
                            return localforage.setItem('notifications', messages);
                        })
                        .then(() => {
                            return setNotifications(messages);
                        });
                }
            });
    };

    const activeNotificationUpdate = () => {
        if (activeNotification) {
            localforage
                .setItem('activeNotification', false)
                .then(() => {
                    setActiveNotification(false);
                    return localforage.setItem('newMessagesCounter', 0);
                })
                .catch(() => {});
        }
    };

    useEffect(() => {
        if (!initMessages) {
            localforage.getItem('notifications').then(messages => {
                setInitMessages(true);
                if (!messages) {
                    loadNotifications();
                } else {
                    if (messages.length > 50) {
                        messages.splice(-Math.abs(messages.length - 50));
                        localforage.setItem('notifications', messages);
                    }
                    return localforage
                        .getItem('activeNotification')
                        .then(active => {
                            setNotifications(messages);
                            setActiveNotification(active);
                            return localforage.getItem('newMessagesCounter');
                        })
                        .then(count => {
                            setNewMessagesCounter(count);
                        });
                }
            });
        }

        newNotificationRef.on('value', snapshot => {
            localforage.getItem('notificationsTimestamp').then(timestamp => {
                if (timestamp) {
                    if (timestamp !== snapshot.val()) {
                        loadNotifications();
                        localforage.setItem('notificationsTimestamp', snapshot.val());
                    }
                } else {
                    localforage.setItem('notificationsTimestamp', snapshot.val());
                }
            });
        });
        return () => {
            newNotificationRef.off();
        };
    }, [notifications]);

    return (
        <UncontrolledDropdown nav className="list-inline-item notification-dropdown">
            <DropdownToggle nav className="p-0" onClick={activeNotificationUpdate}>
                <Tooltip title={<IntlMessages id="serviceNotifications" />} placement="bottom">
                    <IconButton className={activeNotification ? 'shake' : ''} aria-label="bell">
                        <i className="zmdi zmdi-notifications-active" />
                        {activeNotification && (
                            <Badge color="danger" className="badge-xs badge-top-right rct-notify">
                                {newMessagesCounter}
                            </Badge>
                        )}
                    </IconButton>
                </Tooltip>
            </DropdownToggle>
            <DropdownMenu right>
                <div className="dropdown-content">
                    <div className="dropdown-top d-flex justify-content-between rounded-top bg-primary">
                        <span className="text-white font-weight-bold">
                            <IntlMessages id="widgets.recentNotifications" />
                        </span>
                    </div>
                    <Scrollbars className="rct-scroll" autoHeight autoHeightMin={100} autoHeightMax={280}>
                        <ul className="list-unstyled dropdown-list">
                            {notifications.map((notification, index) => (
                                <Alert severity={`info`} key={index} className={classes.notification}>
                                    <AlertTitle className={classes.title}>
                                        {notification.title} <span className={classes.time}>{notification.create_time}</span>
                                    </AlertTitle>
                                    {notification.message}
                                </Alert>
                            ))}
                        </ul>
                    </Scrollbars>
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

const mapStateToProps = ({ authUser }) => {
    const { user, firebase } = authUser;
    return { user, firebase };
};

export default compose(connect(mapStateToProps))(NewsNotifications);
