/**
 * Data Table
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import TextField from '@material-ui/core/TextField';
import { Helmet } from 'react-helmet';
import withWidth from '@material-ui/core/withWidth';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

// api
import api from 'Api';

// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

// rct card box
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

// intl messages
import IntlMessages from 'Util/IntlMessages';

//components
import ConfirmInstanceRemove from './components/ConfirmInstanceRemove';
import SubscribtionForm from './components/SubscribtionForm';

// async components
import { AsyncWhatsapMoreInstanceComponent } from 'Components/AsyncComponent/AsyncComponent';

// redux action
import { initiateWhatsappInstanceDialog, showHideInstanceDialog } from '../../actions';

class WhatsApp extends Component {
    state = {
        instances: [],
        loader: true,
        plan_id: '',
        subscription: false,
    };

    getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTableHeadRow: {
                    root: {
                        zIndex: '0',
                    },
                },
                MUIDataTableHeadCell: {
                    fixedHeaderCommon: {
                        zIndex: 0,
                    },
                },
                MUIDataTableSelectCell: {
                    headerCell: {
                        zIndex: 0,
                    },
                    fixedHeaderCommon: {
                        zIndex: 0,
                    },
                },
            },
        });
    componentDidMount() {
        this.database = this.props.firebase.database;
        this.instances = {};
        this.instancesRef = this.database.ref(`/users_instances_whatsapp/${this.props.user.uid}`);
        this.instancesRef.once('value', snapshot => {
            if (!snapshot.val()) {
                this.setState({ loader: false });
            }
        });
        this.instancesRef.orderByChild('ca').on('child_added', snapshot => {
            let item = snapshot.val();
            let newInstances = this.state.instances;
            this.instances[snapshot.key] = item;
            newInstances.unshift(item);
            this.setState({
                instances: newInstances,
            });
            this.setState({ loader: false });
        });

        this.instancesRef.orderByChild('ca').on('child_removed', snapshot => {
            let item = snapshot.val();
            const instances = this.state.instances.filter(instance => instance.instanceId !== item.instanceId);
            this.setState({
                instances,
            });
        });

        this.instancesRef.orderByChild('ca').on('child_changed', snapshot => {
            let instances = this.state.instances;
            for (let i = 0; i < instances.length; i++) {
                if (instances[i].instanceId === snapshot.key) {
                    instances[i] = snapshot.val();
                    this.instances[snapshot.key] = snapshot.val();
                    break;
                }
            }
            this.setState({ instances });
        });
    }

    componentWillUnmount() {
        this.instancesRef.off();
    }

    createNew = () => {
        this.setState({ loader: true });
        api.post(
            `beer/whatsap/create`,
            {},
            {
                headers: {
                    'Access-Token-Id': this.props.userInit.jwt,
                },
            },
        )
            .then(() => {
                this.setState({ loader: false });
            })
            .catch(error => {
                this.setState({ loader: false });
                NotificationManager.error(<IntlMessages id={error.response.data} />);
            });
    };

    deleteInstance = arr => {
        this.props.showHideInstanceDialog(arr);
        return false;
    };

    closeSubscription = () => {
        this.setState({ subscription: false });
    };

    expandInstanceSettings = instance => {
        switch (instance[4]) {
            case 'Stopped':
                NotificationManager.error(<IntlMessages id="whatsap.instanceStatusStopped" />);
                break;
            case 'Expired':
                this.setState({
                    plan_id: this.instances[instance[0].props.value].plan_id,
                    instance_id: instance[0].props.value,
                    subscription: true,
                });
                break;
            case 'Pending':
                NotificationManager.warning(<IntlMessages id="whatsap.awaitingActivation" />);
                break;
            default:
                this.props.initiateWhatsappInstanceDialog(instance[0].props.value, this.instances[instance[0].props.value]);
                break;
        }
    };
    render() {
        const { instances, loader } = this.state;

        const columns = [
            {
                name: 'instanceId',
                label: '#',
                options: {
                    customBodyRender: value => {
                        return <TextField id="name" label="Name" value={value} disabled />;
                    },
                },
            },
            {
                name: 'ex',
                label: 'Expires At',
                options: {
                    customBodyRender: value => {
                        return moment(value).format('YYYY/MM/DD hh:mm:ss');
                    },
                },
            },
            {
                name: 'pr',
                label: 'Price',
            },
            {
                name: 'na',
                label: 'Name',
            },
            {
                name: 'ws',
                label: 'Status',
            },
            {
                name: 'st',
                label: 'Subscription',
            },
        ];

        const options = {
            filterType: 'dropdown',
            responsive: 'scrollMaxHeight',
            onRowsDelete: array => this.deleteInstance(array),
            onRowClick: instance => this.expandInstanceSettings(instance),
        };

        return (
            <div className="data-table-wrapper">
                <Helmet>
                    <title>Instance Manager</title>
                </Helmet>
                <ConfirmInstanceRemove showHideInstanceDialog={this.props.showHideInstanceDialog} userInit={this.props.userInit} instances={this.state.instances} />
                {this.state.subscription && (
                    <SubscribtionForm
                        plan_id={this.state.plan_id}
                        instance_id={this.state.instance_id}
                        closeSubscription={this.closeSubscription}
                        instance={this.props.instance}
                    />
                )}
                <AsyncWhatsapMoreInstanceComponent />
                <PageTitleBar title={<IntlMessages id="sidebar.whatsapp" />} />
                <Button variant="contained" color="primary" onClick={this.createNew} className="text-white btn-lg btn-block mb-10">
                    <IntlMessages id="whatsapp.newInstance" />
                </Button>
                {loader && <RctSectionLoader />}
                <RctCollapsibleCard fullBlock>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable title={<IntlMessages id="whatsapp.tableTitle" />} data={instances} columns={columns} options={options} />
                    </MuiThemeProvider>
                </RctCollapsibleCard>
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ authUser, whatsapp }) => {
    const { user, firebase, userInit } = authUser;
    const { instance } = whatsapp;
    return { user, firebase, instance, userInit };
};

export default compose(
    withWidth(),
    connect(mapStateToProps, {
        initiateWhatsappInstanceDialog,
        showHideInstanceDialog,
    }),
)(WhatsApp);
