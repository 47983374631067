import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import api from 'Api';

import withMobileDialog from '@material-ui/core/withMobileDialog';

// intl messages
import IntlMessages from 'Util/IntlMessages';

// rct card box
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

import PayPalSubscribe from './paypal';

const SubscriptionForm = ({ plan_id, instance_id, closeSubscription, userInit }) => {
    const [success, setSuccess] = useState(false);
    const [subscribe, setSubscribe] = useState(true);
    const [error, setError] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [loader, setLoader] = useState(false);

    const onError = error => {
        setSubscribe(false);
        setError(true);
        setSuccess(false);
        setCancel(false);
        setLoader(false);
    };
    const onCancel = cancel => {
        setSubscribe(false);
        setError(false);
        setSuccess(false);
        setCancel(true);
        setLoader(false);
    };

    const onApprove = approve => {
        setSubscribe(false);
        setLoader(true);
        api.post(
            `/subscription/confirm`,
            {
                plan_id: plan_id,
                subscription_id: approve.subscriptionID,
                instance_id: instance_id,
            },
            {
                headers: {
                    'Access-Token-Id': userInit.jwt,
                },
            },
        )
            .then(response => {
                setError(false);
                setSuccess(true);
                setCancel(false);
                setLoader(false);
            })
            .catch(error => {
                setError(true);
                setSuccess(false);
                setCancel(false);
                setLoader(false);
            });
    };

    const handleClose = () => {
        closeSubscription();
    };
    const emptyClose = () => {};

    return (
        <div>
            {loader && (
                <SweetAlert
                    showConfirm={false}
                    title={<IntlMessages id="payment.loader.title" />}
                    onConfirm={handleClose}
                >
                    <RctSectionLoader />
                    <IntlMessages id="payment.loader" />
                </SweetAlert>
            )}
            {success && (
                <SweetAlert success title={<IntlMessages id="payment.success.title" />} onConfirm={handleClose}>
                    <IntlMessages id="payment.success" />
                </SweetAlert>
            )}
            {cancel && (
                <SweetAlert warning title={<IntlMessages id="payment.cancel.title" />} onConfirm={handleClose}>
                    <IntlMessages id="payment.cancel" />
                </SweetAlert>
            )}
            {error && (
                <SweetAlert warning title={<IntlMessages id="payment.error.title" />} onConfirm={handleClose}>
                    <IntlMessages id="payment.error" />
                </SweetAlert>
            )}
            {subscribe && (
                <SweetAlert
                    onConfirm={emptyClose}
                    showCancel
                    showConfirm={false}
                    onCancel={handleClose}
                    onClose={handleClose}
                    cancelBtnBsStyle="danger"
                    btnSize="sm"
                    title={<IntlMessages id="payment.subscribe.title" />}
                >
                    <PayPalSubscribe
                        clientId={'AVSWo4KUYxdz2Wk028xvOJCMryb_Kxab4Vf1g4j86poeONmGK7gBZ9kSKE0atGbcO4FE0lsNFazXZiNl'}
                        planId={plan_id}
                        instanceId={instance_id}
                        onCancel={onCancel}
                        onApprove={onApprove}
                        onError={onError}
                    />
                </SweetAlert>
            )}
        </div>
    );
};

const mapStateToProps = ({ authUser }) => {
    const { userInit } = authUser;
    return { userInit };
};

export default compose(connect(mapStateToProps), withMobileDialog())(SubscriptionForm);
