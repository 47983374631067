/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */

import {
    FLVBY_INSTANCE_DIALOG_EXPAND,
    FLVBY_SELECT_USER_TO_EDIT,
    FLVBY_CONFIRM_USER_REMOVE,
    FLVBY_INSTANCE_CONTROL,
    FLVBY_UPDATE_CURRENT_INSTANCE,
    FLVBY_SET_USER,
    FLVBY_CREATE_PP_PRODUCT,
} from './types';

/**
 * Create PP Product dialog
 */
export const flvbyShowHideCreatePPProductDialog = product => ({
    type: FLVBY_CREATE_PP_PRODUCT,
    payload: product,
});

/**
 * Show/hide remove instance dialog
 */
export const flvbyShowHideInstanceDialog = instance => ({
    type: FLVBY_INSTANCE_DIALOG_EXPAND,
    payload: instance,
});

export const flvbySetUser = user => ({
    type: FLVBY_SET_USER,
    payload: user,
});

export const flvbyUpdateCurrentInstance = instance => ({
    type: FLVBY_UPDATE_CURRENT_INSTANCE,
    payload: instance,
});

export const selectUserToEdit = user => ({
    type: FLVBY_SELECT_USER_TO_EDIT,
    payload: user,
});

export const confirmUserRemove = user => ({
    type: FLVBY_CONFIRM_USER_REMOVE,
    payload: user,
});

export const showInstanceControlDialog = instance => ({
    type: FLVBY_INSTANCE_CONTROL,
    payload: instance,
});
