import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
// rct card box
import IntlMessages from 'Util/IntlMessages';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import MoreAboutSubscription from './MoreAboutSubscription';

const PaymentTable = ({ user, firebase, userInit, setLoading }) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [more, setMore] = useState(false);
    const [moreSub, setMoreSub] = useState([]);
    const userSubscription = firebase.database.ref(`/users_subscription/${user.uid}`);

    useEffect(() => {
        userSubscription.once('value', snapshot => {
            if (!snapshot.val()) {
                setLoading(false);
            }
        });

        userSubscription.on('child_added', snapshot => {
            const item = snapshot.val();
            subscriptions.push({ ...item, id: snapshot.key });
            subscriptions.sort((a, b) => {
                return b.create_time - a.create_time;
            });
            setSubscriptions([...subscriptions]);
            setLoading(false);
        });

        userSubscription.on('child_changed', snapshot => {
            for (let key in subscriptions) {
                if (subscriptions[key].id === snapshot.key) {
                    subscriptions[key] = snapshot.val();
                    setSubscriptions([...subscriptions]);
                    break;
                }
            }
        });
        userSubscription.on('child_removed', snapshot => {
            setSubscriptions([...subscriptions.filter(item => item.id !== snapshot.key)]);
        });

        return () => {
            userSubscription.off();
        };
    }, [user]);

    const findOutMore = subscription => {
        if (subscription[5] === 'INACTIVE') {
            NotificationManager.warning(<IntlMessages id="Subscription is already inactive" />);
            return;
        }
        setMoreSub(subscription);
        setMore(true);
    };

    const callback = () => {
        setMore(false);
    };

    const columns = [
        {
            name: 'id',
            label: 'Subscription ID',
            options: {
                customBodyRender: value => {
                    return <TextField id="name" label="Name" value={value} disabled />;
                },
            },
        },
        {
            name: 'instance_id',
            label: 'Instance ID',
            options: {
                customBodyRender: value => {
                    return <TextField id="name" label="Name" value={value} disabled />;
                },
            },
        },
        {
            name: 'create_time',
            label: 'Date',
            options: {
                filter: false,
                customBodyRender: value => {
                    return moment(value).format('YYYY/MM/DD hh:mm:ss');
                },
            },
        },
        {
            name: 'price',
            label: 'Price',
        },
        {
            name: 'cycles_completed',
            label: 'Payments(n)',
        },
        {
            name: 'status',
            label: 'Status',
        },
        {
            name: 'Action',
            options: {
                filter: false,
                customBodyRender: (_, tableMeta) => (
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => findOutMore(tableMeta.rowData)}
                        disabled={tableMeta.rowData[5] === 'INACTIVE' ? true : false}
                    >
                        <IntlMessages id="button.cancel" />
                    </Button>
                ),
            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        responsive: 'scrollMaxHeight',
        selectableRows: 'none',
    };
    return (
        <div>
            <MoreAboutSubscription userInit={userInit} more={more} callback={callback} subscription={moreSub} />
            <MUIDataTable data={subscriptions} columns={columns} options={options} />
        </div>
    );
};

export default PaymentTable;
