import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

const PaymentTable = ({ user, firebase, setLoading }) => {
    const [payments, setPayments] = useState([]);
    const userPayments = firebase.database.ref(`/users_payments/${user.uid}`);

    useEffect(() => {
        userPayments.once('value', snapshot => {
            if (!snapshot.val()) {
                setLoading(false);
            }
        });

        userPayments.on('child_added', snapshot => {
            const item = snapshot.val();
            payments.push({ ...item, id: snapshot.key });
            payments.sort((a, b) => {
                return b.create_time - a.create_time;
            });
            setPayments([...payments]);
            setLoading(false);
        });

        userPayments.on('child_changed', snapshot => {
            for (let key in payments) {
                if (payments[key].id === snapshot.key) {
                    payments[key] = snapshot.val();
                    setPayments([...payments]);
                    break;
                }
            }
        });
        userPayments.on('child_removed', snapshot => {
            setPayments([...payments.filter(item => item.id !== snapshot.key)]);
        });
        return () => {
            userPayments.off();
        };
    }, [user]);

    const columns = [
        {
            name: 'id',
            label: 'Payment Id',
        },
        {
            name: 'create_time',
            label: 'Date',
            options: {
                filter: false,
                customBodyRender: value => {
                    return moment(value).format('YYYY/MM/DD hh:mm:ss');
                },
            },
        },
        {
            name: 'subscription_id',
            label: 'Subscription ID',
        },
        {
            name: 'amount',
            label: 'Price',
        },
        {
            name: 'status',
            label: 'Status',
        },
    ];

    const options = {
        filterType: 'dropdown',
        responsive: 'scrollMaxHeight',
        selectableRows: 'none',
    };
    return (
        <div className="data-table-wrapper">
            <RctCollapsibleCard fullBlock>
                <MUIDataTable data={payments} columns={columns} options={options} />
            </RctCollapsibleCard>
        </div>
    );
};

export default PaymentTable;
