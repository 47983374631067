/**
 * Responsive Dialog
 */

import { compose } from 'redux';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

// api
import api from 'Api';

import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// intl messages
import IntlMessages from 'Util/IntlMessages';

// rct card box
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

class ConfirmInstanceRemove extends PureComponent {
    state = {
        loader: false,
    };
    removeInstance = () => {
        this.setState({ loader: true });
        api.delete(`beer/whatsap/delete`, {
            data: {
                instance: this.props.instancesToRemove.data.map(item => this.props.instances[item.index].instanceId),
            },
            headers: {
                'Access-Token-Id': this.props.userInit.jwt,
            },
        })
            .then(response => {
                this.setState({ loader: false });
                NotificationManager.success(<IntlMessages id="whatsapp.instanceRemoved" />);
                this.props.showHideInstanceDialog();
            })
            .catch(error => {
                this.setState({ loader: false });
                NotificationManager.error(<IntlMessages id="whatsapp.cantRemoveInstance" />);
                this.props.showHideInstanceDialog();
            });
    };

    handleClose = () => {
        this.props.showHideInstanceDialog();
    };

    render() {
        const { fullScreen, removeInstanceDialog } = this.props;
        return (
            <Dialog
                fullScreen={fullScreen}
                open={removeInstanceDialog}
                onClose={this.handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                {this.state.loader && <RctSectionLoader />}
                <DialogTitle id="responsive-dialog-title">
                    <IntlMessages id="whatsapp.removeInstancesDialog" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <IntlMessages id="whatsapp.removeInstanceWarning" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={this.handleClose} className="btn-secondary text-white">
                        <IntlMessages id="button.cancel" />
                    </Button>
                    <Button
                        variant="contained"
                        onClick={this.removeInstance}
                        color="primary"
                        className="btn-danger text-white"
                        autoFocus
                    >
                        <IntlMessages id="button.delete" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = ({ whatsapp }) => {
    const { removeInstanceDialog, instancesToRemove } = whatsapp;
    return { removeInstanceDialog, instancesToRemove };
};

export default compose(connect(mapStateToProps), withMobileDialog())(ConfirmInstanceRemove);
