/**
 * Sidebar Content
 */
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import IntlMessages from 'Util/IntlMessages';

import NavMenuItem from './NavMenuItem';

// redux actions
import { onToggleMenu } from 'Actions';

class SidebarContent extends Component {
    toggleMenu(menu, stateCategory) {
        let data = {
            menu,
            stateCategory,
        };
        this.props.onToggleMenu(data);
    }

    render() {
        const { sidebarMenus } = this.props.sidebar;
        const { userInit } = this.props;
        return (
            <div className="rct-sidebar-nav">
                <nav className="navigation">
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled"
                        subheader={
                            <ListSubheader className="side-title" component="li">
                                <IntlMessages id="sidebar.services" />
                            </ListSubheader>
                        }
                    >
                        {sidebarMenus.services.map((menu, key) => (
                            <NavMenuItem menu={menu} key={key} onToggleMenu={() => this.toggleMenu(menu, 'services')} />
                        ))}
                    </List>
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled"
                        subheader={
                            <ListSubheader className="side-title" component="li">
                                <IntlMessages id="sidebar.developer" />
                            </ListSubheader>
                        }
                    >
                        {sidebarMenus.developer.map((menu, key) => (
                            <NavMenuItem
                                menu={menu}
                                key={key}
                                onToggleMenu={() => this.toggleMenu(menu, 'developer')}
                            />
                        ))}
                    </List>
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled"
                        subheader={
                            <ListSubheader className="side-title" component="li">
                                <IntlMessages id="sidebar.payments" />
                            </ListSubheader>
                        }
                    >
                        {sidebarMenus.payment.map((menu, key) => (
                            <NavMenuItem menu={menu} key={key} onToggleMenu={() => this.toggleMenu(menu, 'payments')} />
                        ))}
                    </List>
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled"
                        subheader={
                            <ListSubheader className="side-title" component="li">
                                <IntlMessages id="sidebar.flowBuilder" />
                            </ListSubheader>
                        }
                    >
                        {sidebarMenus.flowBuilder.map((menu, key) => (
                            <NavMenuItem
                                menu={menu}
                                key={key}
                                onToggleMenu={() => this.toggleMenu(menu, 'flowBuilder')}
                            />
                        ))}
                    </List>
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled"
                        subheader={
                            <ListSubheader className="side-title" component="li">
                                <IntlMessages id="sidebar.help" />
                            </ListSubheader>
                        }
                    >
                        {sidebarMenus.help.map((menu, key) => (
                            <NavMenuItem menu={menu} key={key} onToggleMenu={() => this.toggleMenu(menu, 'help')} />
                        ))}
                    </List>
                    {userInit.isAdmin ? (
                        <List
                            className="rct-mainMenu p-0 m-0 list-unstyled"
                            subheader={
                                <ListSubheader className="side-title" component="li">
                                    <IntlMessages id="sidebar.flvby" />
                                </ListSubheader>
                            }
                        >
                            {sidebarMenus.flvby.map((menu, key) => (
                                <NavMenuItem
                                    menu={menu}
                                    key={key}
                                    onToggleMenu={() => this.toggleMenu(menu, 'flvby')}
                                />
                            ))}
                        </List>
                    ) : (
                        ' '
                    )}
                </nav>
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ sidebar, authUser }) => {
    const { userInit } = authUser;
    return { sidebar, userInit };
};

export default withRouter(
    connect(mapStateToProps, {
        onToggleMenu,
    })(SidebarContent),
);
