/*======= Include All App Css =======*/
// Use For Switch
import 'react-toggle-switch/dist/css/switch.min.css';

// Use Themify Icon
import 'Assets/themify-icons/themify-icons.css';
// Use For Full Calender

// notifications
import 'react-notifications/lib/notifications.css';
// tour
import 'react-joyride/lib/react-joyride-compiled.css';
// calendar

// Custom Style File
import 'Assets/scss/_style.scss';
