/**
 * App Config File
 */
const AppConfig = {
    valueLen: process.env.VALUE_LEN, // Custom fields value length
    descriptionLen: process.env.DESCRIPTION_LEN, // Custom fields description length
    nameLength: process.env.NAME_LEN, // Custom fields name length
    appLogo: require('Assets/img/logo_white.png'), // App Logo
    signInTitle: 'Get started with ApiRail',
    signInSubTitle: 'Most powerful Api Gateway Service',
    navCollapsed: false, // Sidebar collapse
    darkMode: false, // Dark Mode
    boxLayout: false, // Box Layout
    rtlLayout: false, // RTL Layout
    miniSidebar: false, // Mini Sidebar
    enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
    sidebarImage: require('Assets/img/sidebar-4.jpg'), // Select sidebar image
    isDarkSidenav: true, // Set true to dark sidebar
    enableThemeOptions: true, // Enable Theme Options
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'en',
    },
    enableUserTour: process.env.NODE_ENV === 'production' ? false : false, // Enable / Disable User Tour
    copyRightText: 'ApiRail © 2019-2020 All Rights Reserved.', // Copy Right Text
    mainApiHost: process.env.NODE_ENV === 'production' ? 'https://control.apirail.com/' : 'http://127.0.0.1:9000/',
    // light theme colors
    themeColors: {
        primary: '#5D92F4',
        secondary: '#677080',
        success: '#00D014',
        danger: '#FF3739',
        warning: '#FFB70F',
        info: '#00D0BD',
        dark: '#464D69',
        default: '#FAFAFA',
        greyLighten: '#A5A7B2',
        grey: '#677080',
        white: '#FFFFFF',
        purple: '#896BD6',
        yellow: '#D46B08',
    },
    // dark theme colors
    darkThemeColors: {
        darkBgColor: '#424242',
    },
};

export default AppConfig;
