/**
 * Chat
 */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { Helmet } from 'react-helmet';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';

// components

import { AsyncChatAreaComponent, AsyncChatSidebarComponent } from 'Components/AsyncComponent/AsyncComponent';

const drawerWidth = 310;

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            width: '100%',
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: 230,
            [theme.breakpoints.up('md')]: {
                position: 'relative',
                width: drawerWidth,
            },
            backgroundColor: '#fff',
        },
        drawerHeight: {
            height: '100%',
        },
        content: {
            flexGrow: 1,
        },
    }),
);

const ChatList = () => {
    const theme = useTheme();
    const classes = useStyles();

    const [mobileOpen, setMobileOpen] = useState(false);

    const firebase = useSelector(state => state.authUser.firebase);
    const user = useSelector(state => state.authUser.user);
    const userInit = useSelector(state => state.authUser.userInit);

    const drawer = <AsyncChatSidebarComponent user={user} firebase={firebase} userInit={userInit} />;

    return (
        <div className="chat-wrapper">
            <Helmet>
                <title>Support</title>
            </Helmet>
            <div className={classes.root}>
                <Hidden mdUp className="user-list-wrap">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={() => setMobileOpen(!mobileOpen)}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css" className="user-list-wrap">
                    <Drawer
                        variant="permanent"
                        open
                        classes={{
                            paper: classes.drawerPaper,
                            docked: classes.drawerHeight,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <div className={`chat-content ${classes.content}`}>
                    <AsyncChatAreaComponent onMenuIconPress={() => setMobileOpen(!mobileOpen)} userInit={userInit} user={user} firebase={firebase} />
                </div>
            </div>
        </div>
    );
};

export default ChatList;
