/**
 * App.js Layout Start Here 
 */ 
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

// rct theme provider
import RctThemeProvider from './RctThemeProvider';

//Main App
import RctDefaultLayout from './DefaultLayout';

// boxed layout

// async components
import {
   AsyncSessionLockScreenComponent,
   AsyncForgotPasswordComponent,
   AsyncSessionPage404Component,
   AsyncSessionPage500Component,
   AsyncTermsConditionComponent,
   AsyncSignInComponent,
   AsyncSignUpComponent
} from 'Components/AsyncComponent/AsyncComponent';


// redux action
import {
   setUserInit,
   logoutUserFromFirebaseSuccess,
} from '../actions';

/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = ({ component: Component, ...rest, authUser }) =>
   <Route
      {...rest}
      render={props =>
         authUser
            ? <Component {...props} />
            : <Redirect
               to={{
                  pathname: '/signin',
                  state: { from: props.location }
               }}
            />}
   />;

class App extends PureComponent {
 
   componentDidMount(){
      let that = this;
      this.authListener = this.props.firebase.auth.onAuthStateChanged( (user)=>{
         if (!user){
            if (this.props.user){
               this.props.logoutUserFromFirebaseSuccess();
            }
            this.props.history.push(`/signin`);
         }else{
            this.props.firebase.database.ref(`users/${user.uid}/isAdmin`)
            .on('value', async (snapshot) => {
               this.props.setUserInit({ isAdmin: snapshot.val()});
            });
         }
      });

      this.onIdTokenChanged = this.props.firebase.auth.onIdTokenChanged(function(user) {
         if (user) {
            that.props.setUserInit({ jwt: user._lat });
         }
       });
   }  

   componentWillUnmount(){
      this.authListener();
      this.onIdTokenChanged();
   }

   render() {
      const { location, match, user } = this.props;
      if (location.pathname === '/') {
         if (user === null) {
            return (<Redirect to={'/signin'} />);
         } else {
            return (<Redirect to={'/app/whatsapp'} />);
         }
      }
      return (
         <RctThemeProvider>
            <NotificationContainer />
            <InitialPath
               path={`${match.url}app`}
               authUser={user}
               component={RctDefaultLayout}
            />
            <Route path="/signin" component={AsyncSignInComponent} />
            <Route path="/signup" component={AsyncSignUpComponent} />
            <Route
               path="/forgot-password"
               component={AsyncForgotPasswordComponent}
            />
            <Route path="/session/lock-screen" component={AsyncSessionLockScreenComponent} />
            <Route path="/session/404" component={AsyncSessionPage404Component} />
            <Route path="/session/500" component={AsyncSessionPage500Component} />
            <Route path="/terms-condition" component={AsyncTermsConditionComponent} />
         </RctThemeProvider>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { user, firebase, userInit } = authUser;
   return { user, firebase, userInit };
};

export default connect(mapStateToProps,{
   setUserInit,
   logoutUserFromFirebaseSuccess,
})(App);
