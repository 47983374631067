/**
 * Auth User Reducers
 */
import { NotificationManager } from 'react-notifications';

import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_FAILURE,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    VERIFICATION_EMAIL,
    SET_USER_INIT,
} from 'Actions/types';

import { auth, database, firestore } from '../firebase';
/**
 * initial auth user
 */
const INIT_STATE = {
    user: JSON.parse(localStorage.getItem('user')),
    firebase: { auth, database, firestore },
    loading: false,
    userInit: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_USER_INIT:
            return {
                ...state,
                userInit: {
                    ...state.userInit,
                    ...action.payload,
                },
            };

        case LOGIN_USER:
            return { ...state, loading: true };

        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload };

        case LOGIN_USER_FAILURE:
            NotificationManager.error(action.payload);
            return { ...state, loading: false };

        case LOGOUT_USER:
            return { ...state };

        case LOGOUT_USER_SUCCESS:
            return { ...state, user: null };

        case LOGOUT_USER_FAILURE:
            return { ...state };

        case SIGNUP_USER:
            return { ...state, loading: true };

        case SIGNUP_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload };

        case SIGNUP_USER_FAILURE:
            NotificationManager.error(action.payload);
            return { ...state, loading: false };

        case VERIFICATION_EMAIL:
            NotificationManager.success('Verification email was sent. Please confirm your email');
            return { ...state };

        default:
            return { ...state };
    }
};
