// sidebar nav links
export default {
    services: [
        {
            menu_title: 'sidebar.whatsapp',
            menu_icon: 'zmdi zmdi-phone',
            path: '/app/whatsapp',
            child_routes: null,
        },
    ],
    developer: [
        {
            menu_title: 'sidebar.apiDocs',
            menu_icon: 'zmdi zmdi-settings',
            path: '/app/api-docs',
            child_routes: null,
        },
        {
            menu_title: 'sidebar.testingArea',
            menu_icon: 'zmdi zmdi-adb',
            path: '/app/testing-area',
            child_routes: null,
        },
    ],
    payment: [
        {
            menu_title: 'sidebar.payments',
            menu_icon: 'zmdi zmdi-card-membership',
            path: '/app/payments',
            child_routes: null,
        },
    ],
    flowBuilder: [
        {
            menu_title: 'sidebar.flowBuilder',
            menu_icon: 'zmdi zmdi-device-hub',
            path: '/app/flow-builder',
            child_routes: null,
        },
        {
            menu_title: 'sidebar.flowValues',
            menu_icon: 'zmdi zmdi-code',
            path: '/app/flow-values',
            child_routes: null,
        },
        {
            menu_title: 'sidebar.flowHelper',
            menu_icon: 'zmdi zmdi-pin-help',
            path: '/app/flow-builder-help',
            child_routes: null,
        },
    ],
    help: [
        {
            menu_title: 'sidebar.supportCenter',
            menu_icon: 'zmdi zmdi-comments',
            path: '/app/support',
            child_routes: null,
        },
        {
            menu_title: 'sidebar.faq',
            menu_icon: 'zmdi zmdi-help-outline',
            path: '/app/faq',
            child_routes: null,
        },
    ],

    flvby: [
        {
            menu_title: 'sidebar.flvby',
            menu_icon: 'ti-na',
            path: '/app/flvby',
            child_routes: null,
        },
        {
            menu_title: 'sidebar.flvbySupport',
            menu_icon: 'zmdi zmdi-comments',
            path: '/app/flvby-support',
            child_routes: null,
        },
    ],
};
