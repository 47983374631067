import React, { PureComponent } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { NotificationManager } from 'react-notifications';
import SyntaxHighlighter from 'react-syntax-highlighter';

// intl messages

import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

class WebHookTest extends PureComponent {
    state = {
        instances: [],
        instance: {},
        webhookurl: '',
        message: 'WhatsApp API - https://apirail.com test message',
        response: '{}',
        disabled: true,
        notifications: [
            {
                value: 'new',
                description: 'New message received',
                json: `
{ 
    instanceId: '-LcmOrXc_JFj-xkzT_Nb',
    message: { 
        id: '3052E969BD664476EE0D',
        isForwarded: false,
        fromMe: true,
        authorId: '380731922460@c.us',
        chatId: '380731922460@c.us',
        chatName: '',
        senderName: 'Sender',
        chatType: 'personal',
        type: 'text',
        receiverName: 'Receiver',
        timestamp: 1556501618,
        completed: true,
        body: 'New Message Received' 
    } 
}
`,
            },
            {
                value: 'delivered',
                description: 'Message - Delivered',
                json: `
{ 
    instanceId: '-LcmOrXc_JFj-xkzT_Nb',
    ack: { 
        id: 'AEC0C152EF75EDF3A2A99E48242EFDC9',
        fromMe: true,
        from: '380731922460@c.us',
        to: '380731922460@c.us',
        timestamp: 1556560056,
        status: 'delivered' 
    } 
}
`,
            },
            {
                value: 'viewed',
                description: 'Message - Viewed',
                json: `
{ 
    instanceId: '-LcmOrXc_JFj-xkzT_Nb',
    ack: { 
        id: 'AEC0C152EF75EDF3A2A99E48242EFDC9',
        fromMe: true,
        from: '380731922460@c.us',
        to: '380731922460@c.us',
        timestamp: 1556560056,
        status: 'viewed' 
    } 
}
`,
            },
        ],
        json: `{}`,
    };

    componentDidMount() {
        this.database = this.props.firebase.database;

        this.instancesRef = this.database.ref(`/users_instances_whatsapp/${this.props.user.uid}`);
        this.instancesRef.on('value', snapshot => {
            let items = snapshot.val();
            let store = [];
            for (let item in items) {
                if (items[item].ws !== 'Expired') {
                    store.push({ id: item, ...items[item] });
                }
            }
            this.setState({
                instances: store,
                notification: this.state.notifications[0],
                instance: store.length ? store[0] : {},
                webhookurl: store.length ? store[0].wh : '',
                json: this.state.notifications[0].json,
                disabled: store.length ? false : true,
            });
        });
    }

    componentWillUnmount() {
        this.instancesRef.off('value');
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
            ...(name === 'instance'
                ? { webhookurl: event.target.value.wh, disabled: event.target.value.wh ? false : true }
                : {}),
            ...(name === 'notification' ? { json: event.target.value.json } : {}),
        });
    };

    sendEvent = () => {
        this.setState({ disabled: true });
        axios
            .post(`https://api.apirail.com/${this.state.instance.id}/webHookTest?token=${this.state.instance.api}`, {
                type: this.state.notification.value,
            })
            .then(response => {
                this.setState({ disabled: false });
                if (response.data.status) {
                    NotificationManager.success('Event send');
                } else {
                    NotificationManager.error(response.data.message);
                }
            })
            .catch(error => {
                this.setState({ disabled: false });
                if (error.response.data.message) {
                    NotificationManager.error(error.response.data.message);
                } else {
                    NotificationManager.error('Cant send request');
                }
            });
    };

    render() {
        const { disabled, webhookurl } = this.state;

        return (
            <div className="formelements-wrapper">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-6">
                        <RctCollapsibleCard heading="WebHook Event Emulator">
                            <Form>
                                <FormGroup>
                                    <TextField
                                        id="select-currency"
                                        select
                                        label="Select instance"
                                        value={this.state.instance}
                                        onChange={this.handleChange('instance')}
                                        SelectProps={{
                                            MenuProps: {},
                                        }}
                                        fullWidth
                                    >
                                        {this.state.instances.map(option => (
                                            <MenuItem key={option.id} value={option}>
                                                {option.id}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormGroup>
                                <FormGroup>
                                    <TextField
                                        required
                                        id="required"
                                        type="url"
                                        fullWidth
                                        value={this.state.webhookurl}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextField
                                        id="select-currency"
                                        select
                                        value={this.state.notification || ''}
                                        onChange={this.handleChange('notification')}
                                        SelectProps={{
                                            MenuProps: {},
                                        }}
                                        fullWidth
                                    >
                                        {this.state.notifications.map(option => (
                                            <MenuItem key={option.value} value={option}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormGroup>
                                <Button color="primary" onClick={this.sendEvent} disabled={webhookurl ? false : true}>
                                    Send
                                </Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xl-6">
                        <h4 className="alert-heading">Request</h4>
                        <div>
                            <SyntaxHighlighter language="javascript">{this.state.json}</SyntaxHighlighter>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WebHookTest;
