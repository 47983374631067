// app config
import AppConfig from 'Constants/AppConfig';

module.exports = {
    //Flow Builder
    'please set intiator and menu': 'Please set intiator and menu.',
    'please set user input command': 'Please set user input command.',
    'please set user message text': 'Please set user message text.',
    'please set url and caption': 'Please set url and caption.',
    'please set longtitude, latitude and address': 'Please set longtitude, latitude and address.',
    'new flow was created': 'New flow was created.',
    'flow name': 'Flow name',
    'bot name': 'Bot name',
    'please enter flow name': 'Please enter flow name.',
    'please enter bot name': 'Please enter bot name.',
    'create new flow': 'Create the new flow',
    'select flow': 'Select Flow',
    'select value': 'Select Value',
    'fallback value': 'Fallback Value',
    'field value': 'Field Value',
    'new flow': 'New Flow',
    'clone flow': 'Clone Flow',
    'flow settings': 'Flow Settings',
    'import demo flow': 'Import Demo Flow',
    'flow ID or flow name are missing': 'Flow ID or flow name are missing',
    'flow was imported to your flow list': 'Flow was imported to your flow list',
    'demo flow list': 'Demo flow list',
    'select demo flow': 'Select Demo Flow',
    'clone to a new flow': 'Clone current flow and create a new one?',
    'please choose different name': 'Please choose a different name.',
    'flow was saved': 'Flow was saved.',
    'flow ID or Flow are missing': 'Flow ID or Flow are missing.',
    'flow id does not exist': 'Flow does not exist.',
    'cant save flow. please try again later.': 'Cant save flow. Please try again later.',
    'cant publish flow. please try again later.': 'Cant publish flow. Please try again later.',
    'flow ID is missing': 'Flow ID is missing.',
    'flow was cloned': 'Flow was cloned.',
    'something is wrong with the selected flow. please contact support': 'Something is wrong with the selected flow. Please contact support.',
    'cant load flow. please try again later.': 'Cant load flow. Please try again later.',
    'delete flow?': 'Delete flow?',
    'flow will be removed': 'Everything related to the flow will be permanently removed',
    'flow is binded': 'Flow is still bind with the instances. You need to remove binding.',
    'flow was published': 'Flow was published.',
    'cant clone chose flow': `Can't clone selected flow.`,
    'bot name limit is 20': `Bot Name can't be longer than 20 characters`,
    'flow name limit is 40': `Flow Name can't be longer than 40 characters`,
    'Flow List': 'Flow List',
    'click to add text': 'click to add text',
    'click to add command': 'click to add command',
    'max menu reached': 'You can only add up to 10 menu options.',
    'disable flow': 'Disable Flow',
    'static fields': 'Static Fields',
    'user fields': 'User Fields',
    'bot fields': 'Bot Fields',
    flow: 'Flow',
    'user input': 'Input',
    message: 'Message',
    sent: 'Sent',
    executed: 'Executed',
    condition: 'Condition',
    field: 'Field',
    http: 'Http Request',
    key: 'Key',
    value: 'Value',
    'Request URL. Only https links are allowed': 'Request URL. Only https links are allowed',
    'Request Method': 'Request Method',
    'add request header': 'Add Request Header',
    'add body parameter': 'Add Body Parameter',
    'add response mapping': 'Add Response Mapping',
    'response mapping': 'Response Mapping',
    response: 'Response',
    headers: 'Headers',
    body: 'Body',
    'flow_values_bot.info':
        'Bot field can store a static value that can be used to customize messages in the Flow Builder. For example store your default website url, business address and etc.',
    'flow_values_user.info':
        'User field can store a dynamic value. For example you can create a field with the name `age` and in the flow builder ask the user about the age and store his response in this field for the future usage in the flow.',
    'json path': 'JSON Path',
    'changes were saved': 'Changes were saved.',
    'you are viewing a published version': 'You are viewing a published version',
    'test the flow': 'Test curret flow',
    'port is been used': 'Before removing this port you need to remove binding',
    'this port is already linked to another node': 'This port is already linked to another node.',
    'nodes without binding': 'Please review your flow. Some nodes are without binding.',
    'nodes not completed': 'Please review your flow. Some nodes are incomplete.',
    "you can't link same ports": `You can't link same ports.`,
    "this node can't accept inbount links": `This node can't accept inbount links.`,
    "you can't link ports in the same node": `You can't link ports in the same node.`,
    'not possible to link nodes this way': 'Not possible to link nodes this way.',
    'configure request': 'Configure request',
    'Edit Request': 'Edit Request',
    'payload is missing': 'Request payload is missing.',
    'bad payload': 'Request payload is incorrect. Try again or contact support.',
    'only https url': 'Only HTTPS url is supported.',
    'bad body': 'Request body is incorrect. Try again or contact support.',
    'bad headers': 'Request headers are incorrect. Try again or contact support.',
    'method or url is missing': 'Request method and url are required.',
    'bad method': 'Request supports only GET and POST methods.',
    'bad request': 'Bad request response or not a valid JSON.',
    'good response': 'Request was successful',
    'add condition': 'Add Condition',
    'if not matching condition above': 'Does not match any conditions',
    'configure condition': 'Configure Condition',
    'manage condition': 'Manage Condition',
    'Configured conditions were saved. Dont forget to save your flow': `Configured conditions were saved. Don't forget to save your flow`,
    'Cant save uncompleted conditions': `Can't save uncompleted conditions`,
    'condition created': 'Condition Created',
    'edit condition': 'Edit Condition',
    'save condition': 'Save Conditions',
    'start flow cant be linked with the condition or user input block': `Start flow can't be linked with the Condition and User Input blocks`,

    //Flow tester
    'enter message': 'Enter Message',
    'flow tester': 'Flow Testing Chat',

    'helper Flow Name title': 'Flow Name',
    'helper Flow Name description': 'Flow name. Plain and simple',

    'helper Bot Name title': 'Bot Name',
    'helper Bot Name description':
        'In the Group Chats bot won\'t react on all messages send by the Group Participants to prevent spam. In order to call bot in the Group Chat you can set custom name for example "robot" and in the Group Chat you can activate the bot by sending "@robot BLAH BLAH" ',

    //Flow Node Helper
    'helper Start Flow title': 'Start Flow',
    'helper Start Flow description': 'Main menu. If user will send unknown command he will receive message text from this block.',
    'helper User Input title': 'User Input',
    'helper User Input description': 'You can store user reply (only text message) in a custom field for further usage.',

    'helper Text title': 'Text Message',
    'helper Text description': 'Plain text message.',

    'helper File title': 'File Message',
    'helper File description': 'You can use this block to send Image, Video and almost any file type messages. You will need to provide a URL to the file.',

    'helper Request title': 'Http Request',
    'helper Request description':
        'You can send external GET/POST HTTPS requests with User or Bot fields and save response to a User Field. Only JSON response is accepted and url should be HTTPS',

    'helper Condition title': 'Condition',
    'helper Condition description': 'Conditional block. For example if customer age is les then 18 the forward him to one block else to another block in the flow.',

    'helper Location title': 'Location Message',
    'helper Location description':
        'You can use this block to send a Location Message, fill out Longtitue, Latitude and Address of your bussiness(example) and send this message to your clients.',

    'helper Audio title': 'Audio Message',
    'helper Audio description': 'This block will send message as it was recorded via microphone. You will need to provide a URL to the audio ogg-file in Opus Codec.',

    'helper Field Value title': 'Field Value',
    'helper Field Value description':
        'You can customise current message with a Bot and User fields. Both fields can be created on Custom Field page. Bot field is a static value that you can change anytime. In User field you can store any information that you request from any user, for example you can ask your customer for an Email and store it in an Email field for further usage in the flow.',

    'helper Fallback Value title': 'Fallback Value',
    'helper Fallback Value description': 'This value will be used if there will be a problem with the field value.',

    'helper Webhook Url title': 'Webhook Url',
    'helper Webhook Url description':
        'Use this option if you would like to receive HTTP POST notifications about new incoming messages, changes in the chats and groups. For example: https://exmaple.com/webhook',

    'helper Set Proxy title': 'Proxy Settings',
    'helper Set Proxy description':
        'By default we are using our own proxy, but you can use your own Socks proxy by filling out proxy details in the current field. For example: 127.2.3.2:2323',

    'helper Update Name title': 'Set Name',
    'helper Update Name description': 'Nothing special here. Set instance name. For example: bob',

    'helper File Url title': 'File Url',
    'helper File Url description':
        'The direct URL to the file. It can be image, video, pdf and etc. NOTE: Maximum file size is 2MB. Files larger then 2MB will be ignored',

    'helper Audio File Url title': 'Audio File Url',
    'helper Audio File Url description': 'The direct URL to the audio ogg-file in Opus Codec. NOTE: Maximum file size is 2MB. Files larger then 2MB will be ignored',

    'helper Update Bot Name title': 'Set Bot Name',
    'helper Update Bot Name description':
        'This options is only important if you are connecting our Flow Builder to this instance. In the Group Chats bot won\'t react on all messages send by the Group Participants to prevent spam. In order to call the bot in the Group Chat you can set custom name for example "robot" and by sending "@robot BLAH BLAH" the bot will react on your message',

    'helper Bot Delay title': 'Bot Delay',
    'helper Bot Delay description': 'How long should the bot wait before sending the message. Delay is in milliseconds, 1000 mls = 1 second.',

    'helper Update Flow title': 'Update Flow',
    'helper Update Flow description': 'Bind custom created flow to this instance.',

    'helper Unbind Flow title': 'Unbind Flow',
    'helper Unbind Flow description': 'Unbind flow from this instance.',

    'helper Select Flow title': 'Select Flow',
    'helper Select Flow description':
        'You can bind this bot with the flow that you have created in the Flow Builder. If you would like to disable it then select "Disconnect Flow Builder" and click "Update" button.',
    //Whatsapp
    'helper save messages title': 'Save Messages',
    'helper save messages description': 'Save all incoming messages. By default this feature is disabled.',

    'helper video upload title': 'Save Video Messages',
    'helper video upload description': 'Save incoming video message in to a file.',

    'helper chat changes title': 'Chat Changes',
    'helper chat changes description': 'Receive a webhook HTTP POST notification about changes in the chats/groups. You should have an acive WebHook URL.',

    'helper ack title': 'Delivery/Read Notifications',
    'helper ack description': 'Receive a webhook HTTP POST notification when message was delivery/read. You should have an acive WebHook URL.',

    'helper typing indicator title': 'Typing Indicator',
    'helper typing indicator description': 'Enable bot typing indicator. The users will see that someone is typing a message.',

    'drag and drop': 'Drag and drop on the screen',
    'flow builder nodes': 'Flow Builder Nodes',

    // How to use flow builder
    'flow.howToUse.header': 'How to use flow builder?',
    'flow.howToUse.section1': 'Main Concept',
    'flow.howToUse.section2': 'Interface Explanation',
    'flow.howToUse.section3': 'Nodes Explanation',
    //Flow Values
    'flow.values': 'Custom Fields',
    'User Fields': 'User Fields',
    'Bot Fields': 'Bot Fields',
    'New User Fields': 'New User Field',
    'New Bot Fields': 'New Bot Field',
    'Create a new field?': 'Create the new field?',
    'Field Name': 'Name',
    'select type': 'Select Type',
    'wrong field type': 'Wrong field type.',
    'field type is missing': 'Field type is missing.',
    'value is missing': 'Value is missing.',
    'field name is missing': 'Field name is missing.',
    'max value length': `Max value length is ${AppConfig.valueLen}.`,
    'max description length': `Max description length is ${AppConfig.descriptionLen}`,
    'max name length': `Max name length is ${AppConfig.nameLength}`,
    'new field': 'New field was created.',
    'fixed fields': 'Fixed Fields',
    'instances:': 'instances:',
    'nodes:': 'nodes:',
    'blocks:': 'blocks:',
    'wrong field': 'Wrong field',
    'field id is required': 'Field ID is required.',
    'value is required': 'Value is required.',
    'field does not exist': 'Field does not exist.',
    'unknown error. please contact support': 'Unknown error. Please contact support.',
    'field was removed': 'Field was removed.',
    'delete field?': 'Delete field?',
    'field removal notice': 'Make sure to remove this field from your flow.',
    'you can only use letters, numbers, spaces and _': 'You can only use letters, numbers, spaces and _.',
    'you can only edit value, type or description': 'You can only edit value, type or description.',

    'Instance Info': 'Instance Info',
    Status: 'Status',
    'Message History': 'Message History',
    'Api URL': 'Api URL',
    // PAGE TITLE
    'page.faq.title': 'FAQ',
    'page.instance.title': 'Instance Manager',
    'page.apidocs.title': 'Api Documentation',
    'page.testing.title': 'Testing Are',
    'page.billing.title': 'Payments and Subscriptions',
    'page.support.title': 'Support',
    //SERVICES
    'sidebar.services': 'API Services',
    'sidebar.whatsapp': 'Whatsapp API',
    //DEVELOPER
    'sidebar.developer': 'Developer',
    'sidebar.apiDocs': 'API Docs',
    'sidebar.testingArea': 'Testing Area',
    //PAYMENT
    'sidebar.payments': 'Billing',
    //HELP
    'sidebar.help': 'Help',
    'sidebar.supportCenter': 'Support',
    'sidebar.faq': 'FAQ',
    'sidebar.flvbySupport': 'Manage Support',
    //flvby
    'sidebar.flvby': 'Flvby',
    //Flow Builder
    'sidebar.flowBuilder': 'Flow Builder',
    //Flow Values
    'sidebar.flowValues': 'Custom Fields',
    //Flow Helper
    'sidebar.flowHelper': 'How to use ?',
    //WHATSAP
    'whatsapp.activate.button': 'Activate Subscription',
    'whatsapp.Session is not valid': "Can't update the value when session is not active",
    'whatsapp.saveMessages': 'Save Messages',
    'whatsapp.newInstance': 'Create the new instance',
    'whatsapp.tableTitle': 'Whatsapp Instances',
    'whatsapp.phoneInfo': 'Keep Your Phone Online',
    'whatsapp.phoneStatus': 'Phone Status',
    'whatsapp.paymentStatus': 'Payment Status',
    'whatsapp.phonesuccess': 'Connected',
    'whatsapp.phonedanger': 'Phone Battery Is Low',
    'whatsapp.phonewarning': "Can't Connect To Instance",
    'whatsapp.phoneInfoMessage': 'WhatsApp connects to your phone to sync messages. To reduce data usage, connect your phone to Wi-Fi',
    'whatsapp.paymentStatusLeftDays': 'Left',
    'whatsapp.subscriptionStatus': 'Subscription',
    'whatsapp.paymentStatusTill': 'Active Until',
    'whatsapp.paymentStatusExpiredDaysAgo': 'Payment Is Past Due',
    'whatsapp.connectingToInstance': 'Connecting To Instance',
    'whatsapp.initializingInstance': 'Initializing Instance',
    'whatsapp.replacedSession': "WhatsApp is open on another computer or browser. Click 'Use Here' to use WhatsApp in this window.",
    'whatsapp.lostConnection': 'Make sure your phone has an active Internet connection.',
    'whatsapp.setSettings': 'Update',
    'use here': 'USE HERE',
    'retry now': 'RETRY NOW',
    'trying to reach phone': 'Trying to reach phone',
    'retrying in': 'Retrying in: ',
    'log out': 'LOG OUT',
    'request to remove messages was sent': 'Messages will be removed from the current instance within few seconds',
    'delete all messages': 'Clean Message History',
    'messages permanently removed': 'All messages will be permanently removed from the instance store',
    'api generate new token': 'Generate New Token',
    'new api token': 'New API token was generated',
    settings: 'Settings',
    copy: 'Copy',
    update: 'Update',
    'data was saved to the clipboard': 'Data was saved to the clipboard',
    'api settings': 'Api Settings',
    'api key': 'Api Key',
    'Instance Settings:': 'Instance Settings:',
    'whatsapp.bad flow value': `Can't set flow. Please check your flow for any errors.`,
    'whatsapp.flow updated': 'Flow was updated',
    'whatsapp.flow is missing': 'Flow is missing',
    'whatsapp.there is no flow': 'Instance is not binded with the flow builder',
    'whatsapp.flow ID is missing': 'Flow ID is mssing',
    'whatsapp.flow was unbinded': 'Flow was unbinded from the instance',
    'api changes': 'Make sure that you are ready to apply the changes in your application',
    'whatsapp.updateWebHook': 'WebHook URL',
    'whatsapp.setSocksProxy': 'Socks Proxy',
    'whatsapp.selectFlow': 'Select Flow',
    'whatsapp.setInstanceName': 'Instance Name',
    'whatsapp.setBotName': 'Bot Name',
    'whatsapp.instanceNameUpdate': 'Instance name was updated',
    'whatsapp.instanceNameNotUpdated': 'Please use only letters/numbers with maximum length of 50',
    'whatsapp.instanceBotNameUpdate': 'Bot name was updated',
    'whatsapp.instanceBotNameNotUpdated': 'Please use only letters/numbers with maximum lenght of 20',
    'whatsapp.instanceDelayError': 'Bot delay value should be a number in milliseconds. Minimum is 1000 mls = 1 second',
    'whatsapp.instanceReadUploadMsgVideoSettings': "Can't update it",
    'whatsapp.instanceReadUploadMsgVideoSettingsUpdated': 'Settings were updated',
    'whatsapp.instanceDelayUpdate': 'Bot delay was updated',
    'whatsapp.instanceWhuUpdate': 'Webhook URL was updated',
    'whatsapp.instanceWhuNotUpdated': 'Please enter a valid URL/IP',
    'whatsapp.instanceProxyUpdated': 'Proxy was updated! Instance will be reconnected within 30-60 seconds!',
    'whatsapp.instanceProxyDuplicate': 'Provided proxy is already in use',
    'whatsapp.instanceWebHookDuplicate': 'Provide WebHook URL is already in use',
    'whatsapp.instanceProxyNotUpdated': 'Please enter valid proxy IP:PORT',
    'whatsapp.paymentLeftHours': 'Hours',
    'whatsapp.messageHistory': 'Message History',
    'whatsapp.messageQueue': 'Message Queue',
    'whatsapp.restartInstance': 'Restart Instance',
    'whatsapp.disconnectFromInstance': 'Disconnect From Instance',
    'whatsapp.qrUpdateEvery': 'QR code will be updated every ~20 seconds',
    'whatsapp.qrScanNote_0': '0. Read message in the yellow Attention Box below.',
    'whatsapp.qrScanNote_1': 'To use our service, you need to Autorize our server to connect to your WhatSapp',
    'whatsapp.qrScanNote_2': '1. Open the WhatsApp application on your phone.',
    'whatsapp.qrScanNote_3': '2. Go to Settings->WhatsApp WEB and then click on the + icon.',
    'whatsapp.qrScanNote_4': '3. Scan a QR code and wait for a minute.',
    'whatsapp.qrScanNote_5': '4. Keep your phone turned on and connected to the Internet.',
    'proxy connection lost': 'Proxy connection lost! Please use a new proxy or make sure that the old one is online!',
    Attention: 'Attention',
    'brief summary of important information': 'Brief summary of important information.',
    'Usage Tips': 'Usage Tips',
    'Public agreement': 'Public agreement',
    tos_1: `ApiRail.com (Below will be used «developer») provides an opportunity for a capable individual who has joined this Agreement in his own interest, or who acts on behalf of and in the interests of a legal entity of the site (Below will be used "Customer") to use the services of the site https://apirail.com/ and any subdomains thereof (Below will be used "Site") on the terms and conditions set forth in this User Agreement (Below will be used "Agreement"). The Agreement comes into force from the moment of the User's consent to its terms and conditions in the manner prescribed by paragraph 1.2 of the Agreement.`,
    tos_2:
        'The text of the Agreement is offered to the Customer when registering on the Site. The Agreement shall come into force after the Customer agrees to its terms in the form of a confirmation of acquaintance.',
    tos_3:
        'Use of materials and services of the Site is regulated by the norms of the current international legislation and/or legislation of other countries (Below will be used "Current legislation")',
    tos_4:
        'This Agreement is a public offer. Getting access to the materials of the Site the Customer is considered to have joined the present Agreement and accepts all conditions of the present Agreement, Rules of use of a site and Requirements to advertising materials and the contents of sent messages in full without any exceptions and restrictions and undertakes to observe them in full.',
    tos_5:
        'The Site Administration has the right to unilaterally change the terms of this Agreement at any time without any special notice. Such changes come into force after 1 (one) working day from the moment of placing the new version of the Agreement on the site. In case of disagreement of the Customer with the changes made, the Customer shall be obliged to refuse access to the Site and stop using the materials and services of the Site.',
    tos_6: 'The Site (Site) – is the chat-api.com website and all of its subdomains available on the Internet at www.chat-api.com.',
    tos_7:
        'Administration of the site (Administration) – a person authorized by the Developer to manage the Site, as well as other activities related to the use of the Site.',
    tos_8: 'Service — set of services provided by the Developer to the Customer using the Site.',
    tos_9: 'Login – a sequence of symbols identifying each Customer among other users of the Site.',
    tos_10: 'Password – a sequence of characters known only to the Customer, stored on the Site in an encrypted form, used to identify the Customer.',
    tos_11:
        'Api — is a functionality and/or programming interface, which allows to implement in the existing Software, website, etc., messenger functions, expand and make them convenient for use.',
    tos_12: 'Instance — Account received by the user under 1 phone number.',

    'whatsapp.qrScanAttention_1': '1. ApiRail is operating independently of WhatsApp Inc.',
    'whatsapp.qrScanAttention_2': '2. ApiRail is fully complied with WhatsApp Terms Of Service.',
    'whatsapp.qrScanAttention_3': '3. You agree not to use ApiRail service for spam, bulk messaging or spreading any harmfull content.',
    'whatsapp.qrScanAttention_4':
        '4. By authorizing your WhatsApp account(phone number) in the ApiRail service, you agree that ApiRail will have full access to the authorized account.',
    'whatsapp.qrScanAttention_5': '5. ApiRail has the right to block your account in case of violation of the "Public Agreement" or "Message Content Requirements".',
    'I READ IT': 'I READ IT',
    'How to avoid being blocked by WhatsApp': 'How to avoid being blocked by WhatsApp',
    'Do not violate WhatsApp TOS!': 'Do not violate WhatsApp TOS!',
    'Spam is the main reason': `SPAM is the main reason why your WhatsApp account can be suspended. If you will send a
    message to a person or a group of peope that are not expecting your message, they can click
    "This is spam" and your account will be suspended without any ways to recover it, you
    will loose your subscription base and you will be in need of a new phone number to register in
    whatsapp.`,
    'How to avoid ban:': 'How to avoid ban:',
    'avoidBan.tip_1': '1. Make sure the user writes you first. In that case "This is Spam" button is not displayed.',
    'avoidBan.tip_2': `2. In each message always offer to unsubscribe or subscribe to/from your mailing list.
    For example "Respond 0 to unsubscribe" or "Respond 1 to subscribe".`,
    'avoidBan.tip_3': '3. Do not send same message over and over again, randomize and personalize them.',
    'avoidBan.tip_4': '4. Minimize link appearance in text at the first usage of the bot.',
    'avoidBan.tip_5': '5. Offer to a user to save you in his contact list.',
    'What to expect from a new phone number:': 'What to expect from a new phone number:',
    'avoidBan.tip_6': `1. Before connecting a new phone number(that was recently registred in WhatsApp) to our service, we advise to use this number for some time
    in your phone's WhatsApp App. Chat with other users and etc.`,
    'avoidBan.tip_7': `2. After connecting to our service do not send more then 5-10 messages per minute. You need to warm
    up your account and eventually you will be able to send more and more messages`,
    'avoidBan.tip_8': `3. Do not use a new number on a device that was already blocked by WhatsApp.`,
    'whatsapp.savedMessages': 'Saved messages: ',
    'whatsapp.saveVideoMessages': 'Video Upload',
    'whatsapp.saveChatChanges': 'Chat Changes',
    'whatsapp.messageRead': 'Message Read',
    'whatsapp.typingIndicator': 'Typing Indicator',
    'whatsapp.botDelay': 'Bot Response Delay',
    'whatsapp.badProxy': 'Proxy is unreachable! Please use different proxy!',
    'whatsapp.cantConnectSocket': 'Trying reconnect to the instance',
    'whatsapp.cantReconnect': "Can't reconnect to instance. Please try again later or contact support",
    'whatsap.cantCreateInstance': 'You have unpaid instance',
    'whatsapp.requestNewQrCode': 'Click To Renew Qr Code',
    'whatsapp.instanceRemoved': 'Instance Removed',
    'whatsapp.instanceReboot': 'Instance Reboot Request Was Submitted',
    'whatsapp.instanceDisconnect': 'WhatsApp Account Was Disconnected',
    'whatsapp.cantRemoveInstance': "Can't remove instance. Try again later or contact support.",
    'whatsapp.removeInstancesDialog': 'Remove Instances?',
    'whatsap.awaitingActivation': 'Your instance will be activated within few minutes. Thank You!',
    'whatsap.instanceStatusStopped': 'The instance was stopped. Please contact support!',
    'whatsap.instanceStatusExpired': 'Before continue using your instance you need to pay for it!',
    'whatsapp.removeInstanceWarning': 'All data,messages and everything related to the instance will be permanently removed',
    'whatsapp.rebootInstanceDialog': 'Reboot Instances?',
    'whatsapp.rebootInstanceWarning': 'Please note that instance will be unavailable for a few minutes!',
    'whatsapp.disconnectInstanceDialog': 'Disconnect WhatsApp Account?',
    'whatsapp.dicsonnectInstanceWarning': 'All data,messages and everything related to the instance will be permanently removed',
    'whatsap.toManyRequests': 'To many requests, please try again later',
    'whatsapp.instanceExpired': 'Instance Is Expired. Please Make A Payment',
    'whatsapp.payForTheInstance': 'In order to continue using instance you need to pay for it',
    'To many requests': 'To many requests, please try again later',
    'Wrong privilegies': 'Wrong privilegies',
    'You have unpaid instances': 'Please pay for your instances before creating a new one',
    'Error while updating user profile. Please contact support': 'Error while updating user profile. Please contact support',
    'Error while retrieving authorization. Please contact support': 'Error while retrieving authorization. Please contact support',
    'Error while creating instance. Please contact support': 'Error while creating instance. Please contact support',
    'Error while creating api token. Please contact support': 'Error while creating api token. Please contact support',
    'Error while saving instance in database. Please contact support': 'Error while saving instance in database. Please contact support',
    'Instance was created': 'Instance was created',
    "Can't find servers. Please contact support": "Can't find servers. Please contact support",
    'Missing server details': 'Missing server details',
    'Missing body': 'Missing body',
    'Error while creating new instance': 'Error while creating new instance',
    'Missing instance Id': 'Missing instance Id',
    'Cant delete app': 'Cant delete app',
    'Already exist': 'Already exist',
    "We can't verify your credentials. Refresh page and try again": "We can't verify your credentials. Refresh page and try again",

    'sidebar.userSettings': 'Profile Settings',
    'sidebar.app': 'App',
    'sidebar.news': 'News',
    'sidebar.horizontal': 'Horizontal',
    'sidebar.horizontalMenu': 'Horizontal Menu',
    'sidebar.general': 'General',
    'sidebar.component': 'Component',
    'sidebar.features': 'Features',
    'sidebar.applications': 'Applications',
    'sidebar.dashboard': 'Dashboard',
    'sidebar.dashboard1': 'Dashboard 1',
    'sidebar.dashboard2': 'Dashboard 2',
    'sidebar.dashboard3': 'Dashboard 3',
    'sidebar.modules': 'Modules',
    'sidebar.agency': 'Agency',
    'sidebar.pages': 'Pages',
    'sidebar.gallery': 'Gallery',
    'sidebar.pricing': 'Pricing',
    'sidebar.terms&Conditions': 'Terms & Conditions',
    'sidebar.feedback': 'Feedback',
    'sidebar.report': 'Report',
    'sidebar.faq(s)': 'Faq(s)',
    'sidebar.advancedComponent': 'Advance Component',
    'sidebar.blank': 'Blank',
    'sidebar.session': 'Session',
    'sidebar.login': 'Login',
    'sidebar.register': 'Register',
    'sidebar.lockScreen': 'Lock Screen',
    'sidebar.forgotPassword': 'Forgot Password',
    'sidebar.404': '404',
    'sidebar.500': '500',
    'sidebar.uiComponents': 'UI Components',
    'sidebar.alerts': 'Alerts',
    'sidebar.appBar': 'App Bar',
    'sidebar.avatars': 'Avatars',
    'sidebar.buttons': 'Buttons',
    'sidebar.bottomNavigations': 'Bottom Navigations',
    'sidebar.badges': 'Badges',
    'sidebar.cards': 'Cards',
    'sidebar.cardsMasonry': 'Cards Masonry',
    'sidebar.chip': 'Chip',
    'sidebar.dialog': 'Dialog',
    'sidebar.dividers': 'Dividers',
    'sidebar.drawers': 'Drawers',
    'sidebar.popover': 'Popover',
    'sidebar.expansionPanel': 'Expansion Panel',
    'sidebar.gridList': 'Grid List',
    'sidebar.list': 'List',
    'sidebar.menu': 'Menu',
    'sidebar.popoverAndToolTip': 'Pop Over & ToolTip',
    'sidebar.progress': 'Progress',
    'sidebar.snackbar': 'Snackbar',
    'sidebar.selectionControls': 'Selection Controls',
    'sidebar.advanceUiComponents': 'Advance UI Components',
    'sidebar.dateAndTimePicker': 'Date & Time Picker',
    'sidebar.tabs': 'Tabs',
    'sidebar.stepper': 'Stepper',
    'sidebar.notification': 'Notification',
    'sidebar.sweetAlert': 'Sweet Alert',
    'sidebar.autoComplete': 'Auto Complete',
    'sidebar.aboutUs': 'About Us',
    'sidebar.widgets': 'Widgets',
    'sidebar.forms': 'Forms',
    'sidebar.formElements': 'Form Elements',
    'sidebar.textField': 'Text Field',
    'sidebar.selectList': 'Select List',
    'sidebar.charts': 'Charts',
    'sidebar.reCharts': 'Re Charts',
    'sidebar.reactChartjs2': 'React Chartjs 2',
    'sidebar.icons': 'Icons',
    'sidebar.themifyIcons': 'Themify Icons',
    'sidebar.simpleLineIcons': 'Simple Line Icons',
    'sidebar.materialIcons': 'Material Icons',
    'sidebar.fontAwesome': 'Font Awesome',
    'sidebar.tables': 'Tables',
    'sidebar.basic': 'Basic',
    'sidebar.dataTable': 'Data Table',
    'sidebar.responsive': 'Responsive',
    'sidebar.reactTable': 'React Table',
    'sidebar.maps': 'Maps',
    'sidebar.googleMaps': 'Google Maps',
    'sidebar.leafletMaps': 'Leaflet Maps',
    'sidebar.inbox': 'Inbox',
    'sidebar.users': 'Users',
    'sidebar.userProfile1': 'User Profile 1',
    'sidebar.userProfile2': 'User Profile 2',
    'sidebar.userManagement': 'User Management',
    'sidebar.userProfile': 'User Profile',
    'sidebar.userList': 'User List',
    'sidebar.calendar': 'Calendar',
    'sidebar.cultures': 'Cultures',
    'sidebar.dnd': 'Dnd',
    'sidebar.selectable': 'Selectable',
    'sidebar.customRendering': 'Custom Rendering',
    'sidebar.chat': 'Chat',
    'sidebar.toDo': 'ToDo',
    'sidebar.editor': 'Editor',
    'sidebar.wysiwygEditor': 'WYSIWYG Editor',
    'sidebar.quillEditor': 'Quill Editor',
    'sidebar.reactAce': 'React Ace',
    'sidebar.dragAndDrop': 'Drag And Drop',
    'sidebar.reactDragula': 'React Dragula',
    'sidebar.reactDnd': 'React Dnd',
    'sidebar.blogManagement': 'Blog Management',
    'sidebar.ecommerce': 'Ecommerce',
    'sidebar.shopList': 'Shop List',
    'sidebar.shopGrid': 'Shop Grid',
    'sidebar.invoice': 'Invoice',
    'sidebar.multilevel': 'Multilevel',
    'sidebar.sublevel': 'Sublevel',
    'widgets.totalEarns': 'Total Earns',
    'widgets.emailsStatistics': 'Emails Statistics',
    'widgets.totalRevenue': 'Total Revenue',
    'widgets.onlineVistors': 'Online Vistors',
    'widgets.trafficSources': 'Traffic Sources',
    'widgets.RecentOrders': 'Recent Orders',
    'widgets.topSellings': 'Top Sellings',
    'widgets.productReports': 'Product Reports',
    'widgets.productStats': 'Product Stats',
    'widgets.ComposeEmail': 'Compose Email',
    'widgets.employeePayroll': 'Employee Payroll',
    'widgets.visitors': 'Visitors',
    'widgets.orders': 'Orders',
    'widgets.orderStatus': 'Order Status',
    'widgets.totalSales': 'Total Sales',
    'widgets.netProfit': 'Net Profit',
    'widgets.overallTrafficStatus': 'Overall Traffic Status',
    'widgets.tax': 'Tax',
    'widgets.expenses': 'Expenses',
    'widgets.currentTime': 'Current Time',
    'widgets.currentDate': 'Current Date',
    'widgets.todayOrders': 'Today Orders',
    'widgets.toDoList': 'To Do Lists',
    'widgets.discoverPeople': 'Discover People',
    'widgets.commments': 'Comments',
    'widgets.newCustomers': 'New Customers',
    'widgets.recentNotifications': 'Recent Notifications',
    'widgets.recentAccountNotifications': 'Account Notifications',
    accountNotifications: 'Account Notifications',
    serviceNotifications: 'Service Notifications',
    'widgets.appNotifications': 'App Notifications',
    'widgets.ratings': 'Ratings',
    'widgets.newEmails': 'New Emails',
    'widgets.siteVisitors': 'Site Visitors',
    'widgets.socialCompanines': 'Social Companines',
    'widgets.recentActivities': 'Recent Activities',
    'widgets.recentOrders': 'Recent Orders',
    'widgets.gallery': 'Gallery',
    'widgets.pricing': 'Pricing',
    'widgets.enterpriseEdition': 'Enterprise Edition',
    'widgets.personalEdition': 'Personal Edition',
    'widgets.teamEdition': 'Team Edition',
    'widgets.standard': 'Standard',
    'widgets.advanced': 'Advanced',
    'widgets.master': 'Master',
    'Wrong action': 'Wrong action',
    'widgets.Mega': 'Mega',
    'widgets.logIn': 'Log In',
    ouch: 'Ouch! Nothing Yet!',
    'close ticket': 'Close Ticket',
    'open ticket': 'Open Ticket',
    'ticket was close': 'Ticket was closed',
    'ticket was open': 'Ticket was reopened',
    'Ticket is closed': 'Ticket is closed',
    'widgets.signUp': 'Sign Up',
    'Missing title or message': 'Missing title or message',
    'Ticket does not exist': 'Ticket does not exist',
    'Wrong type': 'Wrong type',
    'Missing required variables': 'Missing required variables',
    'Action or Ticket ID are missing': 'Action or Ticket ID are missing',
    'Message or Ticket ID are missing': 'Message or Ticket ID are missing',
    'widgets.lockScreen': 'Lock Screen',
    'widgets.alertsWithLink': 'Alerts With Link',
    'widgets.additionalContent': 'Additional Content',
    'widgets.alertDismiss': 'Alert Dismiss',
    'widgets.uncontrolledDisableAlerts': 'Uncontrolled Disable Alerts',
    'widgets.contexualAlerts': 'Contexual Alerts',
    'widgets.alertsWithIcons': 'Alerts With Icons',
    'widgets.Simple App Bars': 'Simple App Bars',
    'widgets.appBarsWithButtons': 'App Bars With Buttons',
    'widgets.imageAvatars': 'Image Avatars',
    'widgets.lettersAvatars': 'Letters Avatars',
    'widgets.iconsAvatars': 'Icons Avatars',
    'widgets.flatButtons': 'Flat Buttons',
    'widgets.raisedButton': 'Raised Button',
    'widgets.buttonWithIconAndLabel': 'Button With Icon And Label',
    'widgets.floatingActionButtons': 'Floating Action Buttons',
    'widgets.iconButton': 'Icon Button',
    'widgets.socialMediaButton': 'Social Media Button',
    'widgets.reactButton': 'React Button',
    'widgets.buttonOutline': 'Button Outline',
    'widgets.buttonSize': 'Button Size',
    'widgets.buttonState': 'Button State',
    'widgets.buttonNavigationWithNoLabel': 'button Navigation With No Label',
    'widgets.buttonNavigation': 'Button Navigation',
    'widgets.iconNavigation': 'Icon Navigation',
    'widgets.badgeWithHeadings': 'Badge With Headings',
    'widgets.contexualVariations': 'Contexual Variations',
    'widgets.badgeLinks': 'Badge Links',
    'widgets.materialBadge': 'Material Badge',
    'widgets.simpleCards': 'Simple Cards',
    'widgets.backgroundVarient': 'Background Varient',
    'widgets.cardOutline': 'Card Outline',
    'widgets.overlayCard': 'Overlay Card',
    'widgets.cardGroup': 'Card Group',
    'widgets.cardTitle': 'Card Title',
    'widgets.speacialTitleTreatment': 'Speacial Title Treatment',
    'widgets.chipWithClickEvent': 'Chip With Click Event',
    'widgets.chipArray': 'Chip Array',
    'widgets.dialogs': 'Dialogs',
    'widgets.listDividers': 'List Dividers',
    'widgets.insetDividers': 'Inset Dividers',
    'widgets.temporaryDrawers': 'Temporary Drawers',
    'widgets.permanentDrawers': 'Permanent Drawers',
    'widgets.simpleExpansionPanel': 'Simple Expansion Panel',
    'widgets.controlledAccordion': 'Controlled Accordion',
    'widgets.secondaryHeadingAndColumns': 'Secondary Heading And Columns',
    'widgets.imageOnlyGridLists': 'Image Only Grid Lists',
    'widgets.advancedGridLists': 'Advanced Grid Lists',
    'widgets.singleLineGridLists': 'Single Line Grid Lists',
    'widgets.simpleLists': 'Simple Lists',
    'widgets.folderLists': 'Folder Lists',
    'widgets.listItemWithImage': 'List Item With Image',
    'widgets.switchLists': 'Switch Lists',
    'widgets.insetLists': 'Inset Lists',
    'widgets.nestedLists': 'Nested Lists',
    'widgets.checkboxListControl': 'Checkbox List Control',
    'widgets.pinedSubHeader': 'Pined Sub Header',
    'widgets.InteractiveLists': 'Interactive Lists',
    'widgets.simpleMenus': 'Simple Menus',
    'widgets.selectedMenu': 'Selected Menu',
    'widgets.maxHeightMenu': 'Max Height Menu',
    'widgets.changeTransition': 'Change Transition',
    'widgets.paper': 'Paper',
    'widgets.anchorPlayGround': 'Anchor Play Ground',
    'widgets.tooltip': 'ToolTip',
    'widgets.positionedToolTips': 'Positioned Snackbar',
    'widgets.circularProgressBottomStart': 'Circular Progress Bottom Start',
    'widgets.interactiveIntegration': 'Interactive Integration',
    'widgets.determinate': 'Determinate',
    'widgets.linearProgressLineBar': 'Linear Progress Line Bar',
    'widgets.indeterminate': 'Indeterminate',
    'widgets.buffer': 'Buffer',
    'widgets.query': 'Query',
    'widgets.transitionControlDirection': 'Transition Control Direction',
    'widgets.simpleSnackbar': 'Simple Snackbar',
    'widgets.positionedSnackbar': 'Positioned Snackbar',
    'widgets.contexualColoredSnackbars': 'Contexual Colored Snackbars',
    'widgets.simpleCheckbox': 'Simple Checkbox',
    'widgets.interminateSelection': 'Interminate Selection',
    'widgets.disabledCheckbox': 'Disabled Checkbox',
    'widgets.customColorCheckbox': 'Custom Color Checkbox',
    'widgets.VerticalStyleCheckbox': 'Vertical Style Checkbox',
    'widgets.horizontalStyleCheckbox': 'Horizontal Style Checkbox',
    'widgets.radioButtons': 'Radio Buttons',
    'widgets.disabledRadio': 'Disabled Radio',
    'widgets.withError': 'With Error',
    'widgets.switches': 'Swiches',
    'widgets.dateAndTimePicker': 'Date And Time Picker',
    'widgets.defaultPicker': 'Default Picker',
    'widgets.timePicker': 'Time Picker',
    'widgets.weekPicker': 'Week Picker',
    'widgets.defaultDatePicker': 'Default Date Picker',
    'widgets.customPicker': 'Custom Picker',
    'widgets.tabs': 'Tabs',
    'widgets.fixedTabs': 'Fixed Tabs',
    'widgets.basicTab': 'Basic Tab',
    'widgets.wrappedLabels': 'Wrapped Labels',
    'widgets.centeredLabels': 'Centered Labels',
    'widgets.forcedScrolledButtons': 'Forced Scrolled Buttons',
    'widgets.iconsTabs': 'Icons Tabs',
    'widgets.withDisableTabs': 'With Disable Tabs',
    'widgets.iconWithLabel': 'Icon With Label',
    'widgets.stepper': 'Stepper',
    'widgets.horizontalLinear': 'Horizontal Linear',
    'widgets.horizontalNonLinear': 'Horizontal Non Linear',
    'widgets.horizontalLinerAlternativeLabel': 'Horizontal Liner Alternative Label',
    'widgets.horizontalNonLinerAlternativeLabel': 'Horizontal Non Liner Alternative Label',
    'widgets.verticalStepper': 'Vertical Stepper',
    'widgets.descriptionAlert': 'Description Alert',
    'widgets.customIconAlert': 'Custom Icon Alert',
    'widgets.withHtmlAlert': 'With Html Alert',
    'widgets.promptAlert': 'Prompt Alert',
    'widgets.passwordPromptAlert': 'Password Prompt Alert',
    'widgets.customStyleAlert': 'Custom Style Alert',
    'widgets.autoComplete': 'Auto Complete',
    'widgets.reactSelect': 'React Select',
    'widgets.downshiftAutoComplete': 'Downshift Auto Complete',
    'widgets.reactAutoSuggests': 'React Auto Suggests',
    'widgets.aboutUs': 'About Us',
    'widgets.ourVission': 'Our Vission',
    'widgets.ourMissions': 'Our Missions',
    'widgets.ourMotivation': 'Our Motivation',
    'widgets.defualtReactForm': 'Defualt React Form',
    'widgets.url': 'Url',
    'widgets.textArea': 'Text Area',
    'widgets.file': 'File',
    'widgets.formGrid': 'Form Grid',
    'widgets.inlineForm': 'Inline Form',
    'widgets.inputSizing': 'Input Sizing',
    'widgets.inputGridSizing': 'Input Grid Sizing',
    'widgets.hiddenLabels': 'Hidden Labels',
    'widgets.formValidation': 'Form Validation',
    'widgets.number': 'Number',
    'widgets.date': 'Date',
    'widgets.time': 'Time',
    'widgets.color': 'Color',
    'widgets.search': 'Search',
    'widgets.selectMultiple': 'Select Multiple',
    'widgets.inputWithSuccess': 'Input With Success',
    'widgets.inputWithDanger': 'Input With Danger',
    'widgets.simpleTextField': 'Simple Text Field',
    'widgets.componet': 'Components',
    'widgets.layouts': 'Layouts',
    'widgets.inputAdorements': 'Input Adorements',
    'widgets.formattedInputs': 'Formatted Inputs',
    'widgets.simpleSelect': 'Simple Select',
    'widgets.nativeSelect': 'Native Select',
    'widgets.MutltiSelectList': 'Mutlti Select List',
    'widgets.lineChart': 'Line Chart',
    'widgets.barChart': 'Bar Chart',
    'widgets.stackedBarChart': 'Stacked Bar Chart',
    'widgets.lineBarAreaChart': 'Line Bar Area Chart',
    'widgets.areaChart': 'Area Chart',
    'widgets.stackedAreaChart': 'Stacked Area Chart',
    'widgets.verticalChart': 'Vertical Chart',
    'widgets.radarChart': 'Radar Chart',
    'widgets.doughnut': 'Doughnut',
    'widgets.polarChart': 'Polar Chart',
    'widgets.pieChart': 'Pie Chart',
    'widgets.bubbleChart': 'Bubble Chart',
    'widgets.horizontalBar': 'Horizontal Bar',
    'widgets.basicTable': 'Basic Table',
    'widgets.contexualColoredTable': 'Contexual Colored Table',
    'widgets.dataTable': 'Data Table',
    'widgets.employeeList': 'Employee List',
    'widgets.responsiveTable': 'Responsive Table',
    'widgets.responsiveFlipTable': 'Responsive Flip Table',
    'widgets.reactGridControlledStateMode': 'React Grid Controlled State Mode',
    'widgets.productsReports': 'Products Reports',
    'widgets.taskList': 'Task List',
    'widgets.basicCalender': 'Basic Calender',
    'widgets.culturesCalender': 'Cultures Calender',
    'widgets.dragAndDropCalender': 'Drag And Drop Calender',
    'widgets.selectableCalender': 'Selectable Calender',
    'widgets.customRendering': 'Custom Rendering',
    'widgets.customCalender': 'Custom Calender',
    'widgets.searchMailList': 'Search Mail List',
    'components.buyNow': 'Buy Now',
    'compenets.choose': 'Choose',
    'compenets.username': 'Username',
    'compenets.passwords': 'Passwords',
    'widgets.forgetPassword': 'Forget Password',
    'compenets.signIn': 'Sign In',
    'compenets.dontHaveAccountSignUp': 'Dont Have Account SignUp',
    'compenets.enterUserName': 'Enter User Name',
    'compenets.enterEmailAddress': 'Enter Email Address',
    'compenets.confirmPasswords': 'Confirm Passwords',
    'components.alreadyHavingAccountSignIn': 'Already Having Account Sign In',
    'components.enterYourPassword': 'Enter Your Password',
    'components.unlock': 'Unlock',
    'components.enterPasswords': 'Enter Passwords',
    'components.resestPassword': 'Resest Password',
    'components.pageNotfound': 'Page not Found',
    'components.goToHomePage': 'Go To Home Page',
    'components.sorryServerGoesWrong': 'Sorry Server Goes Wrong',
    'components.persistentDrawer': 'Persistent Drawer',
    'components.withHtml': 'With Html',
    'components.prompt': 'Prompt',
    'components.withDescription': 'With Description',
    'components.success': 'Success',
    'components.passwordPrompt': 'Password Prompt',
    'components.warning': 'Warning',
    'components.customIcon': 'Custom Icon',
    'components.customStyle': 'Custom Style',
    'components.basic': 'Basic',
    'components.submit': 'Submit',
    'components.compose': 'Compose',
    'components.sendMessage': 'Send Message',
    'components.addNewTasks': 'Add New Tasks',
    'components.addToCart': 'Add To Cart',
    'components.payNow': 'Pay Now',
    'components.print': 'Print',
    'components.cart': 'Cart',
    'components.viewCart': 'View Cart',
    'components.checkout': 'Checkout',
    'widgets.QuickLinks': 'Quick Links',
    'widgets.upgrade': 'upgrade',
    'widgets.app': 'App',
    'widgets.addNew': 'Add New',
    'widgets.orderDate': 'Order Date',
    'widgets.status': 'Status',
    'widgets.trackingNumber': 'Tracking Number',
    'widgets.action': 'Action',
    'widgets.designation': 'Designation',
    'widgets.subject': 'subject',
    'widgets.send': 'Send',
    'widgets.saveAsDrafts': 'Save As Drafts',
    'widgets.onlineSources': 'Online Sources',
    'widgets.lastMonth': 'Last Month',
    'widgets.widgets': 'Widgets',
    'widgets.listing': 'Listing',
    'widgets.paid': 'Paid',
    'widgets.refunded': 'Refunded',
    'widgets.done': 'Done',
    'widgets.pending': 'Pending',
    'widgets.cancelled': 'Cancelled',
    'widgets.approve': 'Approve',
    'widgets.following': 'Following',
    'widgets.follow': 'follow',
    'widgets.graphs&Charts': 'Graphs & Charts',
    'widgets.open': 'Open',
    'widgets.bounced': 'Bounced',
    'widgets.spam': 'Spam',
    'widgets.unset': 'Unset',
    'widgets.bandwidthUse': 'Bandwidth Use',
    'widgets.dataUse': 'Data Use',
    'widgets.unsubscribe': 'Unsubscribe',
    'widgets.profile': 'Profile',
    'widgets.messages': 'Messages',
    'widgets.support': 'Support',
    'widgets.faq(s)': 'Faq(s)',
    'widgets.upgradePlains': 'Upgrade Plains',
    'widgets.logOut': 'Log Out',
    'widgets.mail': 'Mail',
    'widgets.adminTheme': 'Admin Theme',
    'widgets.wordpressTheme': 'Wordpress Theme',
    'widgets.addToCart': 'Add To Cart',
    'widgets.plan': 'Plan',
    'widgets.basic': 'Basic',
    'widgets.pro': 'Pro',
    'widgets.startToBasic': 'Start To Basic',
    'widgets.upgradeToPro': 'Upgrade To Pro',
    'widgets.upgradeToAdvance': 'Upgrade To Advance',
    'widgets.comparePlans': 'Compare Plans',
    'widgets.free': 'Free',
    'widgets.frequentlyAskedQuestions': 'Frequently Asked Questions',
    'widgets.searchIdeas': 'Search Ideas',
    'widgets.startDate': 'Start Date',
    'widgets.endDate': 'End Date',
    'widgets.category': 'Category',
    'widgets.apply': 'Apply',
    'widgets.yesterday': 'Yesterday',
    'widgets.totalOrders': 'Total Orders',
    'widgets.totalVisitors': 'Total Visitors',
    'widgets.typeYourQuestions': 'Type Your Questions',
    'widgets.username': 'Username',
    'widgets.password': 'Password',
    'widgets.signIn': 'Sign In',
    'widgets.enterYourPassword': 'Enter Your Password',
    'widgets.alreadyHavingAccountLogin': 'Already Having Account Login',
    'widgets.composeMail': 'Compose Mail',
    'widgets.issue': 'Issue',
    'widgets.recentChat': 'Recent Chat',
    'widgets.previousChat': 'Previous Chat',
    'widgets.all': 'All',
    'widgets.filters': 'Filters',
    'widgets.deleted': 'Deleted',
    'widgets.starred': 'Starred',
    'widgets.frontend': 'Frontend',
    'widgets.backend': 'Backend',
    'widgets.api': 'Api',
    'widgets.simpleAppBar': 'Simple App Bar',
    'widgets.recents': 'Recents',
    'widgets.cardLink': 'Card Link',
    'widgets.anotherLink': 'Another Link',
    'widgets.cardSubtitle': 'card Subtitle',
    'widgets.confirmationDialogs': 'Confirmation Dialogs',
    'widgets.deletableChip': 'Deletable Chip',
    'widgets.customDeleteIconChip': 'Custom Delete Icon Chip',
    'widgets.openAlertDialog': 'Open Alert Dialog',
    'widgets.openResponsiveDialog': 'Open Responsive Dialog',
    'widgets.openSimpleDialog': 'Open Simple Dialog',
    'widgets.openFormDialog': 'Open Form Dialog',
    'widgets.follower': 'Follower',
    'widgets.important': 'Important',
    'widgets.private': 'Private',
    'widgets.openLeft': 'Open Left',
    'widgets.openRight': 'Open Right',
    'widgets.openTop': 'Open Top',
    'widgets.openBottom': 'Open Bottom',
    'widgets.selectTripDestination': 'Select Trip Destination',
    'widgets.pinnedSubheaderList': 'Pinned Subheader List',
    'widgets.singleLineItem': 'Single Line Item',
    'widgets.optionA': 'Option A',
    'widgets.optionB': 'Option B',
    'widgets.optionC': 'Option C',
    'widgets.optionM': 'Option M',
    'widgets.optionN': 'Option N',
    'widgets.optionO': 'Option O',
    'widgets.customColor': 'Custom Color',
    'widgets.centeredTabs': 'Centered Tabs',
    'widgets.multipleTabs': 'Multiple Tabs',
    'widgets.preventScrolledButtons': 'Prevent Scrolled Buttons',
    'widgets.browse': 'Browse',
    'widgets.formValidate': 'Form Validate',
    'widgets.code': 'Code',
    'widgets.company': 'Company',
    'widgets.price': 'Price',
    'widgets.change': 'Change',
    'widgets.high': 'High',
    'widgets.low': 'Low',
    'widgets.volume': 'Volume',
    'widgets.personalDetails': 'Personal Details',
    'widgets.occupation': 'Occupation',
    'widgets.companyName': 'Company Name',
    'widgets.phoneNo': 'Phone No',
    'widgets.updateProfile': 'Update Profile',
    'widgets.reject': 'Reject',
    'widgets.exportToExcel': 'Export To Excel',
    'widgets.workWeek': 'Work Week',
    'widgets.agenda': 'Agenda',
    'widgets.conference': 'Conference',
    'widgets.multilevel': 'Multilevel',
    'widgets.dailySales': 'Daily Sales',
    'widgets.today': 'Today',
    'widgets.campaignPerformance': 'Campaign Performance',
    'widgets.supportRequest': 'Support Request',
    'widgets.usersList': 'Users List ',
    'widgets.lastWeek': 'Last Week',
    'themeOptions.sidebarOverlay': 'Sidebar Overlay',
    'themeOptions.sidebarBackgroundImages': 'Sidebar Background Images',
    'themeOptions.appSettings': 'App Settings',
    'themeOptions.sidebarImage': 'Sidebar Image',
    'themeOptions.miniSidebar': 'Mini Sidebar',
    'themeOptions.boxLayout': 'Box Layout',
    'themeOptions.rtlLayout': 'Rtl Layout',
    'themeOptions.darkMode': 'Dark Mode',
    'themeOptions.sidebarLight': 'Light',
    'themeOptions.sidebarDark': 'Dark',
    'button.deletenode': 'Delete Node',
    'button.import': 'Import',
    'button.cancel': 'Cancel',
    'button.create': 'Create',
    'button.cancel.subscription': 'Cancel Subscription',
    'button.payment.refund': 'Refund Payment',
    'button.add': 'Add',
    'button.addcommand': 'Add Menu Command',
    'button.update': 'Update',
    'button.reply': 'Reply',
    'button.delete': 'Delete',
    'button.close': 'Close',
    'button.yes': 'Yes',
    'button.viewAll': 'View All',
    'button.like': 'Like',
    'button.assignNow': 'Assign Now',
    'button.seeInsights': 'See Insights',
    'button.test': 'Test',
    'button.restart': 'Restart',
    'sidebar.dateTimePicker': 'Date & Time Picker',
    'components.summary': 'Summary',
    'hint.whatAreYouLookingFor': 'What are You Looking For',
    'components.yesterday': 'Yesterday',
    'components.last7Days': 'Last 7 Days',
    'components.last1Month': 'Last 1 Month',
    'components.last6Month': 'Last 6 Month',
    'components.spaceUsed': 'Space Used',
    'components.followers': 'Follower',
    'components.trending': 'Trending',
    'components.paid': 'Paid',
    'components.refunded': 'Refunded',
    'components.done': 'Done',
    'components.pending': 'Pending',
    'components.cancelled': 'Cancelled',
    'components.approve': 'Approve',
    'components.week': 'Week',
    'components.month': 'Month',
    'components.year': 'Year',
    'components.today': 'Today',
    'components.popularity': 'Popularity',
    'components.email': 'Email',
    'components.drafts': 'Drafts',
    'components.sent': 'Sent',
    'components.trash': 'Trash',
    'components.all': 'All',
    'components.do': 'Do',
    'components.title': 'Title',
    'components.projectName': 'Project Name',
    'components.companyName': 'Company Name',
    'components.openAlert': 'Open Alert',
    'components.slideInAlertDialog': 'Slide In Alert Dialog',
    'components.openFullScreenDialog': 'Open Full Screen Dialogs',
    'components.basicChip': 'Basic Chip',
    'components.clickableChip': 'Clickable Chip',
    'components.left': 'Left',
    'components.right': 'Right',
    'components.expansionPanel1': 'Expansion Panel 1',
    'components.expansionPanel2': 'Expansion Panel 2',
    'components.generalSetting': 'General Setting',
    'components.advancedSettings': 'Advanced Settings',
    'components.firstName': 'First Name',
    'components.lastName': 'Last Name',
    'components.occupation': 'Occupation',
    'components.phoneNo': 'Phone No',
    'components.address': 'Address',
    'components.city': 'City',
    'components.state': 'State',
    'components.zipCode': 'Zip Code',
    'components.social Connection': 'Social Connection',
    'widgets.buyMore': 'Buy More',
    'widgets.trafficChannel': 'Traffic Channel',
    'widgets.stockExchange': 'Stock Exchange',
    'widgets.tweets': 'Tweets',
    'widgets.ourLocations': 'Our Locations',
    'widgets.sales': 'Sales',
    'widgets.to': 'To',
    'widgets.shipTo': 'Ship To',
    'widgets.description': 'Description',
    'widgets.unitPrice': 'Unit Price',
    'widgets.total': 'Total',
    'widgets.note': 'Note',
    'widgets.chipWithAvatar': 'Chip With Avatar',
    'widgets.chipWithTextAvatar': 'Chip With Text Avatar',
    'widgets.chipWithIconAvatar': 'Chip With Icon Avatar',
    'widgets.customClickableChip': 'Custom Clickable Chip',
    'widgets.outlineChip': 'Outline Chip',
    'widgets.disableChip': 'Disable Chip',
    'widgets.alertDialog': 'Alert Dialog',
    'widgets.animatedSlideDialogs': 'Animated Slide Dialogs',
    'widgets.fullScreenDialogs': 'Full Screen Dialogs',
    'widgets.formDialogs': 'Form Dialogs',
    'widgets.simpleDialogs': 'Simple Dialogs',
    'widgets.responsiveFullScreen': 'Responsive Full Screen',
    'widgets.primary': 'Primary',
    'widgets.social': 'Social',
    'widgets.user': 'User',
    'widgets.admin': 'Admin',
    'widgets.permanentdrawer': 'Permanent Drawer',
    'widgets.persistentdrawer': 'Persistent Drawer',
    'widgets.swiches': 'Swiches',
    'widgets.horizontalLinearAlternativeLabel': 'Horizontal Linear Alternative Label',
    'widgets.horizontalNonLinearAlternativeLabel': 'Horizontal Non Linear Alternative Label',
    'widgets.notifications': 'Notifications',
    'widgets.basicAlert': 'Basic Alert',
    'widgets.successAlert': 'Success Alert',
    'widgets.warningAlert': 'Warning Alert',
    'widgets.reactAutoSuggest': 'React Auto Suggest',
    'widgets.components': 'Components',
    'widgets.inputAdornments': 'Input Adorements',
    'widgets.multiSelectList': 'Multi Select List',
    'widgets.contextualColoredTable': 'Contexual Colored Table',
    'widgets.updateYourEmailAddress': 'Update Your Email Address',
    'widgets.selectADefaultAddress': 'Select A Default Address',
    'widgets.activity': 'Activity',
    'widgets.basicCalendar': 'Basic Calendar',
    'widgets.culturesCalendar': 'Cultures Calendar',
    'widgets.dragAndDropCalendar': 'Drag And Drop Calendar',
    'widgets.quillEditor': 'Quill Editor',
    'widgets.reactDND': 'React DND',
    'widgets.dragula': 'Dragula',
    'button.acceptTerms': 'Accept Terms',
    'button.reject': 'Reject',
    'button.addNew': 'Add New',
    'button.goToCampaign': 'Go To Campaign',
    'button.viewProfile': 'View Profile',
    'button.sendMessage': 'Send Message',
    'button.saveNow': 'Save Now',
    'button.pen': 'Pen',
    'button.reboot': 'Reboot Now',
    'button.disconnect': 'Disconnect Now',
    'button.search': 'Search',
    'button.downloadPdfReport': 'Download Pdf Report',
    'button.primary': 'Primary',
    'button.secondary': 'Secondary',
    'button.danger': 'Danger',
    'button.info': 'Info',
    'button.success': 'Success',
    'button.warning': 'Warning',
    'button.link': 'Link',
    'button.smallButton': 'Small Button',
    'button.largeButton': 'Large Button',
    'button.blockLevelButton': 'Block Level Button',
    'button.primaryButton': 'Primary Button',
    'button.button': 'Button',
    'button.save': 'Save',
    'button.publish': 'Publish',
    'button.published': 'Published',
    'button.openMenu': 'Open Menu',
    'button.openWithFadeTransition': 'Open With Fade Transition',
    'button.openPopover': 'Open Popover',
    'button.accept': 'Accept',
    'button.click': 'Click',
    'button.complete': 'Complete',
    'button.back': 'Back',
    'button.next': 'Next',
    'button.edit': 'Edit',
    'button.test_now': 'Test Now',
    'button.completeStep': 'Complete Step',
    'button.error': 'Error',
    'button.writeNewMessage': 'Write New Message',
    'button.saveChanges': 'Save Changes',
    'button.addNewUser': 'Add New User',
    'button.more': 'More',
    'hint.searchMailList': 'Search Mail List',
    'widgets.AcceptorrRejectWithin': 'Accept or reject within',
    'widgets.quoteOfTheDay': 'Quote Of The Day',
    'widgets.updated10Minago': 'Updated 10 min ago',
    'widgets.personalSchedule': 'Personal Schedule',
    'widgets.activeUsers': 'Active Users',
    'widgets.totalRequest': 'Total Request',
    'widgets.new': 'New',
    'widgets.ShareWithFriends': 'Share with friends!',
    'widgets.helpToShareText': 'Help us spread the world by sharing our website with your friends and followers on social media!',
    'widgets.thisWeek': 'This Week',
    'widgets.howWouldYouRateUs': 'How would you rate us?',
    'widgets.booking': 'Booking',
    'widgets.confirmed': 'Confirmed',
    'widgets.monthly': 'Monthly',
    'widgets.weekly': 'Weekly',
    'widgets.target': 'Target',
    'widgets.totalActiveUsers': 'Total Active Users',
    'sidebar.user': 'User',
    'sidebar.miscellaneous': 'Miscellaneous',
    'sidebar.promo': 'Promo',
    'themeOptions.themeColor': 'Theme Color',
    'module.inbox': 'Inbox',
    'module.drafts': 'Drafts',
    'module.sent': 'Sent',
    'module.trash': 'Trash',
    'module.spam': 'Spam',
    'module.frontend': 'Frontend',
    'module.backend': 'Backend',
    'module.api': 'Api',
    'module.issue': 'Issue',
    'components.emailPrefrences': 'Email Prefrences',
    'components.myProfile': 'My Profile',
    'sidebar.gettingStarted': 'Getting Started',
    'widgets.deadline': 'Deadline',
    'widgets.team': 'Team',
    'widgets.projectManagement': 'Project Management',
    'widgets.latestPost': 'Latest Post',
    'widgets.projectTaskManagement': 'Project Task Management',
    'widgets.selectProject': 'Select Project',
    'widgets.activityBoard': 'Activity Board',
    'widgets.checklist': 'Checklist',
    'sidebar.shop': 'Shop',
    'sidebar.cart': 'Cart',
    'sidebar.checkout': 'Checkout',
    'components.product': 'Product',
    'components.quantity': 'Quantity',
    'components.totalPrice': 'Total Price',
    'components.removeProduct': 'Remove Product',
    'components.mobileNumber': 'Mobile Number',
    'components.address2Optional': 'Address 2 (Optional)',
    'components.country': 'Country',
    'components.zip': 'Zip',
    'components.saveContinue': 'Save & Continue',
    'components.placeOrder': 'Place Order',
    'components.payment': 'Payment',
    'components.billingAddress': 'Billing Address',
    'components.ShippingAddressText': 'Shipping address is the same as billing address.',
    'components.CartEmptyText': 'Your Shopping Cart Is Empty!',
    'components.NoItemFound': 'No Item Found',
    'components.goToShop': 'Go To Shop',
    'components.cardNumber': 'Card Number',
    'components.expiryDate': 'Expiry Date',
    'components.cvv': 'CVV',
    'components.nameOnCard': 'Name On Card',
    'components.confirmPayment': 'Confirm Payment',
    'sidebar.saas': 'SAAS',
    'sidebar.multiLevel': 'MultiLevel',
    'sidebar.level1': 'Level 1',
    'sidebar.level2': 'Level 2',
    'sidebar.boxed': 'Boxed',
    'sidebar.extensions': 'Extensions',
    'sidebar.imageCropper': 'Image Cropper',
    'sidebar.videoPlayer': 'Video Player',
    'sidebar.dropzone': 'Dropzone',
    'widgets.baseConfig': 'Base Config',
    'widgets.customControlBar': 'Custom Control Bar',
    'widgets.withDownloadButton': 'With Download Button',
    'widgets.httpLiveStreaming': 'HTTP Live Streaming',
    'widgets.keyboardShortcuts': 'Keyboard Shortcuts',
    'button.useDefaultImage': 'Use Default Image',
    'button.cropImage': 'Crop Image',
    'widgets.preview': 'Preview',
    'widgets.croppedImage': 'Cropped Image',
    'Flow Builder': 'Flow Builder',
    'apiTesting.apiTestingArea': 'API Testing Area',
    'apiTesting.sendMessage': 'Send Message',
    'apiTesting.webHookTesting': 'Webhook Test',
    'apiDocs.title': 'API Documentation',
    'user.id': 'User ID',
    'instance.id': 'Instance ID',
    'transaction.id': 'Transaction ID',
    'payments.refund.title': 'Refund Payment',
    'payments.refund.description': 'If you will refund this payment, then the funds will be deducted from your paypal balance.',
    'payments.title': 'Payments and Subscriptions',
    'payments.cancel.dialog.instance': 'Instance ID',
    'payments.cancel.dialog.subscription': 'Subscription ID',
    'payments.subscriptions': 'Subscriptions',
    'payments.payments': 'Payments',
    'payments.subscrition.canceled': 'Subscription was canceled',
    'payments.refunded': 'Payment refunded',
    'new notification created': 'New notification created',
    'payments.subscription.cancel.title': 'Subscription Cancelation Form',
    'Payment was already refunded': 'Payment was already refunded',
    'Subscription id is missing. Please refresh page and try again.': 'Subscription id is missing. Please refresh page and try again.',
    'No such subscription': 'No such subscription',
    'Subscription is already inactive': 'Subscription is already inactive',
    'This subscription can only be canceled from your PayPal account': 'This subscription can only be canceled from your PayPal account',
    'payments.cancel.subscripion':
        'If you will cancel your subscription then at the end of the curren billing cicle the instance that is related to the subscription will be deactivated',

    'flvby.servers': 'Servers',
    'flvby.support': 'Support',

    'flvby.users': 'Users',
    'flvby.instances': 'Instances',
    'flvby.controlPanel': 'Control Panel',
    'flvby.noPermissions': "Don't have right permission",
    'flvby.userRemoved': 'User was removed',
    'flvby.removeUserDialog': 'Remove User?',
    'flvby.changesSaved': 'Changes saved',
    'Missing title or descritpion': 'Missing title or descritpion',
    'Duplicate Title': 'Duplicate Title',
    'flvby.removeUserWarning': 'All data,messages and everything related to User will be permanently removed',
    'auth.verificationEmail': 'Verification email was sent. Please confirm your email',
    'flvby.subscribtion.plans': 'Subscription Plans',
    'flvby.subscribtion.list': 'Subscribers',
    'flvby.payment.list': 'Payments',
    'flvby.notifications': 'Notifications',
    'flvby.proxies': 'Proxy Management',
    'New Request': 'New Support Request',
    'new support request': 'Create the new request to support',
    'new support description': 'We usually reply within few hours after request was created',
    'flvby.productIsMissing': 'To create Billing Plan, you need to create PayPal product',
    'flvby.categoryProductMissing': 'Category Product Is Missing',
    'Missing name or description': 'Missing name or description',
    'flvby.addBillingPlan': 'Add Billing Plan',
    'total messages': 'Total messages',
    'flvby.updatePrice': 'Update Price',
    'flvby.dialog.instance.manager': 'Here you can manage choosen instance. Stop , start or extend time of usage',
    'flvby.dialog.instance.manager.extend': 'Extend Usage',
    'flvby.dialog.instance.manager.startstop': 'Start/Stop',
    'flvby.dialog.instance.manager.restart': 'Restart',
    'payment.success': 'Your instance will be updated within Few Minutes',
    'payment.success.title': 'Subscribed',
    'payment.cancel': 'Subscription was not activated',
    'payment.cancel.title': 'Payment Canceled',
    'payment.error': 'There was an error. Please try again later.',
    'payment.error.title': 'Error',
    'title or message are missing': 'Title or message are missing',
    'payment.subscribe.title': 'Pay for the instance.',
    'payment.loader.title': 'Please Wait!',
    'payment.loader': 'Do not refresh page',
    "Can't find subscribtion plan. Please contact support.": "Can't find subscribtion plan. Please contact support.",
    'delete message': 'Delete message',
    'message was removed': 'Message was removed',
    'message will be permanently removed from the storage': 'Message will be permanently removed from the storage',
    'apiDocs.whatsapp': 'WhatsApp API',
    'apiDocs.apiDosTitle': 'API Documentation',
    'subscription.info': 'You can cancel any of your subscriptions by clicking on a cancel button in the Action column',

    'server.control.dialog.title': 'Server Control',
    'server.control.dialog.description': 'After deleting the chosen server it will be removed from the Redis Cluster and Firebase RDB',
    'server.button.delete': 'Delete Server',
    'server.delete': 'You are about to delete the server!',
    'server.message.no_delete': "Can't delete the server",
    'server.message.removed': 'Server was removed',
    'server.new.server.title': 'Create New Server',
    'server.new.server.description': 'After submitting the request to create a new server, it will take few minutes for the task to be completed',
    'server.no_list': "Can't load server list. Try again by refreshing the page",
    'server.no_create': "Can't create server. Please try again later",
    'server.button.create': 'Create Server',
    'server.create.missing.params': 'All field should be filled/selected',
    'server.created': 'Server was created. Please wait few minutes and server will appear in the table',
    'server.button.create.snapshot': 'Create Snapshot',
    'server.button.create.server': 'Create Server',
    'server.new.snapshot.title': 'Create New Snapshot',
    'server.new.snapshot.description': 'A new image(snapshot) can be used to create a new server instance',
    'server.snapshot.created': 'Snapshot was created. It will take around 10 minutes before it can be used to created new servers',
    'server.snapshot.not_created': 'Wasn`t able to create a snapshot. Try again later',

    'notification.new.description': 'The new notification will be created in firebase RDB and showed to all users in the service in top right corner',
    'notification.new.title': 'Create New Service Notification',
    'notification.remove.title': 'Remove Notifiction',
    'notification.remove.description': "The notificaiton will be removed from firebase RDB and won't be showed to users",
    'notification.new.button': 'Create New Notification',

    'instance.manage.title': 'Manage',

    'plan.manage.title': 'Manage Plan',
    'plan.manage.description': 'You can deactivate and/or remove current plan at any time by using action buttons below',
    'plan.manage.delete.button': 'Delete Plan',
    'plan.manage.deactivate.button': 'Deactivate Plan',
    'plan.manage.activate.button': 'Activate Plan',
    'plan.manage.delete.title': 'Remove Payment Plan?',
    'plan.manage.delete.description': 'You are about to delete the paypal payment plan!',
    'plan.manage.delete.cant_remove': "Can't remove payment plan. Try again later or contact someone :)",
    'plan.new.title': 'Create New Payment Plan',
    'plan.new.product.button': 'Create New Product',

    'proxy.management.list': 'Proxy List',
    'proxy.management.add.button': 'Add Socks Proxies',
    'proxy.new.title': 'Add Socks Proxies',
    'proxy.new.description':
        'You can add single proxy or multiple proxies at the same time. Prior adding the proxy to the list it should be properly formated: username:password@ip:port or ip:port',
    'proxy.new.description_2': 'NOTE: Only socks proxies are supported',
    'proxy.new.textarea': 'Proxy List',
    'proxy.management.submit.button': 'Add Proxies',
    'proxy.management.empty.list': "Proxy list can't be empty",
    'proxy.management.added': 'Proxies were imported to the service',
    'proxy.management.not_added': "Wasn't able to import the proxies. Double check proxies format and connection",
    'proxy.manage.title': 'Remove Proxy',
    'proxy.manage.description': 'You can only delete proxy that is not binded to the instance',
    'proxy.manage.dialog.title': 'Proxy',
    'proxy.manage.dialog.description': 'Delete, updated proxy',
    'proxy.button.delete': 'Delete Proxy',
    'proxy.message.removed': 'Proxy was removed',
    'proxy.message.no_delete': "Can't delete proxy. Try again",

    'No enough free resources. Please try again in 30 seconds': 'No enough free resources. Please try again in 30 seconds',
};
