/**
 * Firebase Login
 * Reactify comes with built in firebase login feature
 * You Need To Add Your Firsebase App Account Details Here
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

// Initialize Firebase
const config = {
    apiKey: 'AIzaSyCtpoqCge9dFV116qa-9bgziBnkLeE2mpM', // Your Api key will be here
    authDomain: 'apirail.firebaseapp.com', // Your auth domain
    databaseURL: 'https://apirail.firebaseio.com', // data base url
    projectId: 'apirail', // project id
    storageBucket: 'apirail.appspot.com', // storage bucket
    messagingSenderId: '231881050304', // messaging sender id
};

firebase.initializeApp(config);

const auth = firebase.auth();
const firestore = firebase.firestore;
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const database = firebase.database();

export { auth, googleAuthProvider, githubAuthProvider, twitterAuthProvider, database, firestore };
