import React, { useState } from 'react';
import SwaggerUi from './component/SwaggerApi';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
// intl messages
import IntlMessages from 'Util/IntlMessages';
// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

const TabContainer = ({ children }) => {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
};

const ApiDocs = ({ match }) => {
    const [index, setIndex] = useState(0);

    return (
        <div>
            <Helmet>
                <title>Api Documentation</title>
            </Helmet>
            <PageTitleBar title={<IntlMessages id="apiDocs.title" />} match={match} spec={{ openapi: '3.0.0', customCss: '.swagger-ui .topbar { display: none }' }} />
            <RctCollapsibleCard>
                <Tabs value={index} onChange={(e, value) => setIndex(value)} textColor="primary" indicatorColor="primary">
                    <Tab label={<IntlMessages id="apiDocs.whatsapp" />} />
                </Tabs>
                <SwipeableViews axis={'x'} index={index} onChangeIndex={index => setIndex(index)}>
                    <TabContainer>
                        <SwaggerUi url={`https://control.apirail.com/dashboard.json`} />
                    </TabContainer>
                    <TabContainer />
                </SwipeableViews>
            </RctCollapsibleCard>
        </div>
    );
};

export default ApiDocs;
