/**
 * Chat App Actions
 */
import { SET_NODE, RESET_FLOW, SET_FLOW_ID, SET_FLOW, READY_TO_PUBLISH, SET_SAVE, RENEW_CHART, SET_READ_ONLY, SHOW_FLOW_TESTER } from './types';

/**
 * Show/hide flow tester dialog
 */
export const showFlowTester = () => ({
    type: SHOW_FLOW_TESTER,
});

/**
 * Set readonly true/false
 */
export const setReadOnly = read => ({
    type: SET_READ_ONLY,
    payload: read,
});

/**
 * Select node to edit
 * @param {*} node
 */
export const selectNode = node => ({
    type: SET_NODE,
    payload: node,
});

/**
 * Reset flow to empty
 */
export const resetFlow = () => ({
    type: RESET_FLOW,
});

/**
 * Set flow ID
 */
export const setFlowId = flowId => ({
    type: SET_FLOW_ID,
    payload: flowId,
});

/**
 * Set current flow
 */
export const setFlow = flow => ({
    type: SET_FLOW,
    payload: flow,
});

/**
 * Show hide publish button
 */
export const publishFlow = publish => ({
    type: READY_TO_PUBLISH,
    payload: publish,
});

/**
 *
 * @param {boolean} save
 */
export const setSave = save => ({
    type: SET_SAVE,
    payload: save,
});

/**
 * Dynamically renew chart content
 */
export const renewChart = () => ({
    type: RENEW_CHART,
});
