import appLocaleData from 'react-intl/locale-data/es';
import enMessages from '../locales/es_ES';

const EnLang = {
    messages: {
        ...enMessages,
    },
    locale: 'es-ES',
    data: appLocaleData,
};
export default EnLang;
