import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import makeAsyncScriptLoader from 'react-async-script';
import propTypes from 'prop-types';

// rct card box
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

class PayPalButton extends Component {
    render() {
        const { onError, onApprove, onCancel, planId } = this.props;
        const createSubscription = (data, actions) => {
            return actions.subscription.create({
                plan_id: planId,
                application_context: {
                    brand_name: 'ApiRail Instance Subscription',
                },
            });
        };
        if (window.paypal) {
            const Button = window.paypal.Buttons.driver('react', {
                React,
                ReactDOM,
            });

            return (
                <Button
                    createSubscription={createSubscription}
                    onError={onError}
                    onCancel={onCancel}
                    onApprove={onApprove}
                />
            );
        }
        return <RctSectionLoader />;
    }
}

const RenderPayPalButton = makeAsyncScriptLoader(
    'https://www.paypal.com/sdk/js?client-id=AYBJHbNGOiUGXMEglNH6zZqh7vnEHvwPMVKEptIhnbHIxjmJzHjANV6VsHXGO4EDc_dI9qJdH9amlAho&vault=true',
)(PayPalButton);

class PayPalSubscribe extends Component {
    render() {
        return (
            <RenderPayPalButton
                onError={this.props.onError}
                onApprove={this.props.onApprove}
                onCancel={this.props.onCancel}
                planId={this.props.planId}
            />
        );
    }
}

PayPalSubscribe.propTypes = {
    onError: propTypes.func,
    onApprove: propTypes.func,
    onCancel: propTypes.func,
    planId: propTypes.string.isRequired,
    clientId: propTypes.string.isRequired,
    instanceId: propTypes.string.isRequired,
};

export default PayPalSubscribe;
