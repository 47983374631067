import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { NotificationManager } from 'react-notifications';
import IntlMessages from 'Util/IntlMessages';
import api from 'Api';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// rct card box
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

const MoreAboutSubscription = ({ userInit, more, callback, subscription }) => {
    const [loader, setLoader] = useState(false);

    const cancelSubscription = () => {
        setLoader(true);
        api.post(
            `/subscription/cancel`,
            {
                subscription_id: subscription[0],
            },
            {
                headers: {
                    'Access-Token-Id': userInit.jwt,
                },
            },
        )
            .then(response => {
                setLoader(false);
                callback();
                NotificationManager.warning(<IntlMessages id="payments.subscrition.canceled" />);
            })
            .catch(error => {
                setLoader(false);
                NotificationManager.error(error.response.data);
            });
    };
    return (
        <Dialog open={more} keepMounted onClose={callback} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
            {loader && <RctSectionLoader />}
            <DialogTitle id="alert-dialog-slide-title">
                <IntlMessages id="payments.subscription.cancel.title" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <IntlMessages id="payments.cancel.subscripion" />
                    <br />
                    <List className="p-0 border-top">
                        <ListItem className="d-flex align-items-center border-bottom py-15">
                            <span className="w-25">
                                <IntlMessages id="payments.cancel.dialog.subscription" />
                            </span>
                            <p className="w-75 mb-0">{subscription[0]}</p>
                        </ListItem>
                        <ListItem className="d-flex align-items-center border-bottom py-15">
                            <span className="w-25">
                                <IntlMessages id="payments.cancel.dialog.instance" />
                            </span>
                            <p className="w-75 mb-0">{subscription[1]}</p>
                        </ListItem>
                    </List>
                </DialogContentText>
                <br />
                <Button variant="contained" onClick={cancelSubscription} className="btn-danger text-white mr-10">
                    <IntlMessages id="button.cancel.subscription" />
                </Button>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={callback} className="btn-info text-white mr-10">
                    <IntlMessages id="button.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MoreAboutSubscription;
