/**
 * App Language Provider
 * Add more locales here
 */
import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import deLang from './entries/de_DE';
import ruLang from './entries/ru_RU';
import esLang from './entries/es_ES';

const AppLocale = {
    en: enLang,
    de: deLang,
    ru: ruLang,
    es: esLang,
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.de.data);
addLocaleData(AppLocale.ru.data);
addLocaleData(AppLocale.es.data);

export default AppLocale;
