module.exports = {
    'sidebar.app': 'App',
    'sidebar.horizontal': 'Horizontales',
    'sidebar.horizontalMenu': 'Horizontales Menü',
    'sidebar.general': 'Allgemeines',
    'sidebar.component': 'Komponente',
    'sidebar.features': 'Eigenschaften',
    'sidebar.applications': 'Anwendungen',
    'sidebar.dashboard': 'Instrumententafel',
    'sidebar.dashboard1': 'Instrumententafel 1',
    'sidebar.dashboard2': 'Instrumententafel 2',
    'sidebar.dashboard3': 'Instrumententafel 3',
    'sidebar.modules': 'Modul',
    'sidebar.agency': 'Agentur',
    'sidebar.pages': 'Seiten',
    'sidebar.gallery': 'Galerie',
    'sidebar.pricing': 'Preisgestaltung',
    'sidebar.terms&Conditions': 'Terms & amp Bedingungen',
    'sidebar.feedback': 'Feedback',
    'sidebar.report': 'Bericht',
    'sidebar.faq(s)': 'Faq(s)',
    'sidebar.advancedComponent': 'Erweiterte Komponente',
    'sidebar.blank': 'Leer',
    'sidebar.session': 'Session',
    'sidebar.login': 'Anmeldung',
    'sidebar.register': 'Registrieren',
    'sidebar.lockScreen': 'Bildschirm sperren',
    'sidebar.forgotPassword': 'Passwort vergessen',
    'sidebar.404': '404',
    'sidebar.500': '500',
    'sidebar.uiComponents': 'UI Components',
    'sidebar.alerts': 'Benachrichtigungen',
    'sidebar.appBar': 'App-Leiste',
    'sidebar.avatars': 'Avatare',
    'sidebar.buttons': 'Tasten',
    'sidebar.bottomNavigations': 'Untere Navigation',
    'sidebar.badges': 'Abzeichen',
    'sidebar.cards': 'Karten',
    'sidebar.cardsMasonry': 'Karten Masonary',
    'sidebar.chip': 'Chip',
    'sidebar.dialog': 'Dialog',
    'sidebar.dividers': 'Teiler',
    'sidebar.drawers': 'Schubladen',
    'sidebar.popover': 'Popover',
    'sidebar.expansionPanel': 'Erweiterungsfeld',
    'sidebar.gridList': 'Rasterliste',
    'sidebar.list': 'Liste',
    'sidebar.menu': 'Speisekarte',
    'sidebar.popoverAndToolTip': 'Popover und Kurzinfo',
    'sidebar.progress': 'Fortschritt',
    'sidebar.snackbar': 'Imbissbude',
    'sidebar.selectionControls': 'Auswahlkontrollen',
    'sidebar.advanceUiComponents': 'Advance UI-Komponenten',
    'sidebar.dateAndTimePicker': 'Datums- und Uhrzeitauswahl',
    'sidebar.tabs': 'Registerkarten',
    'sidebar.stepper': 'Stepper',
    'sidebar.notification': 'Benachrichtigung',
    'sidebar.sweetAlert': 'Süßer Alarm',
    'sidebar.autoComplete': 'Auto abgeschlossen',
    'sidebar.aboutUs': 'Über uns',
    'sidebar.widgets': 'Widgets',
    'sidebar.forms': 'Formen',
    'sidebar.formElements': 'Formularelemente',
    'sidebar.textField': 'Textfeld',
    'sidebar.selectList': 'Wählen Sie Liste',
    'sidebar.charts': 'Diagramme',
    'sidebar.reCharts': 'Re Charts',
    'sidebar.reactChartjs2': 'Reagiere Chartjs 2',
    'sidebar.icons': 'Symbole',
    'sidebar.themifyIcons': 'Themify Symbole',
    'sidebar.simpleLineIcons': 'Einfache Linie Icons',
    'sidebar.materialIcons': 'Materielle Ikonen',
    'sidebar.fontAwesome': 'Schrift genial',
    'sidebar.tables': 'Tabellen',
    'sidebar.basic': 'Basic',
    'sidebar.dataTable': 'Datentabelle',
    'sidebar.responsive': 'Reagierend',
    'sidebar.reactTable': 'Reagiert Tabelle',
    'sidebar.maps': 'Karten',
    'sidebar.googleMaps': 'Google Karten',
    'sidebar.leafletMaps': 'Prospektkarten',
    'sidebar.inbox': 'Posteingang',
    'sidebar.users': 'Benutzer',
    'sidebar.userProfile1': 'Benutzerprofil 1',
    'sidebar.userProfile2': 'Benutzerprofil 2',
    'sidebar.userManagement': 'Benutzerverwaltung',
    'sidebar.userProfile': 'Benutzerprofil',
    'sidebar.userList': 'Benutzerliste',
    'sidebar.calendar': 'Kalander',
    'sidebar.cultures': 'Kulturen',
    'sidebar.dnd': 'Dnd',
    'sidebar.selectable': 'Wählbar',
    'sidebar.customRendering': 'Benutzerdefiniertes Rendern',
    'sidebar.chat': 'Plaudern',
    'sidebar.toDo': 'Machen',
    'sidebar.editor': 'Editor',
    'sidebar.wysiwygEditor': 'WYSIWYG-Editor',
    'sidebar.quillEditor': 'Federredakteur',
    'sidebar.reactAce': 'Reagiere Ace',
    'sidebar.dragAndDrop': 'Ziehen und ablegen',
    'sidebar.reactDragula': 'Reagiere Dragula',
    'sidebar.reactDnd': 'Reagiere Dnd',
    'sidebar.blogManagement': 'Blogverwaltung',
    'sidebar.ecommerce': 'E-Commerce',
    'sidebar.shopList': 'Geschäftsliste',
    'sidebar.shopGrid': 'Ladenetz',
    'sidebar.invoice': 'Rechnung',
    'sidebar.multilevel': 'Mehrstufig',
    'sidebar.sublevel': 'Unterebene',
    'widgets.totalEarns': 'Gesamt verdient',
    'widgets.emailsStatistics': 'E-Mail-Statistiken',
    'widgets.onlineVistors': 'Online-Vistoren',
    'widgets.trafficSources': 'Zugriffsquellen',
    'widgets.RecentOrders': 'letzte Bestellungen',
    'widgets.topSellings': 'Top-Verkäufe',
    'widgets.productReports': 'Produktberichte',
    'widgets.productStats': 'Produkt Statistiken',
    'widgets.ComposeEmail': 'E-Mail verfassen',
    'widgets.ratings': 'Bewertungen',
    'widgets.employeePayroll': 'Personalabrechnung',
    'widgets.visitors': 'Besucher',
    'widgets.orders': 'Aufträge',
    'widgets.orderStatus': 'Bestellstatus',
    'widgets.totalSales': 'Gesamtumsatz',
    'widgets.netProfit': 'Reingewinn',
    'widgets.overallTrafficStatus': 'Gesamtverkehrsstatus',
    'widgets.tax': 'MwSt',
    'widgets.expenses': 'Expetenzen',
    'widgets.currentTime': 'Aktuelle Uhrzeit',
    'widgets.currentDate': 'Aktuelles Datum',
    'widgets.todayOrders': 'Heute Bestellungen',
    'widgets.toDoList': 'Aufgabenlisten',
    'widgets.discoverPeople': 'Menschen entdecken',
    'widgets.commments': 'Bemerkungen',
    'widgets.newCustomers': 'neue Kunden',
    'widgets.recentNotifications': 'Letzte Benachrichtigungen',
    'widgets.appNotifications': 'App-Benachrichtigungen',
    'widgets.newEmails': 'Neue E-Mails',
    'widgets.siteVisitors': 'Website-Besucher',
    'widgets.recentActivities': 'Kürzliche Aktivitäten',
    'widgets.recentOrders': 'letzte Bestellungen',
    'widgets.gallery': 'Galerie',
    'widgets.pricing': 'Preisgestaltung',
    'widgets.enterpriseEdition': 'Enterprise Edition',
    'widgets.personalEdition': 'Persönliche Ausgabe',
    'widgets.teamEdition': 'Teamausgabe',
    'widgets.socialCompanines': 'Soziale Unternehmen',
    'widgets.standard': 'Standard',
    'widgets.advanced': 'Fortgeschritten',
    'widgets.master': 'Meister',
    'widgets.Mega': 'Mega',
    'widgets.logIn': 'Einloggen',
    'widgets.signUp': 'Anmelden',
    'widgets.lockScreen': 'Bildschirm sperren',
    'widgets.alertsWithLink': 'Warnungen mit Link',
    'widgets.additionalContent': 'Zusätzlicher Inhalt',
    'widgets.alertDismiss': 'Alarm ablehnen',
    'widgets.uncontrolledDisableAlerts': 'Unkontrolliert Alarme deaktivieren',
    'widgets.contexualAlerts': 'Contexuelle Warnungen',
    'widgets.alertsWithIcons': 'Warnungen mit Symbolen',
    'widgets.Simple App Bars': 'Einfache App-Bars',
    'widgets.appBarsWithButtons': 'App-Bars mit Tasten',
    'widgets.imageAvatars': 'Bild-Avatare',
    'widgets.lettersAvatars': 'Buchstaben Avatare',
    'widgets.iconsAvatars': 'Ikonen Avatare',
    'widgets.flatButtons': 'Flache Tasten',
    'widgets.raisedButton': 'Raised Knopf',
    'widgets.buttonWithIconAndLabel': 'Knopf mit Ikone und Aufkleber',
    'widgets.floatingActionButtons': 'Schwimmende Aktionsschaltflächen',
    'widgets.iconButton': 'ICon-Taste',
    'widgets.socialMediaButton': 'Social-Media-Schaltfläche',
    'widgets.reactButton': 'Schaltfläche Reagieren',
    'widgets.buttonOutline': 'Schaltfläche Gliederung',
    'widgets.buttonSize': 'Knopfgröße',
    'widgets.buttonState': 'Schaltflächenstatus',
    'widgets.buttonNavigationWithNoLabel': 'Schaltfläche Navigation ohne Label',
    'widgets.buttonNavigation': 'Schaltflächennavigation',
    'widgets.iconNavigation': 'Symbol Navigation',
    'widgets.badgeWithHeadings': 'Abzeichen mit Überschriften',
    'widgets.contexualVariations': 'Contexuelle Variationen',
    'widgets.badgeLinks': 'Abzeichen Links',
    'widgets.materialBadge': 'Materialabzeichen',
    'widgets.simpleCards': 'Einfache Karten',
    'widgets.backgroundVarient': 'Hintergrund-Variante',
    'widgets.cardOutline': 'Kartenumriss',
    'widgets.overlayCard': 'Overlay-Karte',
    'widgets.cardGroup': 'Kartengruppe',
    'widgets.cardTitle': 'Kartentitel',
    'widgets.speacialTitleTreatment': 'Spezielle Titelbehandlung',
    'widgets.chipWithClickEvent': 'Chip mit Klickereignis',
    'widgets.chipArray': 'Chip-Array',
    'widgets.dialogs': 'Dialoge',
    'widgets.listDividers': 'Verteiler auflisten',
    'widgets.insetDividers': 'Ergänzung Teiler',
    'widgets.temporaryDrawers': 'Vorübergehende Schubladen',
    'widgets.permanentDrawers': 'Permanente Schubladen',
    'widgets.simpleExpansionPanel': 'Einfacher Erweiterungspanel',
    'widgets.controlledAccordion': 'Kontrolliertes Akkordeon',
    'widgets.secondaryHeadingAndColumns': 'Sekundäre Überschrift und Spalten',
    'widgets.imageOnlyGridLists': 'Bild nur Rasterlisten',
    'widgets.advancedGridLists': 'Erweiterte Grid-Listen',
    'widgets.singleLineGridLists': 'Einzellinien-Raster-Listen',
    'widgets.simpleLists': 'Einfache Listen',
    'widgets.folderLists': 'Ordnerlisten',
    'widgets.listItemWithImage': 'Artikel mit Bild auflisten',
    'widgets.switchLists': 'Listen umschalten',
    'widgets.insetLists': 'Einfügelisten',
    'widgets.nestedLists': 'Verschachtelte Listen',
    'widgets.checkboxListControl': 'Kontrollkästchen Listensteuerung',
    'widgets.pinedSubHeader': 'Pined Unterkopf',
    'widgets.InteractiveLists': 'Interaktive Listen',
    'widgets.simpleMenus': 'Einfache Menüs',
    'widgets.selectedMenu': 'Ausgewähltes Menü',
    'widgets.maxHeightMenu': 'Max Höhe Menü',
    'widgets.changeTransition': 'Übergang ändern',
    'widgets.paper': 'Papier',
    'widgets.anchorPlayGround': 'Anker Spielfeld',
    'widgets.tooltip': 'QuickInfo',
    'widgets.positionedToolTips': 'Positionierte Snackbar',
    'widgets.circularProgressBottomStart': 'Kreisförmiger Fortschritt Bottom Start',
    'widgets.interactiveIntegration': 'Interaktive Integration',
    'widgets.determinate': 'Bestimmt',
    'widgets.linearProgressLineBar': 'Lineare Fortschrittsleiste',
    'widgets.indeterminate': 'Unbestimmt',
    'widgets.buffer': 'Puffer',
    'widgets.query': 'Abfrage',
    'widgets.transitionControlDirection': 'Übergangskontrollrichtung',
    'widgets.simpleSnackbar': 'Einfache Snackbar',
    'widgets.positionedSnackbar': 'Positionierte Snackbar',
    'widgets.contexualColoredSnackbars': 'Contexual farbige Snackbars',
    'widgets.simpleCheckbox': 'Einfache Checkbox',
    'widgets.interminateSelection': 'Auswahl trennen',
    'widgets.disabledCheckbox': 'Deaktivierte Checkbox',
    'widgets.customColorCheckbox': 'Benutzerdefinierte Farbe Kontrollkästchen',
    'widgets.VerticalStyleCheckbox': 'Vertical Style Checkbox',
    'widgets.horizontalStyleCheckbox': 'Horizontal Style Checkbox',
    'widgets.radioButtons': 'Radio Knöpfe',
    'widgets.disabledRadio': 'Deaktiviertes Radio',
    'widgets.withError': 'Mit Fehler',
    'widgets.switches': 'Swisches',
    'widgets.dateAndTimePicker': 'Datum und Zeit Picker',
    'widgets.defaultPicker': 'Standardauswahl',
    'widgets.timePicker': 'ime Picker',
    'widgets.weekPicker': 'Wochenauswahl',
    'widgets.defaultDatePicker': 'Standard-Datumsauswahl',
    'widgets.customPicker': 'Benutzerdefinierte Auswahl',
    'widgets.tabs': 'Registerkarten',
    'widgets.fixedTabs': 'Feste Registerkarten',
    'widgets.basicTab': 'Grundlegende Registerkarte',
    'widgets.wrappedLabels': 'Wrapped Etiketten',
    'widgets.centeredLabels': 'Zentrierte Etiketten',
    'widgets.forcedScrolledButtons': 'Erzwungene Scroll-Schaltflächen',
    'widgets.iconsTabs': 'Ikonen-Registerkarten',
    'widgets.withDisableTabs': 'Mit Disable Registerkarten ',
    'widgets.iconWithLabel': 'Ikone mit Etikett',
    'widgets.stepper': 'Stepper',
    'widgets.horizontalLinear': 'Horizontal Linear',
    'widgets.horizontalNonLinear': 'Horizontal nicht linear',
    'widgets.horizontalLinerAlternativeLabel': 'Horizontale Liner Alternative Label',
    'widgets.horizontalNonLinerAlternativeLabel': 'Horizontaler nonliner alternativer Aufkleber',
    'widgets.verticalStepper': 'Vertikaler Stepper',
    'widgets.descriptionAlert': 'Beschreibung Warnung',
    'widgets.customIconAlert': 'Benutzerdefinierte Symbolwarnung',
    'widgets.withHtmlAlert': 'Mit HTML-Warnung',
    'widgets.promptAlert': 'Prompte Warnung',
    'widgets.passwordPromptAlert': 'Passwort Aufforderung Alert',
    'widgets.customStyleAlert': 'Benutzerdefinierte Stilwarnung',
    'widgets.autoComplete': 'Auto abgeschlossen',
    'widgets.reactSelect': 'Reagieren Auswählen',
    'widgets.downshiftAutoComplete': 'Herunterschalten Auto abgeschlossen',
    'widgets.reactAutoSuggests': 'Reagiere Auto Suggests',
    'widgets.aboutUs': 'Über uns',
    'widgets.ourVission': 'Unsere Entscheidung',
    'widgets.ourMissions': 'Unsere Missionen',
    'widgets.ourMotivation': 'Unsere Motivation',
    'widgets.defualtReactForm': 'Defaultre Reagieren Form',
    'widgets.url': 'Url',
    'widgets.textArea': 'Textbereich',
    'widgets.file': 'Datei',
    'widgets.formGrid': 'Formularraster',
    'widgets.inlineForm': 'Inline-Formular',
    'widgets.inputSizing': 'Eingabegröße',
    'widgets.inputGridSizing': 'Größe des Eingabegrids',
    'widgets.hiddenLabels': 'Versteckte Etiketten',
    'widgets.formValidation': 'Formularüberprüfung',
    'widgets.number': 'Nummer',
    'widgets.date': 'Datum',
    'widgets.time': 'Zeit',
    'widgets.color': 'Farbe',
    'widgets.search': 'Suche',
    'widgets.selectMultiple': 'Wählen Sie Mehrere',
    'widgets.inputWithSuccess': 'Eingabe mit Erfolg',
    'widgets.inputWithDanger': 'Eingabe mit Gefahr',
    'widgets.simpleTextField': 'Einfaches Textfeld',
    'widgets.componet': 'Komponenten',
    'widgets.layouts': 'Layouts',
    'widgets.inputAdorements': 'Eingabeverehrung',
    'widgets.formattedInputs': 'Formatierte Eingaben',
    'widgets.simpleSelect': 'Einfache Select',
    'widgets.nativeSelect': 'Native Auswahl',
    'widgets.MutltiSelectList': 'Mutlti Auswahlliste',
    'widgets.lineChart': 'Liniendiagramm',
    'widgets.barChart': 'Balkendiagramm',
    'widgets.stackedBarChart': 'Gestapeltes Balkendiagramm',
    'widgets.lineBarAreaChart': 'Line Bar Bereich Diagramm',
    'widgets.areaChart': 'Flächendiagramm',
    'widgets.stackedAreaChart': 'Gestapelte Flächendiagramm',
    'widgets.verticalChart': 'Vertikales Diagramm',
    'widgets.radarChart': 'Radar-Diagramm',
    'widgets.doughnut': 'Krapfen',
    'widgets.polarChart': 'Polardiagramm',
    'widgets.pieChart': 'Kuchendiagramm',
    'widgets.bubbleChart': 'Blasendiagramm',
    'widgets.horizontalBar': 'Horizonatl Bar',
    'widgets.basicTable': 'Grundtabelle',
    'widgets.contexualColoredTable': 'Contexual farbige Tabelle',
    'widgets.dataTable': 'Datentabelle',
    'widgets.employeeList': 'Mitarbeiterliste',
    'widgets.responsiveTable': 'Responsive Tabelle',
    'widgets.responsiveFlipTable': 'Responsive Flip-Tabelle',
    'widgets.reactGridControlledStateMode': 'Grid-gesteuerten Statusmodus reaktivieren',
    'widgets.googleMaps': 'Google Maps',
    'widgets.productsReports': 'Produkte Berichte',
    'widgets.taskList': 'Aufgabenliste',
    'widgets.basicCalender': 'Grundkalender',
    'widgets.culturesCalender': 'Kulturen Kalender',
    'widgets.dragAndDropCalender': 'ziehen und fallen Kalender',
    'widgets.selectableCalender': 'Wählbarer Kalender',
    'widgets.customRendering': 'Benutzerdefiniertes Rendern',
    'widgets.customCalender': 'Benutzerdefinierte Kalender',
    'widgets.searchMailList': 'Mail-Liste durchsuchen',
    'components.buyNow': 'Kaufe jetzt',
    'compenets.choose': 'Wählen',
    'compenets.username': 'Nutzername',
    'compenets.passwords': 'Passwörter',
    'widgets.forgetPassword': 'Passwort vergessen',
    'compenets.signIn': 'Anmelden',
    'compenets.dontHaveAccountSignUp': 'Keine Kontoanmeldung',
    'compenets.enterUserName': 'Geben Sie den Benutzernamen ein',
    'compenets.enterEmailAddress': 'E-Mail Adresse eingeben',
    'compenets.confirmPasswords': 'Bestätigen Sie die Passwörter',
    'components.alreadyHavingAccountSignIn': 'Account bereits angemeldet haben',
    'components.enterYourPassword': 'Geben Sie Ihr Passwort ein',
    'components.unlock': 'Freischalten',
    'components.enterPasswords': 'Geben Sie Passwörter ein',
    'components.resestPassword': 'Passwort zurücksetzen',
    'components.pageNotfound': 'Seite nicht gefunden',
    'components.goToHomePage': 'Zur Startseite gehen',
    'components.sorryServerGoesWrong': 'Sorry Server geht falsch',
    'components.persistentDrawer': 'Persistente Schublade',
    'components.back': 'Zurück',
    'components.next': 'Nächster',
    'components.completeStep': 'Beende den Schritt',
    'components.withHtml': 'Mit HTML',
    'components.prompt': 'Prompt',
    'components.withDescription': 'Mit Beschreibung',
    'components.success': 'Erfolg',
    'components.passwordPrompt': 'Passwort Eingabeaufforderung',
    'components.warning': 'Warnung',
    'components.customIcon': 'Benutzerdefiniertes Symbol',
    'components.customStyle': 'Benutzerdefinierter Stil',
    'components.basic': 'Basic',
    'components.submit': 'einreichen',
    'components.compose': 'Komponieren',
    'components.sendMessage': 'Nachricht senden',
    'components.addNewTasks': 'Fügen Sie neue Aufgaben hinzu',
    'components.addToCart': 'In den Warenkorb legen',
    'components.payNow': 'Zahl jetzt',
    'components.print': 'Drucken',
    'components.cart': 'Wagen',
    'components.viewCart': 'Warenkorb ansehen',
    'components.checkout': 'Auschecken',
    'widgets.QuickLinks': 'Schnelle Links',
    'widgets.upgrade': 'Aktualisierung',
    'widgets.app': 'App',
    'widgets.addNew': 'Neue hinzufügen',
    'widgets.orderDate': 'Auftragsdatum',
    'widgets.status': 'Status',
    'widgets.trackingNumber': 'Auftragsnummer, Frachtnummer,',
    'widgets.action': 'Sendungscode',
    'widgets.designation': 'Aktion',
    'widgets.subject': 'Bezeichnung',
    'widgets.send': 'Gegenstand',
    'widgets.saveAsDrafts': 'Senden',
    'widgets.onlineSources': 'Als Entwürfe speichern',
    'widgets.lastMonth': 'Online-Quellen',
    'widgets.widgets': 'Im vergangenen Monat',
    'widgets.listing': 'Widgets',
    'widgets.paid': 'Neue hinzufügen',
    'widgets.refunded': 'Bezahlt',
    'widgets.done': 'Zurückerstattet',
    'widgets.pending': 'Erledigt',
    'widgets.cancelled': 'steht aus',
    'widgets.approve': 'Abgebrochen',
    'widgets.following': 'Folgend',
    'widgets.follow': 'Folgen',
    'widgets.graphs&Charts': 'Grafiken und Diagramme',
    'widgets.open': 'Öffnen',
    'widgets.bounced': 'Ausgetreten',
    'widgets.spam': 'Spam',
    'widgets.unset': 'Nicht festgelegt',
    'widgets.bandwidthUse': 'Bandbreite verwenden',
    'widgets.dataUse': 'Daten verwenden',
    'widgets.unsubscribe': 'Abmelden',
    'widgets.profile': 'Profil',
    'widgets.messages': 'Mitteilungen',
    'widgets.support': 'Unterstützung',
    'widgets.faq(s)': 'Faq (s)',
    'widgets.upgradePlains': 'Upgrade-Ebenen',
    'widgets.logOut': 'Ausloggen',
    'widgets.mail': 'E-Mail',
    'widgets.adminTheme': 'Admin-Design',
    'widgets.wordpressTheme': 'Wordpress-Thema',
    'widgets.addToCart': 'In den Warenkorb legen',
    'widgets.plan': 'Planen',
    'widgets.basic': 'Basic',
    'widgets.pro': 'Profi',
    'widgets.startToBasic': 'Start zu Basic',
    'widgets.upgradeToPro': 'Upgrade auf Pro',
    'widgets.upgradeToAdvance': 'Upgrade zum Vorrücken',
    'widgets.comparePlans': 'Vergleiche Pläne',
    'widgets.free': 'Frei',
    'widgets.frequentlyAskedQuestions': 'Häufig gestellte Fragen',
    'widgets.searchIdeas': 'Ideen suchen',
    'widgets.startDate': 'Anfangsdatum',
    'widgets.endDate': 'Endtermin',
    'widgets.category': 'Kategorie',
    'widgets.apply': 'Sich bewerben',
    'widgets.downloadPdfReport': 'PDF-Bericht herunterladen',
    'widgets.yesterday': 'Gestern',
    'widgets.totalOrders': 'Gesamtbestellungen',
    'widgets.totalVisitors': 'Gesamt Besucher',
    'widgets.typeYourQuestions': 'Geben Sie Ihre Fragen ein',
    'widgets.username': 'Nutzername',
    'widgets.password': 'Passwort',
    'widgets.signIn': 'Anmelden',
    'widgets.enterYourPassword': 'Geben Sie Ihr Passwort ein',
    'widgets.alreadyHavingAccountLogin': 'Haben Sie bereits Konto Login',
    'widgets.composeMail': 'Mail schreiben',
    'widgets.issue': 'Problem',
    'widgets.recentChat': 'Kürzlicher Chat',
    'widgets.previousChat': 'Vorheriger Chat',
    'widgets.all': 'Alle',
    'widgets.filters': 'Filter',
    'widgets.deleted': 'Gelöscht',
    'widgets.starred': 'Markiert',
    'widgets.frontend': 'Vorderes Ende',
    'widgets.backend': 'Backend',
    'widgets.api': 'Api',
    'widgets.simpleAppBar': 'Einfache App-Leiste',
    'widgets.recents': 'Letzte',
    'widgets.cardLink': 'Kartenverbindung',
    'widgets.anotherLink': 'Ein weiterer Link',
    'widgets.cardSubtitle': 'subtítulo de la tarjeta',
    'widgets.confirmationDialogs': 'Bestätigungsdialoge',
    'widgets.deletableChip': 'Löschbarer Chip',
    'widgets.customDeleteIconChip': 'Benutzerdefiniertes Löschen Icon Chip',
    'widgets.openAlertDialog': 'Öffnen Sie den Benachrichtigungsdialog',
    'widgets.openResponsiveDialog': 'Offener responsiver Dialog',
    'widgets.openSimpleDialog': 'Einfaches Dialogfeld öffnen',
    'widgets.openFormDialog': 'Formular öffnen',
    'widgets.follower': 'Anhänger',
    'widgets.important': 'Wichtig',
    'widgets.private': 'Privatgelände',
    'widgets.openLeft': 'Öffnen Sie links',
    'widgets.openRight': 'Rechts öffnen',
    'widgets.openTop': 'Oben offen',
    'widgets.openBottom': 'Unten öffnen',
    'widgets.selectTripDestination': 'Wählen Sie Reiseziel',
    'widgets.pinnedSubheaderList': 'Fixierte Subheader-Liste',
    'widgets.singleLineItem': 'Einzelposten',
    'widgets.acceptTerms': 'Die Bedingungen akzeptieren',
    'widgets.optionA': 'Option A',
    'widgets.optionB': 'Option B',
    'widgets.optionC': 'Option C',
    'widgets.optionM': 'Option M',
    'widgets.optionN': 'Option N',
    'widgets.optionO': 'Option O',
    'widgets.customColor': 'Freiwählbare Farbe',
    'widgets.centeredTabs': 'Zentrierte Registerkarten',
    'widgets.multipleTabs': 'Mehrere Registerkarten',
    'widgets.preventScrolledButtons': 'Scroll-Schaltflächen verhindern',
    'widgets.browse': 'Durchsuche',
    'widgets.formValidate': 'Formvalidierung',
    'widgets.code': 'Code',
    'widgets.company': 'Unternehmen',
    'widgets.price': 'Preis',
    'widgets.change': 'Veränderung',
    'widgets.high': 'Hoch',
    'widgets.low': 'Niedrig',
    'widgets.volume': 'Lautstärke',
    'widgets.personalDetails': 'Persönliche Details',
    'widgets.occupation': 'Occupation',
    'widgets.companyName': 'Name der Firma',
    'widgets.phoneNo': 'Telefon-Nr',
    'widgets.city': 'Stadt',
    'widgets.zipCode': 'Postleitzahl',
    'widgets.updateProfile': 'Profil aktualisieren',
    'widgets.reject': 'Ablehnen',
    'widgets.exportToExcel': 'Exportieren nach Excell',
    'widgets.addNewUser': 'Neuen Benutzer hinzufügen',
    'widgets.workWeek': 'Arbeitswoche',
    'widgets.agenda': 'Agenda',
    'widgets.conference': 'Konferenz',
    'widgets.dailySales': 'Tägliche Verkäufe',
    'widgets.today': 'Heute',
    'widgets.campaignPerformance': 'Kampagnenleistung',
    'widgets.supportRequest': 'Unterstützungsanfrage',
    'widgets.usersList': 'Benutzerliste',
    'widgets.lastWeek': 'Letzte Woche',
    'themeOptions.sidebarOverlay': 'Sidebar-Überlagerung',
    'themeOptions.sidebarBackgroundImages': 'Seitenleiste Hintergrundbilder',
    'themeOptions.sidebarImage': 'Sidebar-Bild',
    'themeOptions.miniSidebar': 'Mini Seitenleiste',
    'themeOptions.boxLayout': 'Box-Layout',
    'themeOptions.rtlLayout': 'Rtl-Layout',
    'themeOptions.darkMode': 'Dunkler Modus',
    'themeOptions.sidebarLight': 'Licht',
    'themeOptions.sidebarDark': 'Dunkel',
    'themeOptions.appSettings': 'App Einstellungen',
    'button.cancel': 'Stornieren',
    'button.add': 'Hinzufügen',
    'button.update': 'Aktualisieren',
    'button.reply': 'Antworten',
    'button.delete': 'Löschen',
    'button.yes': 'Ja',
    'button.viewAll': 'Alle ansehen',
    'button.like': 'Mögen',
    'button.assignNow': 'Jetzt zuweisen',
    'button.seeInsights': 'Siehe Einblicke',
    'sidebar.dateTimePicker': 'Datums- und Uhrzeitauswahl',
    'components.summary': 'Zusammenfassung',
    'hint.whatAreYouLookingFor': 'Wonach suchen Sie',
    'components.yesterday': 'Gestern',
    'components.last7Days': 'Letzten 7 Tage',
    'components.last1Month': 'Letzter 1 Monat',
    'components.last6Month': 'Letzte 6 Monate',
    'components.spaceUsed': 'Speicherplatz verwendet',
    'components.followers': 'Anhänger',
    'components.trending': 'Trend',
    'components.paid': 'Bezahlt',
    'components.refunded': 'Zurückerstattet',
    'components.done': 'Erledigt',
    'components.pending': 'steht aus',
    'components.cancelled': 'Abgebrochen',
    'components.approve': 'Genehmigen',
    'components.week': 'Woche',
    'components.month': 'Monat',
    'components.year': 'Jahr',
    'components.today': 'Heute',
    'components.popularity': 'Popularität',
    'components.email': 'Email',
    'components.drafts': 'Entwürfe',
    'components.sent': 'Geschickt',
    'components.trash': 'Müll',
    'components.all': 'Alle',
    'components.do': 'Machen',
    'components.title': 'Titel',
    'components.projectName': 'Projektname',
    'components.companyName': 'Name der Firma',
    'components.openAlert': 'Alarm öffnen',
    'components.slideInAlertDialog': 'Einblenden im Benachrichtigungsdialog',
    'components.openFullScreenDialog': 'Öffnen Sie Vollbild-Dialoge',
    'components.basicChip': 'Grundlegender Chip',
    'components.clickableChip': 'Anklickbarer Chip',
    'components.left': 'links',
    'components.right': 'Recht',
    'components.expansionPanel1': 'Erweiterungskonsole 1',
    'components.expansionPanel2': 'Erweiterungspanel 2',
    'components.generalSetting': 'Allgemeine Einstellung',
    'components.advancedSettings': 'Erweiterte Einstellungen',
    'components.firstName': 'Vorname',
    'components.lastName': 'Familienname, Nachname',
    'components.occupation': 'Besetzung',
    'components.phoneNo': 'Telefon-Nr',
    'components.address': 'Adresse',
    'components.city': 'Stadt',
    'components.state': 'Zustand',
    'components.zipCode': 'Postleitzahl',
    'components.social Connection': 'Soziale Verbindung',
    'widgets.buyMore': 'Kauf mehr',
    'widgets.trafficChannel': 'Verkehrskanal',
    'widgets.stockExchange': 'Börse',
    'widgets.tweets': 'Tweets',
    'widgets.ourLocations': 'Unsere Standorte',
    'widgets.sales': 'Der Umsatz',
    'widgets.to': 'Zu',
    'widgets.shipTo': 'Ausliefern',
    'widgets.description': 'Beschreibung',
    'widgets.unitPrice': 'Einzelpreis',
    'widgets.total': 'Gesamt',
    'widgets.note': 'Hinweis',
    'widgets.chipWithAvatar': 'Chip mit Benutzerbild',
    'widgets.chipWithTextAvatar': 'Chip mit Text Benutzerbild',
    'widgets.chipWithIconAvatar': 'Chip mit Symbol Benutzerbild',
    'widgets.customClickableChip': 'Kundenspezifischer anklickbarer Chip',
    'widgets.outlineChip': 'Umriss-Chip',
    'widgets.disableChip': 'Deaktivieren Sie Chip',
    'widgets.alertDialog': 'Benachrichtigungsdialog',
    'widgets.animatedSlideDialogs': 'Animierte Dia-Dialoge',
    'widgets.fullScreenDialogs': 'Vollbild-Dialoge',
    'widgets.formDialogs': 'Formulardialogfelder',
    'widgets.simpleDialogs': 'Einfache Dialoge',
    'widgets.responsiveFullScreen': 'Responsive Vollbild',
    'widgets.primary': 'Primär',
    'widgets.social': 'Sozial',
    'widgets.user': 'Benutzer',
    'widgets.admin': 'Administrator',
    'widgets.permanentdrawer': 'Dauerhafte Schublade',
    'widgets.persistentdrawer': 'Persistente Schublade',
    'widgets.swiches': 'Swisches',
    'widgets.horizontalLinearAlternativeLabel': 'Horizontaler linearer alternativer Aufkleber',
    'widgets.horizontalNonLinearAlternativeLabel': 'Horizontaler nicht linearer alternativer Aufkleber',
    'widgets.notifications': 'Benachrichtigungen',
    'widgets.basicAlert': 'Grundlegende Warnung',
    'widgets.successAlert': 'Erfolgsalarm',
    'widgets.warningAlert': 'Warnung Warnung',
    'widgets.reactAutoSuggest': 'Reagiere Auto Suggest',
    'widgets.components': 'Komponenten',
    'widgets.inputAdornments': 'Eingabeverehrung',
    'widgets.multiSelectList': 'Mehrfachauswahlliste',
    'widgets.contextualColoredTable': 'Contexual farbige Tabelle',
    'widgets.updateYourEmailAddress': 'Aktualisieren Sie Ihre E-Mail-Adresse',
    'widgets.selectADefaultAddress': 'Wählen Sie eine Standardadresse',
    'widgets.activity': 'Aktivität',
    'widgets.basicCalendar': 'Basiskalender',
    'widgets.culturesCalendar': 'Kulturen Kalender',
    'widgets.dragAndDropCalendar': 'Ziehen Sie den Kalender und legen Sie ihn ab',
    'widgets.quillEditor': 'Federredakteur',
    'widgets.reactDND': 'Reagiere DND',
    'widgets.dragula': 'Dragula',
    'button.acceptTerms': 'Die Bedingungen akzeptieren',
    'button.reject': 'Ablehnen',
    'button.addNew': 'Neue hinzufügen',
    'button.goToCampaign': 'Gehe zur Kampagne',
    'button.viewProfile': 'Profil anzeigen',
    'button.sendMessage': 'Nachricht senden',
    'button.saveNow': 'Jetzt sparen',
    'button.pen': 'Stift',
    'button.search': 'Suche',
    'button.downloadPdfReport': 'PDF-Bericht herunterladen',
    'button.primary': 'Primär',
    'button.secondary': 'Sekundär',
    'button.danger': 'Achtung',
    'button.info': 'Info',
    'button.success': 'Erfolg',
    'button.warning': 'Warnung',
    'button.link': 'Verknüpfung',
    'button.smallButton': 'Kleiner Knopf',
    'button.largeButton': 'Großer Knopf',
    'button.blockLevelButton': 'Schaltfläche Blockieren Ebene',
    'button.primaryButton': 'Primärer Knopf',
    'button.button': 'Geschmack',
    'button.save': 'sparen',
    'button.openMenu': 'Menü öffnen',
    'button.openWithFadeTransition': 'Mit Fade-Übergang öffnen',
    'button.openPopover': 'Popover öffnen',
    'button.accept': 'Akzeptieren',
    'button.click': 'Klicken',
    'button.complete': 'Komplett',
    'button.back': 'Zurück',
    'button.next': 'Nächster',
    'button.completeStep': 'Beende den Schritt',
    'button.error': 'Error',
    'button.writeNewMessage': 'Schreibe eine neue Nachricht',
    'button.saveChanges': 'Änderungen speichern',
    'button.addNewUser': 'Neuen Benutzer hinzufügen',
    'button.more': 'Mehr',
    'hint.searchMailList': 'Mail-Liste durchsuchen',
    'widgets.AcceptorrRejectWithin': 'Akzeptieren oder ablehnen innerhalb',
    'widgets.quoteOfTheDay': 'Zitat des Tages',
    'widgets.updated10Minago': 'Vor 10 Minuten aktualisiert',
    'widgets.personalSchedule': 'Persönlicher Zeitplan',
    'widgets.activeUsers': 'Aktive Benutzer',
    'widgets.totalRequest': 'Gesamtanforderung',
    'widgets.new': 'Neu',
    'widgets.ShareWithFriends': 'Mit Freunden teilen!',
    'widgets.helpToShareText':
        'Helfen Sie uns, die Welt zu verbreiten, indem Sie unsere Website mit Ihren Freunden und Followern in sozialen Netzwerken teilen!',
    'widgets.thisWeek': 'Diese Woche',
    'widgets.howWouldYouRateUs': 'Wie würdest du uns bewerten?',
    'widgets.booking': 'Buchung',
    'widgets.confirmed': 'Bestätigt',
    'widgets.monthly': 'monatlich',
    'widgets.weekly': 'Wöchentlich',
    'widgets.target': 'Ziel',
    'widgets.totalActiveUsers': 'Gesamtzahl aktiver Benutzer',
    'sidebar.user': 'Benutzer',
    'sidebar.miscellaneous': 'Sonstiges',
    'sidebar.promo': 'Promo',
    'themeOptions.themeColor': 'Thema Farbe',
    'module.inbox': 'Posteingang',
    'module.drafts': 'Entwürfe',
    'module.sent': 'Geschickt',
    'module.trash': 'Müll',
    'module.spam': 'Spam',
    'module.frontend': 'Vorderes Ende',
    'module.backend': 'Backend',
    'module.api': 'Api',
    'module.issue': 'Problem',
    'components.emailPrefrences': 'E-Mail Einstellungen',
    'components.myProfile': 'Mein Profil',
    'sidebar.gettingStarted': 'Anfangen',
    'widgets.deadline': 'Frist',
    'widgets.team': 'Mannschaft',
    'widgets.projectManagement': 'Projektmanagement',
    'widgets.latestPost': 'Neuester Beitrag',
    'widgets.projectTaskManagement': 'Projektaufgabenverwaltung',
    'widgets.selectProject': 'Wählen Sie Projekt',
    'widgets.activityBoard': 'Aktivitätsausschuss',
    'widgets.checklist': 'Checkliste',
    'sidebar.shop': 'Geschäft',
    'sidebar.cart': 'Wagen',
    'sidebar.checkout': 'Auschecken',
    'components.product': 'Produkt',
    'components.quantity': 'Menge',
    'components.totalPrice': 'Gesamtpreis',
    'components.removeProduct': 'Produkt entfernen',
    'components.mobileNumber': 'Handynummer',
    'components.address2Optional': 'Adresse 2 (optional)',
    'components.country': 'Land',
    'components.zip': 'Postleitzahl',
    'components.saveContinue': 'Speichern fortsetzen',
    'components.placeOrder': 'Bestellung aufgeben',
    'components.payment': 'Zahlung',
    'components.billingAddress': 'Rechnungsadresse',
    'components.ShippingAddressText': 'Die Lieferadresse ist die gleiche wie die Rechnungsadresse.',
    'components.CartEmptyText': 'Dein Einkaufswagen ist leer!',
    'components.NoItemFound': 'Kein Artikel gefunden',
    'components.goToShop': 'Zum Laden gehen',
    'components.cardNumber': 'Kartennummer',
    'components.expiryDate': 'Verfallsdatum',
    'components.cvv': 'CVV',
    'components.nameOnCard': 'Name auf der Karte',
    'components.confirmPayment': 'Bestätige Zahlung',
    'sidebar.saas': 'SAAS',
    'sidebar.multiLevel': 'MultiLevel',
    'sidebar.level1': 'Level 1',
    'sidebar.level2': 'Level 2',
    'sidebar.boxed': 'Verpackt',
    'sidebar.news': 'Nachrichten',
    'sidebar.extensions': 'Erweiterungen',
    'sidebar.imageCropper': 'Bildkrämer',
    'sidebar.videoPlayer': 'Videoplayer',
    'sidebar.dropzone': 'Abwurfgebiet',
    'widgets.baseConfig': 'Basiskonfiguration',
    'widgets.customControlBar': 'Benutzerdefinierte Steuerleiste',
    'widgets.withDownloadButton': 'Mit dem Download-Button',
    'widgets.httpLiveStreaming': 'HTTP-Live-Streaming',
    'widgets.keyboardShortcuts': 'Tastatürkürzel',
    'button.useDefaultImage': 'Verwenden Sie Standardbild',
    'button.cropImage': 'Bild zuschneiden',
    'widgets.preview': 'Vorschau',
    'widgets.croppedImage': 'Bild abgeschnitten',
};
