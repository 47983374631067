/**
 * Root Sagas
 */
import { all } from 'redux-saga/effects';

// sagas
import authSagas from './Auth';
import whatsappSagas from './whatsappSagas';

export default function* rootSaga(getState) {
    yield all([authSagas(), whatsappSagas()]);
}
