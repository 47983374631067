/**
 * Centered Tab
 */
import React, { PureComponent } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import { connect } from 'react-redux';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// intl messages
import IntlMessages from 'Util/IntlMessages';

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

import SendMessage from './components/SendMessage';
import WebHookTest from './components/WebHookTest';

function TabContainer({ children }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

class TestingArea extends PureComponent {
    state = {
        activeIndex: 0,
    };

    handleChange(value) {
        this.setState({ activeIndex: value });
    }

    handleChangeIndex(index) {
        this.setState({ activeIndex: index });
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Testing Area</title>
                </Helmet>
                <PageTitleBar title={<IntlMessages id="apiTesting.apiTestingArea" />} match={this.props.match} />
                <RctCollapsibleCard>
                    <Tabs
                        value={this.state.activeIndex}
                        onChange={(e, value) => this.handleChange(value)}
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab label={<IntlMessages id="apiTesting.sendMessage" />} />
                        <Tab label={<IntlMessages id="apiTesting.webHookTesting" />} />
                    </Tabs>
                    <SwipeableViews
                        axis={'x'}
                        index={this.state.activeIndex}
                        onChangeIndex={index => this.handleChangeIndex(index)}
                    >
                        <TabContainer>
                            <SendMessage firebase={this.props.firebase} user={this.props.user} />
                        </TabContainer>
                        <TabContainer>
                            <WebHookTest firebase={this.props.firebase} user={this.props.user} />
                        </TabContainer>
                    </SwipeableViews>
                </RctCollapsibleCard>
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
    const { user, firebase } = authUser;
    return { user, firebase };
};

export default connect(mapStateToProps)(TestingArea);
