/**
 * Auth Sagas
 */
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import { WP_FB_UPDATE_INSTANCE_STATUS, WP_INITIATE_INSTANCE_DIALOG } from 'Actions/types';

import { failedWhatsAppInstanceAction, updateInstnanceStatus, setInstance, openInstanceDialog, createSocketIo, setInstanceExpirationTime } from 'Actions';

/**
 * Create User In Firebase
 */
function* updateInstantceStatusFirebase({ payload }) {
    const { status } = payload;
    try {
        yield put(updateInstnanceStatus(status));
    } catch (error) {
        yield put(failedWhatsAppInstanceAction(error));
    }
}

/**
 * Create User In Firebase
 */
function* startInitiateInstance({ payload }) {
    const { instanceId, instanceInfo } = payload;
    const newObjs = {
        id: instanceId,
        plan_id: instanceInfo.plan_id,
        createdAt: instanceInfo.ca,
        expirationTime: instanceInfo.ex,
        price: instanceInfo.pr,
        botName: instanceInfo.botName,
        name: instanceInfo.na,
        instanceStatus: instanceInfo.ws,
        paymentStatus: instanceInfo.st,
        instanceUrl: instanceInfo.iu,
        webhookUrl: instanceInfo.wh,
        botDelay: instanceInfo.botDelay,
        typingIndicator: instanceInfo.typingIndicator,
        saveMessages: instanceInfo.saveMessages,
        proxy: instanceInfo.px,
        token: instanceInfo.api,
        uid: instanceInfo.uid,
        ack: instanceInfo.ack,
        videoupload: instanceInfo.vu,
        chatchanges: instanceInfo.cc,
        flowId: instanceInfo.flowId,
    };
    try {
        yield put(createSocketIo(`https://api.apirail.com/?instance=${instanceId}&token=${instanceInfo.api}`));
        yield put(setInstance(newObjs));
        yield put(openInstanceDialog());
        yield put(setInstanceExpirationTime(instanceInfo.ex));
    } catch (error) {
        yield put(failedWhatsAppInstanceAction(error));
    }
}

/**
 *
 */
export function* updateWhatsappFirebaseInstnanceStatus() {
    yield takeEvery(WP_FB_UPDATE_INSTANCE_STATUS, updateInstantceStatusFirebase);
}

/**
 *
 */
export function* initiateInstanceDialog() {
    yield takeEvery(WP_INITIATE_INSTANCE_DIALOG, startInitiateInstance);
}

/**
 * Auth Root Saga
 */
export default function* rootSaga() {
    yield all([fork(updateWhatsappFirebaseInstnanceStatus), fork(initiateInstanceDialog)]);
}
