import { SET_NODE, RESET_FLOW, SET_FLOW_ID, SET_FLOW, READY_TO_PUBLISH, SET_SAVE, RENEW_CHART, SET_READ_ONLY, SHOW_FLOW_TESTER } from 'Actions/types';
/**
 * Flow builder
 */
const INIT_STATE = {
    node: {},
    node_id: 0,
    reset_flow: Date.now(),
    flowId: '',
    flow: null,
    publish: false,
    save: true,
    last_renew: Date.now(),
    readonly: false,
    open_flow_tester: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_FLOW_TESTER:
            return {
                ...state,
                open_flow_tester: !state.open_flow_tester,
            };
        case SET_READ_ONLY:
            return {
                ...state,
                readonly: action.payload,
            };
        case SET_NODE:
            return {
                ...state,
                node: action.payload ? action.payload : {},
                ...(action.payload ? { node_id: action.payload.id } : {}),
            };
        case RESET_FLOW:
            return {
                ...state,
                reset_flow: Date.now(),
            };
        case SET_FLOW_ID:
            return {
                ...state,
                flowId: action.payload,
            };
        case SET_FLOW:
            return {
                ...state,
                flow: action.payload,
            };
        case READY_TO_PUBLISH:
            return {
                ...state,
                publish: action.payload,
            };
        case SET_SAVE:
            return {
                ...state,
                save: action.payload,
            };
        case RENEW_CHART:
            return {
                ...state,
                last_renew: Date.now(),
            };
        default:
            return { ...state };
    }
};
