/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React, { Suspense, lazy } from 'react';
// rct page loader
import RctPageLoader from 'Components/RctPageLoader/RctPageLoader';

// whatsapp instance
const AsyncWhatsapInstances = lazy(() => import('Routes/whatsapp'));
const AsyncWhatsapInstancesComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncWhatsapInstances {...props} />
    </Suspense>
);

const AsyncWhatsapMoreInstance = lazy(() => import('Routes/whatsapp/components/MoreInstance'));
const AsyncWhatsapMoreInstanceComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncWhatsapMoreInstance {...props} />
    </Suspense>
);

const AsyncWhatsapInstanceInfo = lazy(() => import('Routes/whatsapp/components/InstanceInfo'));
const AsyncWhatsapInstanceInfoComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncWhatsapInstanceInfo {...props} />
    </Suspense>
);

const AsyncWhatsapInstanceSettings = lazy(() => import('Routes/whatsapp/components/InstanceSettings'));
const AsyncWhatsapInstanceSettingsComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncWhatsapInstanceSettings {...props} />
    </Suspense>
);

const AsyncWhatsapMessageChart = lazy(() => import('Routes/whatsapp/components/MessageChart'));
const AsyncWhatsapMessageChartComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncWhatsapMessageChart {...props} />
    </Suspense>
);

const AsyncWhatsapMessageTable = lazy(() => import('Routes/whatsapp/components/MessagesTable'));
const AsyncWhatsapMessageTableComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncWhatsapMessageTable {...props} />
    </Suspense>
);

const AsyncWhatsapPhoneStatus = lazy(() => import('Routes/whatsapp/components/PhoneStatus'));
const AsyncWhatsapPhoneStatusComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncWhatsapPhoneStatus {...props} />
    </Suspense>
);

const AsyncWhatsapPaymentStatus = lazy(() => import('Routes/whatsapp/components/PaymentStatus'));
const AsyncWhatsapPaymentStatusComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncWhatsapPaymentStatus {...props} />
    </Suspense>
);

//Chat area
const AsyncChatArea = lazy(() => import('Routes/support/components/ChatArea'));
const AsyncChatAreaComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncChatArea {...props} />
    </Suspense>
);

//Chat sidebar
const AsyncChatSidebar = lazy(() => import('Routes/support/components/ChatSidebar'));
const AsyncChatSidebarComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncChatSidebar {...props} />
    </Suspense>
);

// testing page
const AsyncTestingPage = lazy(() => import('Routes/testing-area'));
const AsyncTestingPageComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncTestingPage {...props} />
    </Suspense>
);

// faq page
const AsyncFaqPage = lazy(() => import('Routes/faq'));
const AsyncFaqPageComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncFaqPage {...props} />
    </Suspense>
);

// api page
const AsyncAPIPage = lazy(() => import('Routes/api-docs'));
const AsyncAPIPageComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncAPIPage {...props} />
    </Suspense>
);

// billing page
const AsyncBillingPage = lazy(() => import('Routes/payments'));
const AsyncBillingPageComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncBillingPage {...props} />
    </Suspense>
);

// flvby support page
const AsyncFlvbySupport = lazy(() => import('Routes/flvby_support'));
const AsyncFlvbySupportComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncFlvbySupport {...props} />
    </Suspense>
);

// flvby page
const AsyncFlvbyPage = lazy(() => import('Routes/flvby'));
const AsyncFlvbyPageComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncFlvbyPage {...props} />
    </Suspense>
);

// support page
const AsyncSupport = lazy(() => import('Routes/support'));
const AsyncSupportComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncSupport {...props} />
    </Suspense>
);

/*---------------- Session ------------------*/

// Session Register
const AsyncSessionRegister = lazy(() => import('Routes/session/register'));
const AsyncSessionRegisterComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncSessionRegister {...props} />
    </Suspense>
);

// Forgot Password
const AsyncForgotPassword = lazy(() => import('../../container/ForgotPassword'));
const AsyncForgotPasswordComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncForgotPassword {...props} />
    </Suspense>
);

// Sign In
const AsyncSignIn = lazy(() => import('../../container/Signin'));
const AsyncSignInComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncSignIn {...props} />
    </Suspense>
);

// Sign Up
const AsyncSignUp = lazy(() => import('../../container/Signup'));
const AsyncSignUpComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncSignUp {...props} />
    </Suspense>
);

// Session Page 404
const AsyncSessionPage404 = lazy(() => import('Routes/session/404'));
const AsyncSessionPage404Component = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncSessionPage404 {...props} />
    </Suspense>
);

// Session Page 500
const AsyncSessionPage500 = lazy(() => import('Routes/session/500'));
const AsyncSessionPage500Component = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncSessionPage500 {...props} />
    </Suspense>
);

/*------------------ UI Components ---------------*/

// components Alerts
const AsyncUIAlerts = lazy(() => import('Routes/components/alert'));
const AsyncUIAlertsComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIAlerts {...props} />
    </Suspense>
);

// components Appbar
const AsyncUIAppbar = lazy(() => import('Routes/components/app-bar'));
const AsyncUIAppbarComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIAppbar {...props} />
    </Suspense>
);

// components BottomNavigation
const AsyncUIBottomNavigation = lazy(() => import('Routes/components/bottom-navigation'));
const AsyncUIBottomNavigationComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIBottomNavigation {...props} />
    </Suspense>
);

// components BottomNavigation
const AsyncUIAvatars = lazy(() => import('Routes/components/avatar'));
const AsyncUIAvatarsComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIAvatars {...props} />
    </Suspense>
);

// components Buttons
const AsyncUIButtons = lazy(() => import('Routes/components/buttons'));
const AsyncUIButtonsComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIButtons {...props} />
    </Suspense>
);

// components Badges
const AsyncUIBadges = lazy(() => import('Routes/components/badges'));
const AsyncUIBadgesComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIBadges {...props} />
    </Suspense>
);

// components CardMasonary
const AsyncUICardMasonary = lazy(() => import('Routes/components/card-masonry'));
const AsyncUICardMasonaryComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUICardMasonary {...props} />
    </Suspense>
);

// components Cards
const AsyncUICards = lazy(() => import('Routes/components/cards'));
const AsyncUICardsComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUICards {...props} />
    </Suspense>
);

// components Chips
const AsyncUIChips = lazy(() => import('Routes/components/chip'));
const AsyncUIChipsComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIChips {...props} />
    </Suspense>
);

// components Dialog
const AsyncUIDialog = lazy(() => import('Routes/components/dialog'));
const AsyncUIDialogComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIDialog {...props} />
    </Suspense>
);

// components Dividers
const AsyncUIDividers = lazy(() => import('Routes/components/dividers'));
const AsyncUIDividersComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIDividers {...props} />
    </Suspense>
);

// components Drawers
const AsyncUIDrawers = lazy(() => import('Routes/components/drawers'));
const AsyncUIDrawersComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIDrawers {...props} />
    </Suspense>
);

// components ExpansionPanel
const AsyncUIExpansionPanel = lazy(() => import('Routes/components/expansion-panel'));
const AsyncUIExpansionPanelComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIExpansionPanel {...props} />
    </Suspense>
);

// components Grid List
const AsyncUIGridList = lazy(() => import('Routes/components/grid-list'));
const AsyncUIGridListComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIGridList {...props} />
    </Suspense>
);

// components List
const AsyncUIList = lazy(() => import('Routes/components/list'));
const AsyncUIListComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIList {...props} />
    </Suspense>
);

// components Menu
const AsyncUIMenu = lazy(() => import('Routes/components/menu'));
const AsyncUIMenuComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIMenu {...props} />
    </Suspense>
);

// components Popover
const AsyncUIPopover = lazy(() => import('Routes/components/popover'));
const AsyncUIPopoverComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIPopover {...props} />
    </Suspense>
);

// components Progress
const AsyncUIProgress = lazy(() => import('Routes/components/progress'));
const AsyncUIProgressComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUIProgress {...props} />
    </Suspense>
);

// components Snackbar
const AsyncUISnackbar = lazy(() => import('Routes/components/snackbar'));
const AsyncUISnackbarComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUISnackbar {...props} />
    </Suspense>
);

// components SelectionControls
const AsyncUISelectionControls = lazy(() => import('Routes/components/selection-controls'));
const AsyncUISelectionControlsComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncUISelectionControls {...props} />
    </Suspense>
);

// Flow Builder
const AsyncFlowBuilder = lazy(() => import('Routes/flow_builder'));
const AsyncFlowBuilderComponent = () => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncFlowBuilder />
    </Suspense>
);

const AsyncFlowValues = lazy(() => import('Routes/flow_values'));
const AsyncFlowValuesComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncFlowValues {...props} />
    </Suspense>
);

const AsyncFlowHelper = lazy(() => import('Routes/flow_helper'));
const AsyncFlowHelperComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncFlowHelper {...props} />
    </Suspense>
);

const AsyncTips = lazy(() => import('Components/TermsAndTips/Tips'));
const AsyncTipsComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncTips {...props} />
    </Suspense>
);

const AsyncTermsCondition = lazy(() => import('Routes/terms-condition'));
const AsyncTermsConditionComponent = props => (
    <Suspense fallback={<RctPageLoader />}>
        <AsyncTermsCondition {...props} />
    </Suspense>
);

export {
    AsyncTermsConditionComponent,
    AsyncTipsComponent,
    AsyncFlowBuilderComponent,
    AsyncChatAreaComponent,
    AsyncChatSidebarComponent,
    AsyncSupportComponent,
    AsyncAPIPageComponent,
    AsyncWhatsapMoreInstanceComponent,
    AsyncFaqPageComponent,
    AsyncFlvbyPageComponent,
    AsyncFlvbySupportComponent,
    AsyncWhatsapInstanceInfoComponent,
    AsyncSessionRegisterComponent,
    AsyncForgotPasswordComponent,
    AsyncSessionPage404Component,
    AsyncSessionPage500Component,
    AsyncUIAlertsComponent,
    AsyncUIAppbarComponent,
    AsyncUIBottomNavigationComponent,
    AsyncUIAvatarsComponent,
    AsyncUIButtonsComponent,
    AsyncUIBadgesComponent,
    AsyncUICardMasonaryComponent,
    AsyncUICardsComponent,
    AsyncUIChipsComponent,
    AsyncWhatsapInstancesComponent,
    AsyncWhatsapInstanceSettingsComponent,
    AsyncUIDialogComponent,
    AsyncUIDividersComponent,
    AsyncUIDrawersComponent,
    AsyncUIExpansionPanelComponent,
    AsyncUIGridListComponent,
    AsyncTestingPageComponent,
    AsyncUIListComponent,
    AsyncUIMenuComponent,
    AsyncBillingPageComponent,
    AsyncUIPopoverComponent,
    AsyncUIProgressComponent,
    AsyncUISnackbarComponent,
    AsyncUISelectionControlsComponent,
    AsyncWhatsapMessageTableComponent,
    AsyncWhatsapMessageChartComponent,
    AsyncWhatsapPaymentStatusComponent,
    AsyncWhatsapPhoneStatusComponent,
    AsyncSignInComponent,
    AsyncSignUpComponent,
    AsyncFlowValuesComponent,
    AsyncFlowHelperComponent,
};
