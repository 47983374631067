import {
    FLVBY_INSTANCE_DIALOG_EXPAND,
    FLVBY_SELECT_USER_TO_EDIT,
    FLVBY_CONFIRM_USER_REMOVE,
    FLVBY_INSTANCE_CONTROL,
    FLVBY_UPDATE_CURRENT_INSTANCE,
    FLVBY_SET_USER,
    FLVBY_CREATE_PP_PRODUCT,
} from 'Actions/types';
/**
 * initial auth user
 */
const INIT_STATE = {
    uListDialog: false,
    uListEditDialog: false,
    uListUserRemoveDialog: false,
    iListInstanceControlDialog: false,
    instance: [],
    user_info: [],
    instanceStatus: '',
    showHideCreatePPProductDialog: false,
    showAddProductButton: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FLVBY_CREATE_PP_PRODUCT:
            return { ...state, showHideCreatePPProductDialog: !state.showHideCreatePPProductDialog };

        case FLVBY_INSTANCE_DIALOG_EXPAND:
            return {
                ...state,
                uListDialog: !state.uListDialog,
                ...(action.payload ? { instance: action.payload, instanceStatus: action.payload[2] } : {}),
            };

        case FLVBY_UPDATE_CURRENT_INSTANCE:
            return { ...state, instanceStatus: action.payload };

        case FLVBY_SET_USER:
            return { ...state, user_info: action.payload };

        case FLVBY_SELECT_USER_TO_EDIT:
            return {
                ...state,
                uListEditDialog: !state.uListEditDialog,
                ...(action.payload ? { user_info: action.payload } : {}),
            };

        case FLVBY_CONFIRM_USER_REMOVE:
            return { ...state, uListUserRemoveDialog: !state.uListUserRemoveDialog };

        case FLVBY_INSTANCE_CONTROL:
            return {
                ...state,
                iListInstanceControlDialog: !state.iListInstanceControlDialog,
                ...(action.payload ? { instance: action.payload } : {}),
            };

        default:
            return { ...state };
    }
};
