// async component
import {
    AsyncFlvbySupportComponent,
    AsyncFlvbyPageComponent,
    AsyncFaqPageComponent,
    AsyncFlowBuilderComponent,
    AsyncFlowValuesComponent,
    AsyncFlowHelperComponent,
} from 'Components/AsyncComponent/AsyncComponent';

import ApiDocsComponent from '../routes/api-docs';
import TestingAreaComponent from '../routes/testing-area';
import WhathsAppComponent from '../routes/whatsapp';
import BillingComponent from '../routes/payments';
import SupportComponent from '../routes/support';

export default [
    {
        path: 'flvby',
        component: AsyncFlvbyPageComponent,
    },
    {
        path: 'flvby-support',
        component: AsyncFlvbySupportComponent,
    },
    {
        path: 'testing-area',
        component: TestingAreaComponent,
    },
    {
        path: 'whatsapp',
        component: WhathsAppComponent,
    },
    {
        path: 'api-docs',
        component: ApiDocsComponent,
    },
    {
        path: 'payments',
        component: BillingComponent,
    },
    {
        path: 'support',
        component: SupportComponent,
    },
    {
        path: 'faq',
        component: AsyncFaqPageComponent,
    },
    {
        path: 'flow-builder',
        component: AsyncFlowBuilderComponent,
    },
    {
        path: 'flow-values',
        component: AsyncFlowValuesComponent,
    },
    {
        path: 'flow-builder-help',
        component: AsyncFlowHelperComponent,
    },
];
