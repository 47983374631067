/**
 * Notification Component
 */
import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Badge } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Alert, AlertTitle } from '@material-ui/lab';

import { makeStyles } from '@material-ui/core/styles';

import localforage from 'localforage';

// api

// intl messages
import IntlMessages from 'Util/IntlMessages';

const useStyles = makeStyles({
    notification: {
        boxShadow: '0 0 9px #868383, 0 0 5px #000000',
        margin: '8px 6px 8px 5px',
    },
    box: {
        boxShadow: '0 0 9px #868383, 0 0 5px #000000',
    },
    time: {
        fontSize: 'smaller',
        color: 'grey',
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
    },
});
const AccountNotifications = ({ user, firebase }) => {
    const classes = useStyles();

    const [notifications, setNotifications] = useState([]);
    const [activeNotification, setActiveNotification] = useState(false);
    const [newMessagesCounter, setNewMessagesCounter] = useState(0);
    const [initMessages, setInitMessages] = useState(false);
    const [userNotificationsRef] = useState(firebase.database.ref(`/users_notifications/${user.uid}`));
    const [newNotificationRef] = useState(firebase.database.ref(`/users/${user.uid}/last_notification`));

    const loadNotifications = () => {
        userNotificationsRef
            .orderByChild('create_time_mls')
            .limitToLast(10)
            .once('value', snapshot => {
                let item = snapshot.val();
                if (item) {
                    let messages = notifications;
                    let keys = notifications.map(item => item.key);
                    let newCount = 0;
                    if (messages.length) {
                        for (let key in item) {
                            if (keys.indexOf(key) === -1) {
                                newCount++;
                                messages.unshift({
                                    key: key,
                                    ...item[key],
                                });
                            }
                        }
                    } else {
                        for (let key in item) {
                            newCount++;
                            messages.unshift({
                                key: key,
                                title: item[key].title,
                                message: item[key].message,
                                create_time: item[key].create_time,
                                create_time_mls: item[key].create_time_mls,
                            });
                        }
                    }

                    return localforage
                        .getItem('newAccountMessagesCounter')
                        .then(old_count => {
                            if (old_count) {
                                newCount += old_count;
                            }

                            if (newCount > 0) {
                                return localforage.setItem('activeAccountNotification', true).then(() => {
                                    setActiveNotification(true);
                                    setNewMessagesCounter(newCount);
                                    return localforage.setItem('newAccountMessagesCounter', newCount);
                                });
                            } else {
                                return Promise.resolve();
                            }
                        })
                        .then(() => {
                            return localforage.setItem('account_notifications', messages);
                        })
                        .then(() => {
                            return setNotifications(messages);
                        });
                }
            });
    };

    const activeNotificationUpdate = () => {
        if (activeNotification) {
            localforage
                .setItem('activeAccountNotification', false)
                .then(() => {
                    setActiveNotification(false);
                    return localforage.setItem('newAccountMessagesCounter', 0);
                })
                .catch(() => {});
        }
    };

    useEffect(() => {
        if (!initMessages) {
            localforage.getItem('account_notifications').then(messages => {
                setInitMessages(true);
                if (!messages) {
                    loadNotifications();
                } else {
                    if (messages.length > 50) {
                        messages.splice(-Math.abs(messages.length - 50));
                        localforage.setItem('account_notifications', messages);
                    }
                    return localforage
                        .getItem('activeAccountNotification')
                        .then(active => {
                            setNotifications(messages);
                            setActiveNotification(active);
                            return localforage.getItem('newAccountMessagesCounter');
                        })
                        .then(count => {
                            setNewMessagesCounter(count);
                        });
                }
            });
        }

        newNotificationRef.on('value', snapshot => {
            localforage.getItem('accountNotificationsTimestamp').then(timestamp => {
                if (timestamp) {
                    if (timestamp !== snapshot.val()) {
                        loadNotifications();
                        localforage.setItem('accountNotificationsTimestamp', snapshot.val());
                    }
                } else {
                    localforage.setItem('accountNotificationsTimestamp', snapshot.val());
                }
            });
        });
        return () => {
            newNotificationRef.off();
        };
    }, [notifications]);

    return (
        <UncontrolledDropdown nav className="list-inline-item notification-dropdown">
            <DropdownToggle nav className="p-0" onClick={activeNotificationUpdate}>
                <Tooltip title={<IntlMessages id="accountNotifications" />} placement="bottom">
                    <IconButton className={activeNotification ? 'shake' : ''} aria-label="bell">
                        <i className="zmdi zmdi-device-hub" />
                        {activeNotification && (
                            <Badge color="danger" className="badge-xs badge-top-right rct-notify">
                                {newMessagesCounter}
                            </Badge>
                        )}
                    </IconButton>
                </Tooltip>
            </DropdownToggle>
            <DropdownMenu right className={classes.box}>
                <div className={`dropdown-content`}>
                    <div className="dropdown-top d-flex justify-content-between rounded-top bg-primary">
                        <span className="text-white font-weight-bold">
                            <IntlMessages id="widgets.recentAccountNotifications" />
                        </span>
                    </div>
                    <Scrollbars className="rct-scroll" autoHeight autoHeightMin={100} autoHeightMax={280}>
                        <ul className="list-unstyled dropdown-list">
                            {notifications.map((notification, index) => (
                                <Alert severity={notification.type ? notification.type : `info`} key={index} className={classes.notification}>
                                    <AlertTitle className={classes.title}>
                                        {notification.title} <span className={classes.time}>{notification.create_time}</span>
                                    </AlertTitle>
                                    {notification.message}
                                </Alert>
                            ))}
                        </ul>
                    </Scrollbars>
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

const mapStateToProps = ({ authUser }) => {
    const { user, firebase } = authUser;
    return { user, firebase };
};

export default compose(connect(mapStateToProps))(AccountNotifications);
