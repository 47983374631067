import React, { PureComponent } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { NotificationManager } from 'react-notifications';
import SyntaxHighlighter from 'react-syntax-highlighter';

import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

class SendMessage extends PureComponent {
    state = {
        instances: [],
        instance: '',
        phone: '380731922460',
        message: 'WhatsApp API - https://apirail.com test message',
        response: '{}',
        disabled: true,
    };

    componentDidMount() {
        this.database = this.props.firebase.database;

        this.instancesRef = this.database.ref(`users_instances_whatsapp/${this.props.user.uid}`);
        this.instancesRef.on('child_added', snapshot => {
            let item = snapshot.val();
            let store = this.state.instances;
            if (item.ws === 'Active') {
                store.unshift(item);
            }
            this.setState({
                instances: store,
                instance: store.length ? store[0] : '',
                disabled: store.length ? false : true,
            });
        });
    }

    componentWillUnmount() {
        this.instancesRef.off('value');
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    sendMessage = () => {
        this.setState({ disabled: true });
        axios
            .post(
                `https://api.apirail.com/${this.state.instance.instanceId}/message/text?token=${this.state.instance.api}`,
                {
                    body: this.state.message,
                    phone: this.state.phone,
                },
            )
            .then(response => {
                this.setState({ response: JSON.stringify(response.data, null, 2), disabled: false });
                NotificationManager.success('Message sent');
            })
            .catch(error => {
                this.setState({ disabled: false });
                NotificationManager.error(error.response.data.message);
            });
    };

    render() {
        const { instance, disabled } = this.state;

        return (
            <div className="formelements-wrapper">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-6">
                        <RctCollapsibleCard heading="Send Text Message">
                            <Form>
                                <FormGroup>
                                    <TextField
                                        id="select-currency"
                                        select
                                        label="Select instance"
                                        value={instance}
                                        disabled={disabled}
                                        onChange={this.handleChange('instance')}
                                        SelectProps={{
                                            MenuProps: {},
                                        }}
                                        fullWidth
                                    >
                                        {this.state.instances.map(option => (
                                            <MenuItem key={option.instanceId} value={option}>
                                                {option.instanceId}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormGroup>
                                <FormGroup>
                                    <TextField
                                        required
                                        id="required"
                                        fullWidth
                                        label="Phone Number"
                                        value={this.state.phone}
                                        onChange={this.handleChange('phone')}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextField
                                        required
                                        id="required"
                                        fullWidth
                                        label="Message Text"
                                        value={this.state.message}
                                        onChange={this.handleChange('message')}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                                <Button color="primary" onClick={this.sendMessage} disabled={disabled}>
                                    Submit
                                </Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xl-6">
                        <h4 className="alert-heading">Request</h4>
                        <div>
                            <SyntaxHighlighter language="javascript">{`POST https://api.apirail.com/${instance.id}/sendMessage?token=${instance.api}
JSON body:
{
  "chatId": ${this.state.phone},
  "body": ${this.state.message}
}`}</SyntaxHighlighter>
                        </div>
                        <h4 className="alert-heading">Response</h4>
                        <div>
                            <SyntaxHighlighter language="javascript">{this.state.response}</SyntaxHighlighter>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SendMessage;
